import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";
import "../auth.responsive.css";

export default function Otp() {
  const initialValues = {
    otp: "",
  };
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const verifyOtp = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", location?.state?.email);
    urlencoded.append("otp", values?.otp);
    PagesIndex.apiPostHandler(PagesIndex.Api.VERIFY_OTP, urlencoded).then(
      (res) => {
        if (res.status === 200) {
          navigate("/forgot-password", {
            replace: true,
            state: { email: location?.state?.email },
          });
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const resendOtp = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", location?.state?.email);
    PagesIndex.apiPostHandler(PagesIndex.Api.RESEND_OTP, urlencoded).then(
      (res) => {
        if (res.status === 200) {
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  return (
    <div>
      <Index.Box className="admin-auth-main-flex">
        <Index.Box className="admin-auth-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-auth-right-main">
          <Index.Box className="admin-auth-box">
            <Index.Box className="admin-auth-main">
              <Index.Link className="admin-auth-logo-box">
                <img
                  src={PagesIndex.Png.adminlogo}
                  className="admin-sidebar-logo"
                  alt="logo"
                />
              </Index.Link>
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={verifyOtp}
                initialValues={initialValues}
                validationSchema={PagesIndex.otpSchema}
              >
                {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                  <Index.Box
                    component={"form"}
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                    className="admin-auth-main"
                  >
                    <Index.Box className="admin-auth-inner-main admin-otp-inner-main">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        OTP
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para admin-common-para"
                      >
                        Please enter your OTP.
                      </Index.Typography>
                      <Index.Box className="admin-otp-flex-main">
                        <Index.Box className="admin-otp-input-box admin-input-box">
                          <Index.Box className="admin-form-group">
                            <Index.MuiOtpInput
                              value={values?.otp}
                              onChange={(e) => setFieldValue("otp", e)}
                              className="admin-form-control"
                              TextFieldsProps={{
                                type: "number",
                                inputProps: {
                                  inputMode: "numeric",
                                  pattern: "[0-9]*",
                                },
                              }}
                            />
                            <Index.FormHelperText className="admin-error-text">
                              {errors?.otp && touched?.otp ? errors?.otp : null}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="admin-flex-all admin-forgot-row">
                        <Index.Link
                          className="admin-forgot-para admin-common-para"
                          to="/"
                        >
                          Back to Login?
                        </Index.Link>
                      </Index.Box>
                    </Index.Box>
                    <Index.Button
                      className="admin-resend-btn"
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </Index.Button>
                    <Index.Box className="btn-main-primary admin-login-btn-main">
                      <Index.Box className="admin-resend-row">
                        <Index.Button
                          className="btn-primary admin-login-btn"
                          type="submit"
                        >
                          Send
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
