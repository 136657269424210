import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
// import "./categoryManagement.responsive.css";

// for modal design

const IOSSwitch = Index.styled((props) => (
    <Index.Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 14,
        height: 14,
        boxShadow: "none",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

export default function MusicCategory() {
    const { adminDetails } = PagesIndex.useSelector(
        (state) => state.AdminReducer
    );
    const formRef = useRef();
    const [data, setData] = useState([]);
    const [id, setId] = useState("");
    const [selectuserData, setSelectuserData] = useState();
    const [loading, setLoading] = useState(true);
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [idData, setIdData] = useState()
    const [openLogModal, setOpenLogModal] = useState(false);
    const handleCloseLogModal = () => setOpenLogModal(false);
    const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState("Add");
    const [filteredData, setFilteredData] = useState([]);
    const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
    // add user modal
    const [open, setOpen] = useState(false);
    // delete modal
    const [openDelete, setOpenDelete] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);


    const handleOpenLogModal = (id) => {
        setOpenLogModal(true);
        setIdData(id)
    };
    const initialValues = {
        categoryName: id ? selectuserData?.categoryName : "",
        image: "",
        categoryImage: selectuserData?.image || "",
    };

    const handleOpen = (op, id, data) => {
        setOpen(true);
        setAddOrEdit(op);
        setId(id);
        setSelectuserData(data);
    };
    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setId(id);
    };
    const handleCloseDelete = () => {
        setId("");
        setOpenDelete(false);
    };

    const handleGetCategory = () => {
        PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_SONG_CATEGORY).then(
            (res) => {
                setLoading(false);
                if (res.status === 200) {
                    setData(res.data);
                    setFilteredData(res.data);
                }
            }
        );
    };

    useEffect(() => {
        setLoading(true);
        handleGetCategory();
    }, []);

    const handleAddEditCategory = (values) => {
        setButtonSpinner(true)
        const formdata = new FormData();
        if (id) {
            formdata.append("id", id);
        }
        formdata.append("name", values?.categoryName);
        formdata.append("musicCategory", values?.image);

        PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_SONG_CATEGORY, formdata)
            .then((res) => {
                setButtonSpinner(false)
                if (res.status === 201 || res.status === 200) {
                    handleClose();
                    handleGetCategory();
                    setSearchValue("");
                    PagesIndex.toasterSuccess(res?.message);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            })
            .catch((err) => {
                PagesIndex.toasterError(err.response.message);
            });
    };

    const handleDeleteCategory = () => {
        setDeleteButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiGetHandler(
            PagesIndex.Api.DELETE_SONG_CATEGORY + "/" + id
        ).then((res) => {
            setDeleteButtonSpinner(false)
            if (res.status === 200) {
                handleCloseDelete();
                handleGetCategory();
                PagesIndex.toasterSuccess(res?.message);
            } else {
                PagesIndex.toasterError(res?.message);
            }
        });
    };
    const requestSearch = (e) => {
        setSearchValue(e.target.value);
        let filteredData = data.filter((data) =>
            data?.name
                ?.toLowerCase()
                .includes(e?.target?.value?.trim()?.toLowerCase())
        );
        setCurrentPage(1);
        setFilteredData(filteredData);
    };
    const handleOpenStatusModal = (id) => {
        setId(id);
        setOpenStatusModal(true);
    };
    const handleCloseStatusModal = () => {
        setId("");
        setOpenStatusModal(false);
    };
    const handleStatus = () => {
        setStatusButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(
            PagesIndex.Api.ISACTIVE_SONG_CATEGORY,
            urlEncoded
        ).then((res) => {
            setStatusButtonSpinner(false)
            if (res.status === 200) {
                handleCloseStatusModal();
                PagesIndex.toasterSuccess(res?.message);
                handleGetCategory();
            } else {
                PagesIndex.toasterError(res?.message);
            }
        });
    };
    const renderImage = (values) => {
        const isUploadImg = values?.categoryImage || values.image;
        const isImageBlob = values.image instanceof Blob;
        const imageUrl = values?.categoryImage
            ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.categoryImage}`
            : null;
        let src;

        if (isImageBlob) {
            src = URL.createObjectURL(values.image);
        } else if (isUploadImg) {
            src = imageUrl;
        } else {
            src = PagesIndex.Svg.fileupload;
        }

        return (
            <img
                className={isUploadImg ? "upload-img" : "upload-profile-img"}
                src={src}
                alt="zone"
            />
        );
    };

    const renderTableRows = (setFieldValue) => {
        return filteredData
            ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
            ?.map((data) => {
                return (
                    <Index.TableRow
                        sx={{
                            "&:last-child td, &:last-child th": {
                                border: 0,
                            },
                        }}
                        key={data?._id}
                    >
                        <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.categoryName}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
         
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.createdBy?.name || "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
   
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.updatedBy?.name || "-"}
                            </Index.Typography>
                        </Index.TableCell>

                        {(adminDetails?.roleId?.permissions?.includes(
                            "song_category_edit"
                        ) ||
                            adminDetails?.roleId?.permissions?.includes(
                                "song_category_delete"
                            )) && (
                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    align="right"
                                    className="table-td"
                                >
                                    <Index.Tooltip
                                        title={data?.isActive ? "Active" : "Deactive"}
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                    >
                                        <Index.Box className="admin-switch-main">
                                            <Index.FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} />}
                                                className="admin-switch-lable"
                                                checked={data?.isActive}
                                                onChange={() => {
                                                    handleOpenStatusModal(data?._id);
                                                }}
                                            />
                                        </Index.Box>
                                    </Index.Tooltip>
                                </Index.TableCell>
                            )}
                        {(adminDetails?.roleId?.permissions?.includes(
                            "song_category_edit"
                        ) ||
                            adminDetails?.roleId?.permissions?.includes(
                                "song_category_log"
                            ) ||
                            adminDetails?.roleId?.permissions?.includes(
                                "song_category_delete"
                            )) && (
                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    align="right"
                                    className="table-td"
                                >
                                    <Index.Box className="admin-table-data-btn-flex ">
                                        {adminDetails?.roleId?.permissions?.includes(
                                            "song_category_edit"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Edit"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => {
                                                            setId(data?._id);
                                                            setFieldValue("categoryName", data.name);
                                                            handleOpen("Edit", data?._id, data);
                                                        }}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.blueedit}
                                                            className="admin-icon"
                                                            alt="Edit"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}

                                        {adminDetails?.roleId?.permissions?.includes(
                                            "song_category_log"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Log"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => handleOpenLogModal(data?._id)}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.logicon}
                                                            className="admin-icon"
                                                            alt="Log"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}

                                        {adminDetails?.roleId?.permissions?.includes(
                                            "song_category_delete"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Delete"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => handleOpenDelete(data?._id)}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.trash}
                                                            className="admin-icon"
                                                            alt="Delete"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}
                                    </Index.Box>
                                </Index.TableCell>
                            )}
                    </Index.TableRow>
                );
            });
    };

    const renderContent = (setFieldValue) => {
        if (filteredData?.length) {
            return renderTableRows(setFieldValue);
        } else {
            return <PagesIndex.DataNotFound />;
        }
    };




    const ScrollIntoView = () => {
        // Scroll to the first error when errors change
        const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
        useEffect(() => {
            if (errors && Object.keys(errors).length > 0) {
                const firstErrorField = Object.keys(errors)[0];
                let scrollDiv = document.getElementById("scrollDiv");
                const firstErrorElement = document.querySelector(
                    `[id="${firstErrorField}"]`
                );
                if (firstErrorElement) {
                    scrollDiv.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    firstErrorElement.focus();
                }
            }
        }, [isSubmitting]);
    };
    return (
        <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddEditCategory}
            initialValues={initialValues}
            validationSchema={
                id
                    ? PagesIndex.songCategoryEditSchema
                    : PagesIndex.songCategoryAddSchema
            }
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <>
                    <Index.Box className="admin-dashboard-content admin-user-list-content">
                        <Index.Box className="admin-user-list-flex admin-page-title-main">
                            <Index.Typography
                                className="admin-page-title admin-user-list-page-title"
                                component="h2"
                                variant="h2"
                            >
                                Song Category List
                            </Index.Typography>
                            <Index.Box className="admin-userlist-btn-flex">
                                <Index.Box className="admin-search-main">
                                    <Index.Box className="admin-search-box">
                                        <Index.Box className="admin-form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="admin-form-control"
                                                placeholder="Search"
                                                value={searchValue}
                                                onChange={requestSearch}
                                            />
                                            <img
                                                src={PagesIndex.Svg.search}
                                                className="admin-search-grey-img admin-icon"
                                                alt="search"
                                            ></img>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                {adminDetails?.roleId?.permissions?.includes(
                                    "song_category_add"
                                ) && (
                                        <Index.Box className="admin-userlist-inner-btn-flex">
                                            <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                                <Index.Button
                                                    className="admin-adduser-btn btn-primary"
                                                    onClick={() => {
                                                        setId("");
                                                        handleOpen("Add");
                                                    }}
                                                >
                                                    <img
                                                        src={PagesIndex.Svg.plus}
                                                        className="admin-plus-icon"
                                                        alt="plus"
                                                    />
                                                    <span>Add Song Category</span>
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    )}
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className="common-card">
                            <Index.Box
                                className={`admin-movie-category-table-main ${loading ? "" : "page-table-main"
                                    }`}
                            >
                                <Index.TableContainer
                                    component={Index.Paper}
                                    className="table-container"
                                >
                                    {loading ? (
                                        <PagesIndex.Loader />
                                    ) : (
                                        <Index.Table aria-label="simple table" className="table">
                                            <Index.TableHead className="table-head">
                                                <Index.TableRow className="table-row">
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="25%"
                                                    >
                                                        Song Category Name
                                                    </Index.TableCell>

                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="7%"
                                           
                                                    >
                                                        Created By
                                                    </Index.TableCell>

                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                        width="7%"
                                           
                                                    >
                                                        Updated By
                                                    </Index.TableCell>

                                                    {(adminDetails?.roleId?.permissions?.includes(
                                                        "song_category_edit"
                                                    ) ||
                                                        adminDetails?.roleId?.permissions?.includes(
                                                            "song_category_delete"
                                                        )) && (
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="3%"
                                                                align="right"
                                                            >
                                                                Status
                                                            </Index.TableCell>
                                                        )}
                                                    {(adminDetails?.roleId?.permissions?.includes(
                                                        "song_category_edit"
                                                    ) ||
                                                        adminDetails?.roleId?.permissions?.includes(
                                                            "song_category_log"
                                                        ) ||
                                                        adminDetails?.roleId?.permissions?.includes(
                                                            "song_category_delete"
                                                        )) && (
                                                            <Index.TableCell
                                                                component="th"
                                                                variant="th"
                                                                className="table-th"
                                                                width="4%"
                                                                align="right"
                                                            >
                                                                Action
                                                            </Index.TableCell>
                                                        )}
                                                </Index.TableRow>
                                            </Index.TableHead>
                                            <Index.TableBody className="table-body">
                                                {renderContent(setFieldValue)}
                                            </Index.TableBody>
                                        </Index.Table>
                                    )}
                                </Index.TableContainer>
                            </Index.Box>
                            <Index.Box className="admin-pagination-main">
                                {filteredData?.length ? (
                                    <Index.Pagination
                                        page={currentPage}
                                        count={Math.ceil(filteredData?.length / 10)}
                                        onChange={(event, newPage) => setCurrentPage(newPage)}
                                        variant="outlined"
                                        shape="rounded"
                                        className="admin-pagination"
                                    />
                                ) : (
                                    ""
                                )}
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="admin-modal"
                    >
                        <form onSubmit={handleSubmit}>
                            <Index.Box
                                sx={style}
                                className="admin-add-user-modal-inner-main admin-modal-inner"
                            >
                                <Index.Box className="admin-modal-header">
                                    <Index.Typography
                                        id="modal-modal-title"
                                        className="admin-modal-title"
                                        variant="h6"
                                        component="h2"
                                    >
                                        {addOrEdit} Song Category
                                    </Index.Typography>
                                    {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Box> */}
                                </Index.Box>
                                <Index.Box
                                    component={"form"}
                                    noValidate="novalidate"
                                    onSubmit={handleSubmit}
                                    className="admin-modal-hgt-scroll cus-scrollbar"
                                    id="scrollDiv"
                                >
                                    {/* <ScrollIntoView /> */}
                                    <Index.Box className="admin-input-box admin-modal-input-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                            Song Category Name<span className="astrick-sing">*</span>
                                        </Index.FormHelperText>
                                        <Index.Box className="admin-form-group">
                                            <Index.TextField
                                                name="categoryName"
                                                fullWidth
                                                id="categoryName"
                                                className="admin-form-control"
                                                placeholder="Enter Song Category Name"
                                                onChange={handleChange}
                                                value={values?.categoryName}
                                                error={errors.categoryName && touched.categoryName}
                                                inputProps={{ maxLength: 40 }}
                                            />
                                            {touched.categoryName && errors.categoryName && (
                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                    {errors.categoryName}
                                                </PagesIndex.FormHelperText>
                                            )}
                                        </Index.Box>
                                    </Index.Box>


                                    <Index.Box className="admin-input-box admin-add-webseries-input-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                            Thumbnail<span className="astrick-sing">*</span>
                                            <span className="option-condition-text">
                                                (Image must be 250px(Width) * 250px(Height))
                                            </span>
                                        </Index.FormHelperText>
                                        <Index.Box className="file-upload-main thumbnail-box">
                                            <Index.Button
                                                variant="contained"
                                                component="label"
                                                className="file-upload-button"
                                            >
                                                {renderImage(values)}
                                                <input
                                                    hidden
                                                    accept="image/*"
                                                    multiple
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    onChange={(e) => {
                                                        if (e.target.files?.length !== 0) {

                                                            setFieldValue("image", e.target.files[0]);
                                                        }
                                                    }}

                                                />
                                            </Index.Button>
                                            {touched.image && errors.image && (
                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                    {errors.image}
                                                </PagesIndex.FormHelperText>
                                            )}
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="modal-footer">
                                    <Index.Box className="admin-modal-user-btn-flex">
                                        <Index.Box className="admin-discard-btn-main border-btn-main">
                                            <Index.Button
                                                className="admin-discard-user-btn border-btn"
                                                onClick={handleClose}
                                                disabled={buttonSpinner}

                                            >
                                                Discard
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="admin-save-btn-main btn-main-primary">
                                            <Index.Button
                                                className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                                                type="submit"
                                                disabled={buttonSpinner}
                                            >
                                                {buttonSpinner ? <PagesIndex.Spinner /> :
                                                    (<>
                                                        <img
                                                            src={PagesIndex.Svg.save}
                                                            className="admin-user-save-icon"
                                                            alt="Save"
                                                        />
                                                        <span>{id ? "Update" : "Add"}</span>
                                                    </>)
                                                }
                                            </Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </form>
                    </Index.Modal>
                    <PagesIndex.LogModal
                        openModal={openLogModal}
                        handleCloseModal={handleCloseLogModal}
                        data={idData}
                        api={PagesIndex.Api.GET_SONG_CATEGORY_ACTIVITY_LOG + "/" + idData}
                        setOpenLogModal={setOpenLogModal}
                    />

                    <PagesIndex.DeleteModal
                        openModal={openDelete}
                        handleCloseModal={handleCloseDelete}
                        data={"category"}
                        loading={deleteButtonSpinner}
                        handleFunction={handleDeleteCategory}
                    />
                    <PagesIndex.ChangeStatusModal
                        data={"song category"}
                        statusButtonSpinner={statusButtonSpinner}
                        openStatusModal={openStatusModal}
                        handleCloseStatusModal={handleCloseStatusModal}
                        handleChangeStatus={handleStatus}
                    />
                </>
            )}
        </PagesIndex.Formik>
    );
}
