import React from 'react'
import Index from '../../../container/Index'
import PagesIndex from '../../../container/PagesIndex';

const DeleteModal = ({openModal,handleCloseModal,handleFunction,loading,data}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
    };


  return (
    <Index.Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="admin-modal"
>
    <Index.Box
        sx={style}
        className="admin-delete-modal-inner-main admin-modal-inner"
    >
        <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
                <img
                    src={PagesIndex.Svg.closecircle}
                    className="admin-user-circle-img"
                    alt="Close"
                />
            </Index.Box>
            <Index.Typography
                className="admin-delete-modal-title"
                component="h2"
                variant="h2"
            >
                Are you sure?
            </Index.Typography>
            <Index.Typography
                className="admin-delete-modal-para admin-common-para"
                component="p"
                variant="p"
            >
                Do you really want to delete this {data}? This process cannot be
                undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
                <Index.Button
                    className="admin-modal-cancel-btn border-btn"
                    onClick={handleCloseModal}
                    disabled={loading}
                >
                    Cancel
                </Index.Button>
                <Index.Button
                    className="delete-user admin-modal-delete-btn btn"
                    onClick={handleFunction}
                    disabled={loading}
                >
                    {loading ? (
                        <PagesIndex.Spinner />
                    ) : (
                        <span>Delete</span>
                    )}
                </Index.Button>
            </Index.Box>
        </Index.Box>
    </Index.Box>
</Index.Modal>
  )
}

export default DeleteModal