import React, { useEffect, useState } from 'react'
import Index from '../../../container/Index';
import PagesIndex from '../../../container/PagesIndex';
import { useNavigate } from 'react-router-dom';

const LogModal = ({ openModal, handleCloseModal, data,api }) => {
    console.log(data);
    
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
    };
    const navigate = useNavigate();

    const [activityLog, setActivityLog] = useState([]);
    const [loading, setLoading] = useState(false)
    const handleOpenLogModal = () => {
        setLoading(true);
        PagesIndex.apiGetHandler(
           api
        ).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                setActivityLog(res.data?.activityLog);
            }
        });
    };

    useEffect(() => {
        if(openModal==true){
            handleOpenLogModal()
        }
    }, [data])
    const renderActivityTableRows = () => {
        return activityLog
            .slice()
            .reverse()
            .map((data, index) => {
                let updatedInfo = "";
                if (data?.updatedBy?.name) {
                    updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
                        ? PagesIndex.moment(data?.dateTime).format(
                            "DD/MM/YYYY hh:mm:ss A"
                        )
                        : "-"
                        }`;
                } else {
                    updatedInfo = "-";
                }
                return (
                    <Index.TableRow
                        sx={{
                            "&:last-child td, &:last-child th": {
                                border: 0,
                            },
                        }}
                        key={data?._id}
                    >
                        <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {data?.actionType === "Updated"
                                    ? "Video updated"
                                    : data?.actionType}
                            </Index.Typography>
                        </Index.TableCell>

                        <Index.TableCell component="td" variant="td" className="table-td">
                            {updatedInfo}
                        </Index.TableCell>

                        <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Tooltip
                                title="View"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                            >
                                <Index.Button
                                    className="admin-table-data-btn"
                                    onClick={() =>
                                        navigate(`/admin/videos/video-activity-log`, {
                                            state: {
                                                log: activityLog,
                                                index: index,
                                            },
                                        })
                                    }
                                >
                                    <img
                                        src={PagesIndex.Svg.yelloweye}
                                        className="admin-icon"
                                        alt="View"
                                    ></img>
                                </Index.Button>
                            </Index.Tooltip>
                        </Index.TableCell>
                    </Index.TableRow>
                );
            });
    };

    const renderActivityLogContent = () => {
        if (activityLog?.length) {
            return renderActivityTableRows();
        } else {
            return <PagesIndex.DataNotFound />;
        }
    };

    return (
        <Index.Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
        >
            <Index.Box
                sx={style}
                className="admin-log-modal-inner-main admin-modal-inner"
            >
                <Index.Box className="admin-modal-header">
                    <Index.Typography
                        id="modal-modal-title"
                        className="admin-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        {" "}
                        Activity Log
                    </Index.Typography>
                    <Index.Button
                        className="modal-close-btn"
                        onClick={handleCloseModal}
                    >
                        <img
                            src={PagesIndex.Svg.closeblack}
                            className="admin-modal-close-icon"
                            alt="Close"
                        />
                    </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                    <Index.Box className="common-card">
                        <Index.Box
                            className={`admin-news-activity-table-main ${loading ? "" : "page-table-main"
                                }`}
                        >
                            <Index.TableContainer
                                component={Index.Paper}
                                className="table-container"
                            >
                                {loading ? (
                                    <PagesIndex.Loader />
                                ) : (
                                    <Index.Table aria-label="simple table" className="table">
                                        <Index.TableHead className="table-head">
                                            <Index.TableRow className="table-row">
                                                <Index.TableCell
                                                    component="th"
                                                    variant="th"
                                                    className="table-th"
                                                    width="50%"
                                                >
                                                    Activity Log
                                                </Index.TableCell>

                                                <Index.TableCell
                                                    component="th"
                                                    variant="th"
                                                    className="table-th"
                                                    width="40%"
                                                >
                                                    Updated Date & Time
                                                </Index.TableCell>

                                                <Index.TableCell
                                                    component="th"
                                                    variant="th"
                                                    className="table-th"
                                                    width="10%"
                                                    align="right"
                                                >
                                                    Action
                                                </Index.TableCell>
                                            </Index.TableRow>
                                        </Index.TableHead>
                                        <Index.TableBody className="table-body">
                                            {renderActivityLogContent()}
                                        </Index.TableBody>
                                    </Index.Table>
                                )}
                            </Index.TableContainer>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Modal>
    )
}

export default LogModal