import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../movies/movies.css";
import "../movies/moviesResponsive.css";
import PropTypes from "prop-types";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const TableData = ({
  filteredData,
  currentPage,
  handleOpenDelete,
  handleChangeStatusMovie,
}) => {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const [activityLog, setActivityLog] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
   const [openLogModal, setOpenLogModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);

  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_MOVIE_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        setActivityLog(res.data?.activityLog);
      }
    });
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data, index) => {
        let updatedInfo = "";
        if (data?.updatedBy?.name) {
          updatedInfo = `${data?.updatedBy?.name}-${data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format("DD/MM/YYYY HH:mm:ss")
              : "-"
            }`;
        } else {
          updatedInfo = "-";
        }
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {data?.actionType === "Updated"
                  ? "Movie Updated"
                  : data?.actionType}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              {updatedInfo}
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title="View"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() =>
                    navigate(`/admin/movies/movie-activity-log`, {
                      state: {
                        log: activityLog,
                        index: index,
                      },
                    })
                  }
                >
                  <img
                    src={PagesIndex.Svg.yelloweye}
                    className="admin-icon"
                    alt="View"
                  ></img>
                </Index.Button>
              </Index.Tooltip>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    return (
      <>
        {!activityLoading &&
          activityLog?.length > 0 &&
          renderActivityTableRows()}
        {!activityLoading && (!activityLog || activityLog.length === 0) && (
          <PagesIndex.DataNotFound />
        )}
      </>
    );
  };

  return (
    <>
      {filteredData?.length ? (
        filteredData
          ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
          ?.map((data, index) => {
            return (
              <Index.TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={data?._id}
              >
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="table-td"
                >
                  <Index.Typography className="admin-table-data-text">
                    {" "}
                    {(currentPage - 1) * 10 + (index + 1)}
                  </Index.Typography>
                </Index.TableCell>
                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={data?.title}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {" "}
                      {data?.title}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell>
                {/* <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  <Index.Tooltip
                    title={data?.description}
                    arrow
                    placement="top"
                    className="admin-tooltip"
                  >
                    <Index.Typography className="admin-table-data-text">
                      {" "}
                      {data?.description}
                    </Index.Typography>
                  </Index.Tooltip>
                </Index.TableCell> */}
                {/* <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  <Index.Typography className="admin-table-data-text">
                    {" "}
                    {data?.categoryId?.length
                      ? data?.categoryId
                        ?.map((data) => data?.categoryName)
                        .toString()
                        ?.replaceAll(",", ", ")
                      : "-"}
                  </Index.Typography>
                </Index.TableCell> */}
            <Index.TableCell
  component="td"
  variant="td"
  className="table-td"
>
  {data?.cast?.length ? (
    <Index.Tooltip
      title={  data?.cast?.length ?data?.cast?.map((item) => item?.castId?.name).join(", "):''}
      arrow
      placement="top"
      className="admin-tooltip"
    >
   {  data?.cast?.length ?data?.cast?.map((item) => item?.castId?.name).join(", "):'-'}
    </Index.Tooltip>
  ) : (
    "-"
  )}
</Index.TableCell>
                {adminDetails?.roleId?.permissions?.includes("songs_edit") && (
                  <Index.TableCell
                    component="td"
                    variant="td"
                    className="table-td"
                  >
                    <Index.Tooltip
                      title={data?.isActive ? "Active" : "Deactive"}
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Box className="admin-switch-main">
                        <Index.FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                          className="admin-switch-lable"
                          checked={data?.isActive}
                          onChange={() => handleChangeStatusMovie(data?._id)}
                        />
                      </Index.Box>
                    </Index.Tooltip>
                  </Index.TableCell>
                )}
                <Index.TableCell
                  component="td"
                  variant="td"
                  className="table-td"
                >
                  <Index.Box className="admin-table-data-btn-flex">
                    {adminDetails?.roleId?.permissions?.includes(
                      "songs_edit"
                    ) && (
                        <Index.Tooltip
                          title="Edit"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => {
                              navigate("/admin/add-song", {
                                state: { data: data },
                              });
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.blueedit}
                              className="admin-icon"
                              alt="Edit"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}

                    {adminDetails?.roleId?.permissions?.includes(
                      "songs_log"
                    ) && (
                        <Index.Tooltip
                          title="Log"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenLogModal(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.logicon}
                              className="admin-icon"
                              alt="Log"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}

                    <Index.Tooltip
                      title="View"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => {
                          navigate(`/admin/view-song/${data?._id}`, {
                            state: { data: data },
                          });
                        }}
                      >
                        <img
                          src={PagesIndex.Svg.yelloweye}
                          className="admin-icon"
                          alt="View"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                    {adminDetails?.roleId?.permissions?.includes(
                      "songs_delete"
                    ) && (
                        <Index.Tooltip
                          title="Delete"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleOpenDelete(data?._id)}
                          >
                            <img
                              src={PagesIndex.Svg.trash}
                              className="admin-icon"
                              alt="Delete"
                            ></img>
                          </Index.Button>
                        </Index.Tooltip>
                      )}
                  </Index.Box>
                </Index.TableCell>
              </Index.TableRow>
            );
          })
      ) : (
        <PagesIndex.DataNotFound />
      )}
      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box className="page-table-main movie-activity-table-main">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="50%"
                          >
                            Activity Log
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="40%"
                          >
                            Updated Date & Time
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                            align="right"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};
export default function Music() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  // delete modal
  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  useEffect(() => {
    setLoading(true);
    handleGetMovies();
  }, []);

  const handleGetMovies = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_SONG).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setData(res?.data);
        setFilteredData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleDeleteMovie = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_SONG,urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetMovies();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    PagesIndex.apiPostHandler(PagesIndex.Api.SONG_STATUS,{id:id}).then(
      (res) => {
        setStatusButtonSpinner(false)
        if (res.status === 200) {
          handleGetMovies();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  const requestSearch = (e) => {
    let result = data.filter((data) => {
      let castNames = data.cast.flatMap((cast) =>
        cast.castId.name.toLowerCase()
      );
      let title = data?.title
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());

      let Description = data?.description
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());

      let Cast = castNames.includes(e?.target?.value?.trim()?.toLowerCase());
      return title || Cast || Description;
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Songs List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {adminDetails?.roleId?.permissions?.includes("movies_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => {
                      navigate("/admin/add-song");
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Songs</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box
            className={`admin-movie-table-main  ${loading ? "" : "page-table-main"
              }`}
          >
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="4%"
                      >
                        Sr. No.
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Title
                      </Index.TableCell>
                      {/* <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="15%"
                    >
                      Description
                    </Index.TableCell> */}
                      {/* <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Category
                      </Index.TableCell> */}
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="12%"
                      >
                        Cast
                      </Index.TableCell>
                      {adminDetails?.roleId?.permissions?.includes(
                        "songs_edit"
                      ) && (
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="1%"
                          >
                            Status
                          </Index.TableCell>
                        )}
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        align="right"
                        width="5%"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <TableData
                        filteredData={filteredData}
                        currentPage={currentPage}
                        handleOpenDelete={handleOpenDelete}
                        handleChangeStatusMovie={(id) =>
                          handleOpenStatusModal(id)
                        }
                      />
                    )}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this song? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteMovie}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"song"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}
TableData.propTypes = {
  filteredData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleOpenDelete: PropTypes.func.isRequired,
  handleChangeStatusMovie: PropTypes.func.isRequired,
};
