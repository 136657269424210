import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpeg";
import Sidebar from "../component/admin/defaulLayout/Sidebar";
import Header from "../component/admin/defaulLayout/Header";
import PaidLable from "../component/common/lables/PaidLable";
import FailedLable from "../component/common/lables/FailedLable";
import PendingLable from "../component/common/lables/PendingLable";
import PrimaryButton from "../component/common/Button/PrimaryButton";
import BorderButton from "../component/common/Button/BorderButton";
import AuthBackground from "../component/admin/defaulLayout/AuthBackground";
import AuthFooter from "../component/admin/defaulLayout/AuthFooter";
import EditProfile from "../component/admin/pages/account/editProfile/EditProfile";
import ChangePassword from "../component/admin/pages/account/changePassword/ChangePassword";
import MovieDetailsTabs from "./admin/pages/movies/movieDetailsTabs/MovieDetailsTabs";
import ViewerTable from "./admin/pages/movies/movieDetailsTabs/ViewerTable";
import RatingTable from "./admin/pages/movies/movieDetailsTabs/RatingTable";
import ActivityLogTable from "./admin/pages/movies/movieDetailsTabs/ActivityLogTable";
import WatchlistTable from "./admin/pages/movies/movieDetailsTabs/WatchlistTable";
import WebSeriesActivityLogTable from "./admin/pages/webSeries/webSeriesDetailsTabs/WebSeriesActivityLogTable";
import WebSeriesDetailsTabs from "./admin/pages/webSeries/webSeriesDetailsTabs/WebSeriesDetailsTabs";
import WebSeriesRatingTable from "./admin/pages/webSeries/webSeriesDetailsTabs/WebSeriesRatingTable";
import WebSeriesViewerTable from "./admin/pages/webSeries/webSeriesDetailsTabs/WebSeriesViewerTable";
import WebSeriesWatchlistTable from "./admin/pages/webSeries/webSeriesDetailsTabs/WebSeriesWatchlistTable";
import WebSeriesListOfSeason from "./admin/pages/webSeries/webSeriesDetailsTabs/WebSeriesListOfSeason";
import AdvertiserInformation from "./admin/pages/advertiser/AdvertiserInformation";
import Advertisments from "./admin/pages/advertiser/Advertisments";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../config/Api";
import {
  apiGetHandler,
  apiPostHandler,
  apiPostHandlerVideoUpload,
} from "../config/ApiHandler";
import Loader from "../component/common/loader/Loader";
import {
  rolePermissionSchema,
  rolePermissionEditSchema,
  cmsSchema,
  subAdminSchema,
  subAdminEditSchema,
  categorySchema,
  clientCategorySchema,
  clientSubCategorySchema,
  clientMediaPatformSchema,
  categoryEditSchema,
  stateSchema,
  billingInformation,
  dashbordFilterDate,
  userBlockedSchema,
  FAQSchema,
  zoneAddSchema,
  zoneEditSchema,
  newsAddSchemaWithShedule,
  newsEditSchemaWithShedule,
  districtAddSchema,
  settingValidationSchema,
  districtEditSchema,
  tagSchema,
  newsAddSchema,
  newsEditSchema,
  actorSchema,
  actorEditSchema,
  movieEditSchema,
  movieSchema,
  seriesSchema,
  seriesEditSchema,
  seasonSchema,
  episodeSchema,
  episodeEditSchema,
  exportNewsSchema,
  adSchema,
  adDraftSchema,
  eventSchema,
  salesOrderModal,
  salesOrder,
  salesSubOrder,
  advertisementSchema,
  addUserValidationSchema,
  advertisementEditSchema,
  responseSchema,
  advertiserFilterSchema,
  MovieCategoryEditSchema,
  MovieCategoryAddSchema,
  adFilterSchema,
  userActivityListFilterSchema,
  // newsListFilterSchema,
  homeCategorySchema,
  newsAddDraftSchema,
  sponsorAddSchema,
  channelSchema,
  programTypeAddSchema,
  programAddSchema,
  uploadDocumentSchema,
  uploadSalesDocumentSchema,
  elementAddEditSchema,
  programEditSchema,
  creativeAddSchema,
  creativeEditSchema,
  slotAddEditSchema,
  scheduleAddEditSchema,
  addSlotSchema,
  websiteScheduleAddSchema,
  websiteScheduleEditSchema,
  salesSubOrderModal,
  agencySchema,
  uploadVideoSchema,
  editVideoSchema,
  uploadInternalVideoSchema,
  serverAddSchema,
  songCategoryAddSchema,
  songCategoryEditSchema,
  songSchema,
  songEditSchema,
  homeCategoryMoviesSchema,
  homeCategorySongSchema,
  albumSchema,
  playlistSchema,
} from "../utils/validation/FormikValidation";
import { Formik, useFormikContext } from "formik";
import moment from "moment";
import { toasterSuccess, toasterError } from "../utils/toaster/Toaster";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FormHelperText, Stack } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import DataNotFound from "../component/common/dataNotFound/DataNotFound";
import AutoCompaleteLocation from "./admin/pages/news/AutoCompleteLocation";
import Location from "./admin/pages/adsManagement/SelectMultipleLocation";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Gif from "../assets/Gif";
import PageLoader from "../component/common/loader/PageLoader";
import {
  DateTimePicker,
  DesktopDateTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import ChangeStatusModal from "../component/common/changeStatusModal/ChangeStatusModal";
import LinearProgress from "@mui/material/LinearProgress";
import dayjs from "dayjs";
import BillingInformation from "./admin/pages/advertiser/BillingInformation";
import UploadDocuments from "./admin/pages/advertiser/UploadDocuments";
import Creative from "./admin/pages/advertiser/Creative";
import Spinner from "../component/common/spinner/Spinner";
import useDebounceHook from "../component/common/useDebounceHook/useDebounceHook";
import { SliderViewModal } from "../component/common/dashboardSliderView/SliderViewModal";
import DeleteModal from "../component/common/deleteModal/DeleteModal";
import LogModal from "../component/common/logModal/LogModal";
import { AlbumModal } from "../component/common/albumModal/AlbumModal";

const PagesIndex = {
  Svg,
  Spinner,
  Png,
  PageLoader,
  Gif,
  Jpg,
  Sidebar,
  Header,
  Loader,
  PaidLable,
  FailedLable,
  PendingLable,
  PrimaryButton,
  DatePicker,
  AdapterDayjs,
  AuthFooter,
  AuthBackground,
  BorderButton,
  EditProfile,
  ChangePassword,
  useDispatch,
  useSelector,
  Api,
  apiGetHandler,
  rolePermissionSchema,
  LocalizationProvider,
  rolePermissionEditSchema,
  apiPostHandler,
  Formik,
  moment,
  toasterError,
  toasterSuccess,
  useParams,
  cmsSchema,
  subAdminSchema,
  subAdminEditSchema,
  FormHelperText,
  categorySchema,
  clientCategorySchema,
  clientSubCategorySchema,
  clientMediaPatformSchema,
  categoryEditSchema,
  stateSchema,
  billingInformation,
  dashbordFilterDate,
  userBlockedSchema,
  FAQSchema,
  ReactApexChart,
  DataNotFound,
  useNavigate,
  zoneAddSchema,
  zoneEditSchema,
  newsAddSchemaWithShedule,
  newsEditSchemaWithShedule,
  districtAddSchema,
  settingValidationSchema,
  districtEditSchema,
  tagSchema,
  newsAddSchema,
  newsEditSchema,
  AutoCompaleteLocation,
  Location,
  actorSchema,
  actorEditSchema,
  movieEditSchema,
  movieSchema,
  useLocation,
  seriesSchema,
  seriesEditSchema,
  seasonSchema,
  episodeSchema,
  episodeEditSchema,
  exportNewsSchema,
  adSchema,
  adDraftSchema,
  salesOrderModal,
  salesOrder,
  salesSubOrder,
  eventSchema,
  addUserValidationSchema,
  MovieDetailsTabs,
  ViewerTable,
  RatingTable,
  ActivityLogTable,
  WatchlistTable,
  WebSeriesWatchlistTable,
  WebSeriesViewerTable,
  WebSeriesRatingTable,
  WebSeriesDetailsTabs,
  WebSeriesActivityLogTable,
  WebSeriesListOfSeason,
  advertisementSchema,
  advertisementEditSchema,
  AdvertiserInformation,
  BillingInformation,
  Advertisments,
  responseSchema,
  DateTimePicker,
  advertiserFilterSchema,
  adFilterSchema,
  userActivityListFilterSchema,
  // newsListFilterSchema,
  MovieCategoryEditSchema,
  MovieCategoryAddSchema,
  adFilterSchema,
  DesktopDateTimePicker,
  Stack,
  ChangeStatusModal,
  Link,
  homeCategorySchema,
  newsAddDraftSchema,
  LinearProgress,
  apiPostHandlerVideoUpload,
  sponsorAddSchema,
  useFormikContext,
  channelSchema,
  dayjs,
  programTypeAddSchema,
  TimePicker,
  programAddSchema,
  UploadDocuments,
  uploadDocumentSchema,
  uploadSalesDocumentSchema,
  elementAddEditSchema,
  programEditSchema,
  creativeAddSchema,
  creativeEditSchema,
  Creative,
  slotAddEditSchema,
  scheduleAddEditSchema,
  addSlotSchema,
  websiteScheduleAddSchema,
  websiteScheduleEditSchema,
  salesSubOrderModal,
  agencySchema,
  uploadVideoSchema,
  editVideoSchema,
  uploadInternalVideoSchema,
  serverAddSchema,
  useDebounceHook,
  SliderViewModal,
  DeleteModal,
  LogModal,
  songCategoryAddSchema,
  songCategoryEditSchema,
  songSchema,
  songEditSchema,
  homeCategoryMoviesSchema,
  homeCategorySongSchema,
  albumSchema,
  playlistSchema,
  AlbumModal
};

export default PagesIndex;
