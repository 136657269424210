import React from 'react'
import Index from '../../../container/Index';
import PagesIndex from '../../../container/PagesIndex';

export const SliderViewModal = ({openModal,handleCloseViewModal,data}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
    };
  return (

    <Index.Modal
    open={openModal}
    onClose={handleCloseViewModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="admin-modal"
>
    <Index.Box
        sx={style}
        className="admin-log-modal-inner-main admin-modal-inner cus-view-slider-inner-modal"
    >
        <Index.Box className="admin-modal-header">
            <Index.Typography
                id="modal-modal-title"
                className="admin-modal-title"
                variant="h6"
                component="h2"
            >
                {" "}
                View Image
            </Index.Typography>
            <Index.Button
                className="modal-close-btn"
                onClick={handleCloseViewModal}
            >
                <img
                    src={PagesIndex.Svg.closeblack}
                    className="admin-modal-close-icon"
                    alt="Close"
                />
            </Index.Button>
        </Index.Box>
        {/* <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
                <Index.Box
                    className={`admin-news-activity-table-main ${activityLoading ? "" : "page-table-main"
                        }`}
                >
                    <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                    >
                        {activityLoading ? (
                            <PagesIndex.Loader />
                        ) : (
                            <Index.Table aria-label="simple table" className="table">
                                <Index.TableHead className="table-head">
                                    <Index.TableRow className="table-row">
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="50%"
                                        >
                                            Activity Log
                                        </Index.TableCell>

                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="40%"
                                        >
                                            Updated Date & Time
                                        </Index.TableCell>

                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="10%"
                                            align="right"
                                        >
                                            Action
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                    {renderActivityLogContent()}
                                </Index.TableBody>
                            </Index.Table>
                        )}
                    </Index.TableContainer>
                </Index.Box>
            </Index.Box>
        </Index.Box> */}
          <Index.Box className="view-details-card">
        <Index.Box className="admin-file-view-card common-card">
          <Index.Box className="admin-file-view-main">
           
            <img
              src={
                data?.sliderImage
                  ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.sliderImage}`
                  : PagesIndex.Svg.noimgfound
              }
              className="admin-file-view-img cus-slider-image-view"
              alt="upload img"
            />
          </Index.Box>
        </Index.Box>
        
      </Index.Box>

    </Index.Box>
</Index.Modal>
  )
}
