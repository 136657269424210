const ADMIN = "admin"

const Api = {
  LOGIN: `${ADMIN}/login`,
  PROFILE: `${ADMIN}/profile`,
  EDIT_PROFILE: `${ADMIN}/update-profile`,
  CHANGE_PASSWORD: `${ADMIN}/change-password`,
  VALIDATE_EMAIL: `${ADMIN}/forgot-password`,
  RESEND_OTP: `${ADMIN}/resend-otp`,
  VERIFY_OTP: `${ADMIN}/verify-otp`,
  RESET_PASSWORD: `${ADMIN}/reset-password`,
  LOGIN_LOG: `${ADMIN}/login-log`,
  USER_LOGOUT: `${ADMIN}/logout`,






  GET_ROLES: `${ADMIN}/get-roles`,
  ADD_EDIT_ROLEPERMISSION: `${ADMIN}/add-edit-role`,
  DELETE_ROLE: `${ADMIN}/remove-role`,
  ISACTIVE_ROLE: `${ADMIN}/role-active-deActive`,
  GET_ROLE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-role`,

  GET_CMS: `${ADMIN}/get-cms-details`,
  ADD_EDIT_CMS: `${ADMIN}/add-edit-cms`,
  GET_CMS_HELP_SUPPORT: `${ADMIN}/get-cms-details-help-support`,

  GET_SUBADMIN: `${ADMIN}/get-sub-admin-list`,
  ADD_EDIT_SUBADMIN: `${ADMIN}/add-edit-sub-admin`,
  DELETE_SUBADMIN: `${ADMIN}/delete-sub-admin`,
  ISACTIVE_SUBADMIN: `${ADMIN}/admin-active-deActive`,
  GET_ISACTIVE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-sub-admin`,

  GET_CATEGORY: `${ADMIN}/getAllCategory`,
  ADD_EDIT_CATEGORY: `${ADMIN}/addEditCategory`,
  DELETE_CATEGORY: `${ADMIN}/deleteCategory`,
  ISACTIVE_CATEGORY: `${ADMIN}/category-active-deActive`,
  GET_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-category`,

  GET_STATE: `${ADMIN}/getAllState`,
  ADD_EDIT_STATE: `${ADMIN}/addEditState`,
  DELETE_STATE: `${ADMIN}/deleteState`,
  ISACTIVE_STATE: `${ADMIN}/state-active-deActive`,
  GET_STATE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-state`,

  GET_FAQ: `${ADMIN}/getAllFaq`,
  ADD_EDIT_FAQ: `${ADMIN}/addEditFaq`,
  DELETE_FAQ: `${ADMIN}/deleteFaq`,
  GET_FAQ_ACTIVITY_LOG: `${ADMIN}/get-activity-log-faq`,

  GET_SETTING_DATA: `${ADMIN}/list-site-setting`,
  UPDATE_SETTING: `${ADMIN}/update-site-setting`,
  USER_LIST: `${ADMIN}/user-list`,
  ISACTIVE_USER: `${ADMIN}/user-active-deActive`,
  GET_USER_DETAILS: `${ADMIN}/get-user-detail`,
  GET_USER_COUNT: `${ADMIN}/get-user-views-news-rating`,
  GET_USER_DETAILS_GRAPH: `${ADMIN}/user-content-graph`,
  ADD_EDIT_USER_BLOCK_REASON: `${ADMIN}/user-block-unblock`,
  ADD_USER: `${ADMIN}/admin-add-edit-user`,
  GET_ADMIN_ACTIVITY: `${ADMIN}/get-admin-activity`,
  GET_USER_GRAPH_DATA: `${ADMIN}/ads-user-list`,
  EXPORT_ADSPLAY_LIST: `${ADMIN}/export-adsplay-list`,
  GET_USER_VOTES_LIST: `${ADMIN}/user-vote-list`,

  ADD_EDIT_ZONEMASTER: `${ADMIN}/addEditZoneMaster`,
  GET_ZONE_BY_STATE: `${ADMIN}/getZoneByState`,
  GET_ZONEMASTER: `${ADMIN}/getAllZoneMaster`,
  DELETE_ZONEMASTER: `${ADMIN}/deleteZoneMaster`,
  ISACTIVE_ZONE: `${ADMIN}/zone-active-deActive`,
  GET_ZONE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-zone`,

  ADD_EDIT_DISTRICTMASTER: `${ADMIN}/addEditDistrictMaster`,
  GET_DISTRICTMASTER: `${ADMIN}/getAllDistrictMaster`,
  DELETE_DISTRICTMASTER: `${ADMIN}/deleteDistrictMaster`,
  ISACTIVE_DISTRICTMASTER: `${ADMIN}/district-active-deActive`,
  GET_DISTRICT_BY_ZONE: `${ADMIN}/getDistrictByZone`,
  GET_DISTRICT_ACTIVITY_LOG: `${ADMIN}/get-activity-log-district`,

  GET_TAG: `${ADMIN}/getAllTag`,
  ADD_EDIT_TAG: `${ADMIN}/addEditTag`,
  DELETE_TAG: `${ADMIN}/deleteTag`,
  ISACTIVE_TAG: `${ADMIN}/tag-active-deActive`,
  GET_TAG_ACTIVITY_LOG: `${ADMIN}/get-activity-log-tag`,

  GET_MOVIE_TAG: `${ADMIN}/get-all-tag-movie`,
  ADD_EDIT_MOVIE_TAG: `${ADMIN}/add-edit-tag-type-movie`,
  DELETE_MOVIE_TAG: `${ADMIN}/delete-tag-type-movie`,
  ISACTIVE_MOVIE_TAG: `${ADMIN}/tag-active-deActive-movie`,
  GET_MOVIE_TAG_ACTIVITY_LOG: `${ADMIN}/get-activity-log-tag-type-movie`,

  ADD_EDIT_NEWS: `${ADMIN}/addEditNews`,
  GET_NEWS: `${ADMIN}/getAllNews`,
  DELETE_NEWS: `${ADMIN}/deleteNews`,
  ISACTIVE_NEWS: `${ADMIN}/news-active-deActive`,
  GET_NEWS_ACTIVITY_LOG: `${ADMIN}/get-activity-log-news`,
  VIEW_NEWS: `${ADMIN}/view-news`,
  EXPORT_NEWS: `${ADMIN}/export-news`,
  GET_ALL_NEWS: `${ADMIN}/all-news`,
  GET_NEWS_GRAPH_DATA: `${ADMIN}/news-graph-data`,
  GET_USER_NEWS_RATING: `${ADMIN}/get-user-news-rating`,
  GET_USER_VOTE_RATING: `${ADMIN}/vote-list`,
  CHANGE_AD_STATUS_OF_NEWS: `${ADMIN}/change-status-ads-play`,

  GET_USER_QUERY: `${ADMIN}/get-user-query`,
  GET_USER_CONTECT_LIST: `${ADMIN}/user-contact-list`,
  GET_FEEDBACK: `${ADMIN}/get-feedback`,

  ADD_EDIT_ACTOR: `${ADMIN}/add-edit-cast`,
  GET_ALL_ACTOR: `${ADMIN}/get-cast`,
  DELETE_ACTOR: `${ADMIN}/deleteCast`,

  ADD_EDIT_MOVIES: `${ADMIN}/add-edit-movies`,
  GET_ALL_MOVIES: `${ADMIN}/get-movies`,
  DELETE_MOVIES: `${ADMIN}/delete-movies`,
  MOVIES_STATUS: `${ADMIN}/action-movies`,
  VIEW_MOVIE_DATA: `${ADMIN}/view-movies`,

  GET_ALL_MOVIE_CATEGORY: `${ADMIN}/get-movie-category`,
  GET_MOVIE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-movie`,
  DELETE_MOVIE_CATEGORY: `${ADMIN}/delete-movie-category`,
  ADD_EDIT_MOVIE_CATEGORY: `${ADMIN}/add-edit-movie-category`,
  ISACTIVE_MOVIE_CATEGORY: `${ADMIN}/action-movies-category`,
  GET_MOVIE_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-movie-category`,

  GET_ALL_WEB_SERIES: `${ADMIN}/get-webseries`,
  VIEW_WEB_SERIES_DATA: `${ADMIN}/view-webseries`,

  GET_SEASON: `${ADMIN}/get-season`,
  ADD_EDIT_SEASON: `${ADMIN}/add-edit-season`,
  DELETE_SEASON: `${ADMIN}/delete-season`,
  SEASON_STATUS: `${ADMIN}/season-active-deActive`,

  GET_EPISODE: `${ADMIN}/get-episode`,
  ADD_EDIT_EPISODE: `${ADMIN}/add-edit-episode`,
  DELETE_EPISODE: `${ADMIN}/delete-episode`,
  EPISODE_STATUS: `${ADMIN}/episode-active-deActive`,

  ADD_EDIT_EVENT: `${ADMIN}/add-edit-event`,
  GET_EVENT: `${ADMIN}/get-event`,
  DELETE_EVENT: `${ADMIN}/delete-event`,
  ISACTIVE_EVENT: `${ADMIN}/action-event`,
  GET_EVENT_ACTIVITY_LOG: `${ADMIN}/`,

  ADD_EDIT_AD_ZONEMASTER: `${ADMIN}/addEditZoneMasterAd`,
  GET_AD_ZONEMASTER: `${ADMIN}/getAllZoneMasterAd`,
  DELETE_AD_ZONEMASTER: `${ADMIN}/deleteZoneMasterAd`,
  ISACTIVE_AD_ZONE: `${ADMIN}/zone-active-deActive-ad`,
  GET_AD_ZONE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-zone-ad`,

  GET_ADVERTISER_PREREQUISITE_DATA: `${ADMIN}/add-advertiser-prerequisite-data`,
  ADD_ADVERTISER: `${ADMIN}/add-advertiser`,
  GET_ADVERTISER: `${ADMIN}/get-all-advertisor`,
  GET_ALL_ADVERTISER: `${ADMIN}/get-all-advertiser`,
  DELETE_ADVERTISER: `${ADMIN}/delete-advertiser`,
  ISACTIVE_ADVERTISER: `${ADMIN}/action-advertiser`,
  GET_ADVERTISING: `${ADMIN}/getAllAdvertising`,

  GET_ADVERTISEMENTS: `${ADMIN}/get-all-ads`,
  ADD_ADVERTISEMENT: `${ADMIN}/add-edit-ads`,
  DELETE_ADVERTISEMENT: `${ADMIN}/delete-ads`,
  ISACTIVE_ADVERTISEMENT: `${ADMIN}/action-ads`,
  ADVERTISERWISE_AD_LIST: `${ADMIN}/advwise-ads-list`,
  DELETE_ADVERTISER_LEAD: `${ADMIN}/delete-advertising`,
  GET_AD_DETAILS: `${ADMIN}/get-ads-details`,
  ARCHIVED_ADVERTISING_LIST: `${ADMIN}/archived-list`,
  GET_CLIENTS_DETAILS: `${ADMIN}/adv-details`,
  ADD_BILLING_INFORMATION: `${ADMIN}/add-billing`,

  SEND_EVENT_MAIL: `${ADMIN}/eventMail`,
  GET_SLOT_LIST: `${ADMIN}/slot-list`,

  GET_HOME_CATEGORY: `${ADMIN}/get-all-section`,
  ADD_EDIT_HOME_CATEGORY: `${ADMIN}/add-edit-section`,
  DELETE_HOME_CATEGORY: `${ADMIN}/delete-section`,
  ISACTIVE_HOME_CATEGORY: `${ADMIN}/section-active-deActive`,
  GET_HOME_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-section`,
  UPDATE_ROW_HOME_CATEGORY: `${ADMIN}/update-section-row`,

  GET_SPONSOR: `${ADMIN}/get-sponsor`,
  ADD_EDIT_SPONSOR: `${ADMIN}/add-edit-sponsor`,
  DELETE_SPONSOR: `${ADMIN}/delete-sponsor`,
  ACTION_SPONSOR: `${ADMIN}/action-sponsor`,
  GET_ACTIVITY_LOG: `${ADMIN}/get-activity-log-sponsor`,
  GET_VIEW_DETAILS: `${ADMIN}/get-sponsor`,

  // Not a CRUD wise
  AGE_LIST: `${ADMIN}/age-list`,
  UPLOAD_S3_LINK: `${ADMIN}/upload-s3-link`,
  RESPONSE_USER_QUERY: `${ADMIN}/response-user-query`,

  GET_DASHBOARD_COUNT: `${ADMIN}/get-total-count`,
  GET_NEWS_LIST_GRAPH_DATA: `${ADMIN}/ads-play-user-news-list`,
  GET_DESHBOARD_NEWS_GRAPH_DATA: `${ADMIN}/get-news-graph-data`,
  GET_DESHBOARD_CONTANT_GRAPH: `${ADMIN}/get-content-graph`,
  GET_DESHBOARD_HOME_SECTION_GRAPH: `${ADMIN}/get-home-section-graph`,
  GET_DESHBOARD_NEWS_RATING_GRAPH: `${ADMIN}/get-news-rating-graph`,
  GET_DESHBOARD_DISTRICT_WISE_NEWS_GRAPH: `${ADMIN}/get-district-wise-news-graph`,

  GET_POST_VIDEO_LIST: `${ADMIN}/get-post-video-list`,
  GET_ALL_POST_VIDEO_LIST: `${ADMIN}/get-all-post-video-list`,
  USER_ACCEPT_REJECT_VIDEO: `${ADMIN}/user-accept-video`,
  USER_DOWNLOAD_VIDEO: `${ADMIN}/post-video-download`,
  USER_VIDEO_ACTIVITY_LOG: `${ADMIN}/get-activity-log-post`,

  GET_CHANNELS: `${ADMIN}/get-all-channel`,
  ADD_EDIT_CHANNEL: `${ADMIN}/add-edit-channel`,
  DELETE_CHANNEL: `${ADMIN}/delete-channel`,
  ACTIVE_DEACTIVE_CHANNEL: `${ADMIN}/channel-active-deactive`,
  CHANNEL_ACTIVITY_LOG: `${ADMIN}/get-activity-log-channel`,

  AD_REPORT: `${ADMIN}/ads-report`,

  GET_PROGRAM_TYPE: `${ADMIN}/get-all-program-type`,
  ADD_EDIT_PROGRAM_TYPE: `${ADMIN}/add-edit-program-type`,
  DELETE_PROGRAM_TYPE: `${ADMIN}/delete-program-type`,
  ACTIVE_DEACTIVE_PROGRAM_TYPE: `${ADMIN}/program-type-action`,
  PROGRAM_TYPE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-program-type`,

  GET_PROGRAM: `${ADMIN}/get-all-program`,
  ADD_EDIT_PROGRAM: `${ADMIN}/add-edit-program`,
  DELETE_PROGRAM: `${ADMIN}/delete-program`,
  ACTIVE_DEACTIVE_PROGRAM: `${ADMIN}/program-action`,
  PROGRAM_ACTIVITY_LOG: `${ADMIN}/get-activity-log-program`,

  GET_DOCUMENT_TYPES: `${ADMIN}/document-list`,
  UPLOAD_CLIENT_DOCUMENT: `${ADMIN}/upload-documents`,
  DELETE_DOCUMENT: `${ADMIN}/delete-document`,

  GET_ELEMENTS: `${ADMIN}/get-all-element`,
  ADD_EDIT_ELEMENT: `${ADMIN}/add-edit-element`,
  DELETE_ELEMENT: `${ADMIN}/delete-element`,
  ACTIVE_DEACTIVE_ELEMENT: `${ADMIN}/element-action`,
  ELEMENT_ACTIVITY_LOG: `${ADMIN}/get-activity-log-element`,

  GET_CREATIVES_BY_CLIENT: `${ADMIN}/client-creative`,
  GET_CREATIVES: `${ADMIN}/get-all-creative`,
  ADD_EDIT_CREATIVE: `${ADMIN}/add-edit-creative`,

  GET_SLOTS: `${ADMIN}/get-all-slot`,
  ADD_SLOT: `${ADMIN}/add-edit-slot`,

  GET_SLOTS_BY_TYPE: `${ADMIN}/get-slot-by-type`,
  GET_SCHEDULES: `${ADMIN}/get-all-schedule`,
  ADD_EDIT_SCHEDULE: `${ADMIN}/add-edit-schedule`,
  DELETE_SCHEDULE: `${ADMIN}/delete-schedule`,
  VIEW_SLOT_SCHEDULE: `${ADMIN}/view-schedule`,
  ISACTIVE_SCHEDULE: `${ADMIN}/action-schedule`,

  ADD_EDIT_SLOT_SCHEDULE: `${ADMIN}/slot-schedule`,
  GET_SLOT_SCHEDULE: `${ADMIN}/get-schedule`,
  DELETE_SLOT_SCHEDULE: `${ADMIN}/delete-schedule-slot`,

  ADD_EDIT_SALES_ORDER: `${ADMIN}/add-edit-sales-order`,
  GET_ALL_SALES_ORDERS: `${ADMIN}/get-all-sales-order`,
  DELETE_SALES_ORDER: `${ADMIN}/delete-sales-order`,
  GET_SALES_STATUS: `${ADMIN}/action-status`,

  GET_ALL_CLIENT_CATEGORY: `${ADMIN}/get-category`,
  GET_CLIENT_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-clients-category`,
  DELETE_CLIENT_CATEGORY: `${ADMIN}/delete-clients-category`,
  ADD_EDIT_CLIENT_CATEGORY: `${ADMIN}/add-edit-category`,
  ISACTIVE_CLIENT_CATEGORY: `${ADMIN}/client-category-active-deActive`,

  GET_ALL_CLIENT_SUB_CATEGORY: `${ADMIN}/get-all-subcategory`,
  GET_CLIENT_SUB_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-clients-sub-category`,
  DELETE_CLIENT_SUB_CATEGORY: `${ADMIN}/delete-clients-sub-category`,
  ADD_EDIT_CLIENT_SUB_CATEGORY: `${ADMIN}/add-edit-subcategory`,
  ISACTIVE_CLIENT_SUB_CATEGORY: `${ADMIN}/client-sub-category-active-deActive`,

  GET_ALL_MEDIATYPE: `${ADMIN}/get-media-platform`,
  GET_MEDIATYPE_ACTIVITY_LOG: `${ADMIN}/get-activity-log-media-platform`,
  DELETE_MEDIATYPE: `${ADMIN}/delete-media-platform`,
  ADD_EDIT_MEDIATYPE: `${ADMIN}/add-edit-media-platform`,
  ISACTIVE_MEDIATYPE: `${ADMIN}/media-platform-active-deActive`,

  GET_ALL_VOTERS_LIST: `${ADMIN}/export-vote`,

  GET_WEBSITE_SCHEDULE_LIST: `${ADMIN}/get-other-schedule`,
  ADD_EDIT_WEBSITE_SCHEDULE_LIST: `${ADMIN}/add-edit-other-schedule`,

  ADD_BOOKING: `${ADMIN}/add-sub-order`,
  GET_BOOKING: `${ADMIN}/get-sub-sales-order`,

  GET_UPLOADED_CONTENT_LIST: `${ADMIN}/get-admin-wise-uploaded-news`,
  GET_UPLOADED_VIDEO_CONTENT_LIST: `${ADMIN}/get-admin-wise-uploaded-video`,

  NOTIFICATION_LIST: `${ADMIN}/notification-list`,
  NOTIFICATION_READ: `${ADMIN}/notification-read`,
  GET_PENDINGVIDEO_COUNT: `${ADMIN}/get-post-video-count`,

  ADD_AUTO_SCHEDULE_ENTRY: `${ADMIN}/auto-entry-schedule`,

  GET_ALL_CLIENT_AGENCY: `${ADMIN}/get-agency`,
  GET_CLIENT_AGENCY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-clients-agency`,
  DELETE_CLIENT_AGENCY: `${ADMIN}/delete-clients-agency`,
  ADD_EDIT_CLIENT_AGENCY: `${ADMIN}/add-edit-agency`,
  ISACTIVE_CLIENT_AGENCY: `${ADMIN}/client-agency-active-deActive`,

  GET_UPLOADED_VIDEOS: `${ADMIN}/get-all-upload-video`,
  GET_UPLOADED_VIDEOS_ARCHIVED: `${ADMIN}/get-all-archive-video`,
  DELETE_UPLOADED_VIDEOS: `${ADMIN}/delete-video`,
  HARD_DELETE_UPLOADED_VIDEOS: `${ADMIN}/hard-delete-video`,
  RESTORE_ARCHIVED_VIDEOS: `${ADMIN}/restore-video`,

  GET_ALL_SERVER: `${ADMIN}/get-internal`,
  DELETE_SERVER: `${ADMIN}/delete-internal`,
  ADD_EDIT_SERVER: `${ADMIN}/add-edit-internal`,
  ISACTIVE_SERVER: `${ADMIN}/action-internal`,




  // new
 GET_ALL_MOVIE: `${ADMIN}/get-movies-web-show`,
  SLIDER_IMAGE_ADD: `${ADMIN}/addEditSlider`,
  SLIDER_IMAGE_GET_ALL: `${ADMIN}/get-all-slider`,
  SLIDER_IMAGE_DELETE: `${ADMIN}/delete-slider`,
  SLIDER_IMAGE_ACTIVE_DEACTIVE: `${ADMIN}/slider-active-deActive`,
  
  // music slider
  // GET_ALL_SONG: `${ADMIN}/get-movies-web-show`,
   SLIDER_SONG_IMAGE_ADD: `${ADMIN}/addEditMusicSlider`,
   SLIDER_SONG_IMAGE_GET_ALL: `${ADMIN}/get-all-music-slider`,
   SLIDER_SONG_IMAGE_DELETE: `${ADMIN}/delete-music-slider`,
   SLIDER_SONG_IMAGE_ACTIVE_DEACTIVE: `${ADMIN}/music-slider-active-deActive`,

  // music category
  GET_ALL_SONG_CATEGORY: `${ADMIN}/get-all-music-category`,
  GET_SONG_ACTIVITY_LOG: `${ADMIN}/get-activity-log-movie`,
  DELETE_SONG_CATEGORY: `${ADMIN}/delete-music-category`,
  ADD_EDIT_SONG_CATEGORY: `${ADMIN}/add-edit-music-category`,
  ISACTIVE_SONG_CATEGORY: `${ADMIN}/action-music-category`,
  GET_SONG_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-movie-category`,

  // music cast
  
  ADD_EDIT_ACTOR_SONG: `${ADMIN}/add-edit-music-cast`,
  GET_ALL_ACTOR_SONG: `${ADMIN}/get-music-cast`,
  DELETE_ACTOR_SONG: `${ADMIN}/delete-cast`,

  // music add -edit
  ADD_EDIT_SONG: `${ADMIN}/add-edit-song`,
  GET_ALL_SONG: `${ADMIN}/get-all-song`,
  DELETE_SONG: `${ADMIN}/delete-song`,
  SONG_STATUS: `${ADMIN}/active-deactive-song`,
  VIEW_SONG_DATA: `${ADMIN}/get-single-song`,

  // home category movies 

GET_ALL_IN_DROPDOWN_MOVIES:`${ADMIN}/get-all-movie-show`,
  GET_HOME_MOVIES: `${ADMIN}/get-all-homesection-moviewebshow`,
  DELETE_HOME_MOVIES: `${ADMIN}/delete-movie-show-homesection`,
  ISACTIVE_HOME_MOVIES: `${ADMIN}/active-deactive-movie-show-homesection`,
  // GET_HOME_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-section`,
  UPDATE_MOVIE_ROW: `${ADMIN}/upadete-movie-web-sequance-row`,
  ADD_EDIT_HOME_MOVIES: `${ADMIN}/add-movie-show-homesection`,
  
  // music home category movies 
  
  ADD_EDIT_HOME_SONGS: `${ADMIN}/add-song-show-homesection`,
GET_ALL_IN_DROPDOWN_SONGS:`${ADMIN}/get-all-homesection-song`,
  GET_HOME_SONGS: `${ADMIN}/get-all-homesection-added-song`,
  ADD_EDIT_HOME_SONG_SECTION: `${ADMIN}/add-song-show-homesection`,
  DELETE_HOME_SONG: `${ADMIN}/delete-song-homesection`,
  ISACTIVE_HOME_SONG: `${ADMIN}/active-deactive-song-homesection`,
  // GET_HOME_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-section`,
  UPDATE_SONG_ROW_HOME_CATEGORY:`${ADMIN}/update-music-section-row`,
  
  GET_HOME_SONG_CATEGORY: `${ADMIN}/get-all-song-section`,
  ADD_EDIT_SONG_HOME_CATEGORY: `${ADMIN}/add-edit-song-section`,
  DELETE_SONG_HOME_CATEGORY: `${ADMIN}/delete-song-section`,
  ISACTIVE_SONG_HOME_CATEGORY: `${ADMIN}/song-section-active-deActive`,
  GET_SONG_HOME_CATEGORY_ACTIVITY_LOG: `${ADMIN}/get-activity-log-section`,
  UPDATE_SONG_ROW: `${ADMIN}/upadete-song-sequance-row`,


  // album playlist
  GET_ALBUM_PLAYLIST:`${ADMIN}/get-all-play-list-album`,
  ACTIVE_DEACTIVE_ALBUM_PLAYLIST:`${ADMIN}/active-deactive-play-list-album`,
  ADD_EDIT_ALBUM_PLAYLIST:`${ADMIN}/add-edit-play-list-album`,
  GET_SINGER_LIST:`${ADMIN}/get-all-singer`,
  DELET_ALBUM_PLAYLIST:`${ADMIN}/delete-play-list-album`,
DROPDOWN_SONG_LIST:`${ADMIN}/get-all-song-by-playlist-album`,
ADD_SONG_IN_PLAYLIST_ALBUM:`${ADMIN}/add-song-play-list-album`,
ACTIVE_DEACTIVE_SONG_IN_PLAYLIST_ALBUM:`${ADMIN}/song-active-deactive-play-list-album`,
DELETE_SONG_IN_PLAYLIST_ALBUM:`${ADMIN}/delete-song-play-list-album`,
GET_ALL_SONG_ALBUM_PLAYLIST:`${ADMIN}/get-all-song-play-list-album-id`,

};
export { Api };


