import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";
import "../auth.responsive.css";

export default function ForgotPassWord() {
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  // for page redirect
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();

  // for password eye hide and show

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const changePassword = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", location?.state?.email);
    urlencoded.append("newPassword", values?.newPassword);
    PagesIndex.apiPostHandler(PagesIndex.Api.RESET_PASSWORD, urlencoded).then(
      (res) => {
        if (res.status === 200) {
          navigate("/", { replace: true });
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  return (
    <div>
      <Index.Box className="admin-auth-main-flex">
        <Index.Box className="admin-auth-left-main">
          <PagesIndex.AuthBackground />
        </Index.Box>
        <Index.Box className="admin-auth-right-main">
          <Index.Box className="admin-auth-box">
            <Index.Box className="admin-auth-main">
              <Index.Link className="admin-auth-logo-box">
                <img
              src={PagesIndex.Png.adminlogo}
                  className="admin-sidebar-logo"
                  alt="logo"
                />
              </Index.Link>
              <PagesIndex.Formik
                enableReinitialize
                onSubmit={changePassword}
                initialValues={initialValues}
                validationSchema={PagesIndex.forgotPasswordValidationSchema}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <Index.Box
                    component={"form"}
                    noValidate="novalidate"
                    onSubmit={handleSubmit}
                    className="admin-auth-inner-main admin-forgot-pass-inner-main"
                  >
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Change Password!
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-sign-para admin-common-para"
                    >
                      Please enter your password to change password!
                    </Index.Typography>
                    <Index.Box className="admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        New Password
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.OutlinedInput
                          name="newPassword"
                          className="admin-form-control-eye admin-form-control"
                          autoComplete="off"
                          placeholder="Enter New Password"
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Index.Visibility />
                                ) : (
                                  <Index.VisibilityOff />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          }
                          onChange={handleChange}
                          value={values.newPassword}
                          error={errors?.newPassword && touched?.newPassword}
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {errors?.newPassword && touched?.newPassword
                            ? errors?.newPassword
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Confirm Password
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.OutlinedInput
                          name="confirmPassword"
                          className="admin-form-control-eye admin-form-control"
                          autoComplete="off"
                          id="outlined-adornment-password"
                          placeholder="Enter Confirm Password"
                          type={showConfirmPassword ? "text" : "password"}
                          endAdornment={
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Index.Visibility />
                                ) : (
                                  <Index.VisibilityOff />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          }
                          onChange={handleChange}
                          value={values.confirmPassword}
                          error={
                            errors?.confirmPassword && touched?.confirmPassword
                          }
                        />
                        <Index.FormHelperText className="admin-error-text">
                          {errors?.confirmPassword && touched?.confirmPassword
                            ? errors?.confirmPassword
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-flex-all admin-forgot-row">
                      <Index.Link
                        className="admin-forgot-para admin-common-para"
                        to="/"
                      >
                        Back to Login?
                      </Index.Link>
                    </Index.Box>
                    <Index.Box className="btn-main-primary admin-login-btn-main">
                      <Index.Button
                        className="btn-primary admin-login-btn"
                        type="submit"
                      >
                        Submit
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
