import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import VideoThumbnail from "react-video-thumbnail";
export default function AddMovie() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };



  const formRef = useRef();
  const { id } = PagesIndex.useParams();
  const navigate = PagesIndex.useNavigate();
  const { state } = useLocation();
  const videoRef = useRef();
  const [castData, setCastData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [openCalender, setOpenCalender] = useState(false);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [message, setMessage] = useState("Copy");
  const [uploadVideoId, setUploadVideoId] = useState(undefined);
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [uploadData, setUploadData] = useState('')

  const initialValues = {
    title: state?.data?.title || "",
    releaseDate: dayjs(state?.data?.releaseDate) || null,
    duration: state?.data?.duration || "",

    censorRating: state?.data?.censorRating || "",
    language: state?.data?.language || "",
    movieType: state?.data?.movieType || "",
    tags: state?.data?.tags?.map((data) => data?._id) || "",
    genreCategory:
      state?.data?.categoryId?.map((data) => {
        return data?._id;
      }) || [],
    genreName:
      state?.data?.categoryId?.map((data) => {
        return data?.name;
      }) || [],
    cast:
      state?.data?.cast?.map((data) => {
        return { castId: data?.castId?._id };
      }) || [],

    description: state?.data?.description || "",

    movieLink: state?.data?.link || '',
    movieTrailerLink: state?.data?.trailerLink || "",

    image:"",
    imageUrl: state?.data?.image || "",
  };
  const renderVideo = (values) => {
    if (values.movieLink && uploadData=='movie') {
      return (
        <video
          ref={videoRef}
          className="upload-img"
          loop
          autoPlay
          muted
        >
          <source src={values.movieLink} type="video/mp4" />
        </video>
      );
    } else if (values.movieTrailerLink && uploadData=='trailer') {
      return (
        <video
          ref={videoRef}
          className="upload-img"
          loop
          autoPlay
          muted
        >
          <source src={values.movieTrailerLink} type="video/mp4" />
        </video>
      );
    } else {
      return (
        <img
          src={PagesIndex.Svg.fileupload}
          className="upload-profile-img"
          alt="upload img"
        />
      );
    }
  };
  
  const handleOpenUpload = (data) => {
    setOpenUpload(true)
    setUploadData(data)
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleUploadVideo = (e, setFieldValue, setFieldTouched) => {
    setUploadedVideo("");
    const formdata = new FormData();
    formdata.append("video", e.target.files[0]);
    PagesIndex.apiPostHandlerVideoUpload(
      PagesIndex.Api.UPLOAD_S3_LINK,
      formdata,
      {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      }
    ).then((res) => {
      setUploadProgress(0);
      if (res?.status === 201 || res?.status === 200) {
        PagesIndex.toasterSuccess(res.message);
        videoRef.current?.load();
        setUploadedVideo(`${process.env.REACT_APP_IMAGE_ENDPOINT}${res.data}`);
        if (uploadData=='movie') {
          setFieldTouched("movieLink", false);
          setFieldValue("movieLink", `${process.env.REACT_APP_IMAGE_ENDPOINT}${res.data}`);
        } else {
          setFieldTouched("movieTrailerLink", false);

          setFieldValue("movieTrailerLink", `${process.env.REACT_APP_IMAGE_ENDPOINT}${res.data}`);
        }


        setOpenUpload(false);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const VideoThumb = useCallback(() => {
    return (
      <VideoThumbnail
        videoUrl={uploadedVideo}
        thumbnailHandler={(thumbnail) => {
          formRef.current.setFieldValue("imageUrl", thumbnail);
        }}
        renderThumbnail={false}
      />
    );
  }, [uploadedVideo]);



  useEffect(() => {
    handleGetActors();
    handleGetCategories();
    handleGetTag();
  }, []);
  const handleGetTag = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_MOVIE_TAG).then((res) => {
      if (res.status === 200) {
        const activeTags = res?.data?.filter((tag) => tag?.isActive);
        setTagData(activeTags);
      }
    });
  };
  const handleGetActors = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ACTOR).then((res) => {
      if (res?.status === 200) {
        setCastData(res?.data?.filter((data) => data?.isActive));
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetCategories = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_MOVIE_CATEGORY).then(
      (res) => {
        if (res?.status === 200) {
          setCategoryData(res?.data?.filter((data) => data?.isActive));
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleAddEditMovie = (values) => {
    setButtonSpinner(true)
    const formdata = new FormData();
    if (state?.data?._id) {
      formdata.append("id", state.data._id);
    }
    formdata.append("title", values?.title);
    formdata.append(
      "releaseDate",
      PagesIndex.moment(new Date(values?.releaseDate)).format("YYYY-MM-DD")
    );
    formdata.append("censorRating", values?.censorRating);
    formdata.append("language", values?.language);
    formdata.append("movieType", values?.movieType);
    formdata.append("description", values?.description);
    formdata.append("categoryId", JSON.stringify(values?.genreCategory));
    formdata.append("categoryName", values?.genreName);
    formdata.append("duration", values?.duration);
    formdata.append("link", values?.movieLink);
    formdata.append("trailerLink", values?.movieTrailerLink);
    formdata.append("cast", JSON.stringify(values?.cast));
    formdata.append("type", "movie");
    if (values?.image) {
      formdata.append("profile", values?.image);
    }
    if (values?.tags?.length) {
      values?.tags?.forEach((tag, index) => {
        formdata.append(`tags[${index}]`, tag);
      });
    } else {
      formdata.append(`tags`, []);
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_MOVIES, formdata)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          navigate("/admin/movies");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  // for img
  const renderImage = (values) => {
    const isUploadImg = values?.imageUrl || values.image;
    const isImageBlob = values.image instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        className={isUploadImg ? "upload-img" : "upload-profile-img"}
        src={src}
        alt="movie"
      />
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {state?.data?._id ? "Edit" : "Add"} Movie
        </Index.Typography>
      </Index.Box>

      <Index.Box className="common-card">
        <Index.Box className="grid-row">
          <Index.Box className="grid-main">
            <PagesIndex.Formik
              // enableReinitialize
              onSubmit={handleAddEditMovie}
              initialValues={initialValues}
              validationSchema={
                state?.data?._id
                  ? PagesIndex.movieEditSchema
                  : PagesIndex.movieSchema
              }
              innerRef={formRef}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched
              }) => (
                <form onSubmit={handleSubmit} noValidate="novalidate">
                  {/* <ScrollIntoView /> */}
                  {console.log(values)
                  }
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 8",
                        lg: "span 8",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Movie Name<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="title"
                            className="admin-form-control"
                            placeholder="Enter Movie Name"
                            onChange={handleChange}
                            name="title"
                            value={values?.title}
                            error={errors.title && touched.title}
                            inputProps={{ maxLength: 40 }}
                          />
                          {touched.title && errors.title && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.title}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                        <Index.FormHelperText className="admin-form-lable">
                          <span>Release Date</span>
                          <span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box
                          className="admin-form-group"
                          id="releaseDate"
                        >
                          <PagesIndex.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <PagesIndex.DatePicker
                              open={openCalender}
                              onClose={() => setOpenCalender(false)}
                              className="admin-form-control admin-date-picker-control"
                              value={values.releaseDate} // Use '01/01/2000' as the default value
                              // minDate={dayjs().add(0, "day")}
                              onChange={(date) => {
                                setFieldValue("releaseDate", date);
                              }}
                              slotProps={{
                                popper: {
                                  className: "date-picker-body",
                                },
                                textField: {
                                  readOnly: true,
                                  onClick: () => setOpenCalender(true),
                                },
                              }}
                              format="DD/MM/YYYY"
                            />
                          </PagesIndex.LocalizationProvider>
                          <Index.FormHelperText className="admin-error-text">
                            {errors.releaseDate &&
                              touched.releaseDate &&
                              errors.releaseDate}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Duration<span className="astrick-sing">*</span> (In
                          Minutes)
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="duration"
                            className="admin-form-control"
                            placeholder="Enter Duration"
                            name="duration"
                            onChange={handleChange}
                            value={values?.duration}
                            error={errors.duration && touched.duration}
                            inputProps={{ maxLength: 3 }}
                          />
                          {touched.duration && errors.duration && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.duration}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Censor Rating
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="censorRating"
                            className="admin-form-control"
                            placeholder="Enter Censor Rating"
                            name="censorRating"
                            onChange={handleChange}
                            value={values?.censorRating}
                            error={errors.censorRating && touched.censorRating}
                          />
                          {touched.censorRating && errors.censorRating && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.censorRating}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Language<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="language"
                            className="admin-form-control"
                            placeholder="Enter Language"
                            name="language"
                            onChange={handleChange}
                            value={values?.language}
                            error={errors.language && touched.language}
                            inputProps={{ maxLength: 10 }}
                          />
                          {touched.language && errors.language && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.language}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 4",
                        md: "span 3",
                        lg: "span 3",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Type<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-dropdown-box">
                          <Index.FormControl className="admin-form-control admin-drop-form-control">
                            <Index.Select
                              className="admin-dropdown-select admin-drop-select"
                              id="movieType"
                              name="movieType"
                              value={values?.movieType}
                              onChange={handleChange}
                              defaultValue={values?.movieType}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              renderValue={
                                values?.movieType
                                  ? undefined
                                  : () => "Select Type"
                              }
                            >
                              <Index.MenuItem
                                value="2D"
                                className="admin-drop-menuitem"
                              >
                                2D
                              </Index.MenuItem>
                              <Index.MenuItem
                                value="3D"
                                className="admin-drop-menuitem"
                              >
                                3D
                              </Index.MenuItem>
                            </Index.Select>
                          </Index.FormControl>
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.movieType && touched.movieType
                              ? errors.movieType
                              : null}
                          </PagesIndex.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box input-complete-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Tags<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              className="admin-auto-complete-filed"
                              options={tagData || []}
                              id="tags"
                              multiple
                              disableCloseOnSelect
                              freeSolo
                              closeText={""}
                              openText={""}
                              clearText={""}
                              getOptionSelected={(option, value) =>
                                option._id === value._id
                              }
                              value={
                                values?.tags
                                  ? tagData.filter((tag) =>
                                    values?.tags?.includes(tag._id)
                                  )
                                  : []
                              }
                              onChange={(e, values) => {
                                if (!e.key) {
                                  setFieldValue(
                                    "tags",
                                    values.map((tag) => tag._id)
                                  );
                                }
                              }}
                              getOptionLabel={(option) => option?.tag}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.tag}
                                </li>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  name="tags"
                                  className="admin-form-control"
                                  {...params}
                                  size="small"
                                  placeholder={
                                    values?.tags?.length ? "" : "Enter Tag"
                                  }
                                  variant="outlined"
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.preventDefault(); // Prevent form submission
                                      const inputValue =
                                        event.target.value.trim();
                                      if (
                                        inputValue &&
                                        !values?.tags?.includes(inputValue)
                                      ) {
                                        setFieldValue("tags", [
                                          ...(values?.tags ? values.tags : []),
                                          inputValue,
                                        ]);
                                      }
                                      if (
                                        !tagData.find(
                                          (tag) => tag._id === inputValue
                                        )
                                      ) {
                                        setTagData((prev) => [
                                          ...prev,
                                          {
                                            _id: inputValue,
                                            tag: inputValue,
                                          },
                                        ]);
                                      }
                                      event.target.value = "";
                                    }
                                  }}
                                />
                              )}
                            />
                          </Index.FormControl>
                          {touched.tags && errors.tags && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.tags}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box input-complete-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Categories<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              className="admin-auto-complete-filed"
                              options={categoryData || []}
                              id="genreCategory"
                              multiple
                              disableCloseOnSelect
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={
                                values?.genreCategory
                                  ? categoryData.filter((data) =>
                                    values?.genreCategory?.some(
                                      (cat) => cat === data?._id
                                    )
                                  )
                                  : []
                              }
                              onChange={(e, selectedOptions) => {
                                setFieldValue(
                                  "genreCategory",
                                  selectedOptions.map((option) => option._id)
                                );
                                setFieldValue(
                                  "genreName",
                                  selectedOptions
                                    .map((option) => option.name)
                                    .toString()
                                );
                              }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.name}
                                </li>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  name="genreCategory"
                                  fullWidth
                                  className="admin-form-control"
                                  placeholder={
                                    values?.genreCategory?.length
                                      ? ""
                                      : "Select Category"
                                  }
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                            />

                            {touched.genreCategory && errors.genreCategory && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.genreCategory}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box input-complete-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Cast<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.FormControl className="admin-form-control admin-auto-complete-control">
                            <Index.Autocomplete
                              className="admin-auto-complete-filed"
                              options={castData}
                              id="cast"
                              multiple
                              disableCloseOnSelect
                              closeText={""}
                              openText={""}
                              clearText={""}
                              value={
                                values?.cast
                                  ? castData.filter((data) =>
                                    values?.cast?.some(
                                      (cat) => cat?.castId === data?._id
                                    )
                                  )
                                  : []
                              }
                              onChange={(e, selectedOptions) => {
                                setFieldValue(
                                  "cast",
                                  selectedOptions.map((option) => ({
                                    castId: option._id,
                                  }))
                                );
                              }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, item) => (
                                <li {...props} key={item?._id}>
                                  {item?.name}
                                </li>
                              )}
                              renderInput={(params) => (
                                <Index.TextField
                                  fullWidth
                                  className="admin-form-control"
                                  name="cast"
                                  placeholder={
                                    values?.cast?.length ? "" : "Select Cast"
                                  }
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                            />

                            {touched.cast && errors.cast && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.cast}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Description<span className="astrick-sing">*</span>{" "}
                          <span className="option-condition-text">
                            (Max character length: 250)
                          </span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextareaAutosize
                            // aria-label="minimum height"
                            // minRows={3}
                            style={{ resize: "vertical", overflow: "auto" }}
                            placeholder="Enter Description"
                            className="admin-form-control-textarea"
                            id="description"
                            name="description"
                            onChange={handleChange}
                            value={values?.description}
                            error={errors.description && touched.description}
                            maxLength={250}
                          />
                          {touched.description && errors.description && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.description}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Movie Link<span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.TextField
                            fullWidth
                            id="movieLink"
                            className="admin-form-control"
                            placeholder="Enter Movie Link"
                            name="movieLink"
                            onChange={handleChange}
                            value={values?.movieLink}
                            error={errors.movieLink && touched.movieLink}
                          />
                          {touched.movieLink && errors.movieLink && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.movieLink}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                     Upload Movie Link<span className="astrick-sing">*</span>{" "}
                          {/* <span className="admin-span-text">(Upload)</span> */}
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.OutlinedInput
                            fullWidth
                            id="video"
                            className="admin-form-control admin-form-control-eye"
                            placeholder="Upload Movie Link"
                            name="movieLink"
                            disabled
                            value={
                              values?.movieLink

                            }
                            endAdornment={
                              values?.movieLink ? (
                                <Index.InputAdornment position="end">
                                  <Index.Tooltip
                                    title={message}
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                    onMouseLeave={() => {
                                      setTimeout(() => {
                                        setMessage("Copy");
                                      }, 1000);
                                    }}
                                  >
                                    <Index.Button
                                      className="copy-btn-main"
                                      onClick={() => {
                                        setMessage("Copied ✓");
                                        navigator.clipboard.writeText(
                                          values?.movieLink
                                        );
                                      }}
                                    >
                                      <img
                                        src={PagesIndex.Svg.copyicon}
                                        className="copy-icon"
                                        alt="copy"
                                      />
                                    </Index.Button>
                                  </Index.Tooltip>
                                  <Index.Tooltip
                                    title="Delete"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                  >
                                    <Index.Button
                                      className="copy-btn-main"
                                      onClick={() => {
                                        setFieldValue("movieLink", "");
                                        setUploadVideoId(undefined);
                                        if (!id) {
                                          setFieldValue("imageUrl", "");
                                        }
                                      }}
                                    >
                                      <img
                                        src={PagesIndex.Svg.closeBold}
                                        className="admin-icon"
                                        alt="copy"
                                      />
                                    </Index.Button>
                                  </Index.Tooltip>
                                </Index.InputAdornment>
                              ) : (
                                ""
                              )
                            }
                          />
                          {touched.movieLink && errors.movieLink && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.movieLink}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 3",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box">
                        <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                        <Index.Box className="btn-main-secondary">
                          <Index.Button
                            className="btn-secondary"
                            onClick={() => handleOpenUpload('movie')}
                          >
                            Upload Movie
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
             
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                    Movie Trailer Link<span className="astrick-sing">*</span>{" "}
                          {/* <span className="admin-span-text">(Upload)</span> */}
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <Index.OutlinedInput
                            fullWidth
                            id="video"
                            className="admin-form-control admin-form-control-eye"
                            placeholder="Upload Movie Trailer Link"
                            name="movieTrailerLink"
                            disabled
                            value={
                              values?.movieTrailerLink

                            }
                            endAdornment={
                              values?.movieTrailerLink ? (
                                <Index.InputAdornment position="end">
                                  <Index.Tooltip
                                    title={message}
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                    onMouseLeave={() => {
                                      setTimeout(() => {
                                        setMessage("Copy");
                                      }, 1000);
                                    }}
                                  >
                                    <Index.Button
                                      className="copy-btn-main"
                                      onClick={() => {
                                        setMessage("Copied ✓");
                                        navigator.clipboard.writeText(
                                          values?.movieLink
                                        );
                                      }}
                                    >
                                      <img
                                        src={PagesIndex.Svg.copyicon}
                                        className="copy-icon"
                                        alt="copy"
                                      />
                                    </Index.Button>
                                  </Index.Tooltip>
                                  <Index.Tooltip
                                    title="Delete"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                  >
                                    <Index.Button
                                      className="copy-btn-main"
                                      onClick={() => {
                                        setFieldValue("movieTrailerLink", "");
                                        setUploadVideoId(undefined);
                                        if (!id) {
                                          setFieldValue("imageUrl", "");
                                        }
                                      }}
                                    >
                                      <img
                                        src={PagesIndex.Svg.closeBold}
                                        className="admin-icon"
                                        alt="copy"
                                      />
                                    </Index.Button>
                                  </Index.Tooltip>
                                </Index.InputAdornment>
                              ) : (
                                ""
                              )
                            }
                          />
                          {touched.movieTrailerLink && errors.movieTrailerLink && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.movieTrailerLink}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>



                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 3",
                        md: "span 2",
                        lg: "span 2",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-news-input-box">
                        <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                        <Index.Box className="btn-main-secondary">
                          <Index.Button
                            className="btn-secondary"
                            onClick={()=>handleOpenUpload('trailer')}
                          >
                            Upload Trailer
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-add-movie-input-box">
                        <Index.FormHelperText className="admin-form-lable">
                          Thumbnail<span className="astrick-sing">*</span>
                          <span className="option-condition-text">
                          (Image must be 1280px(Width) * 720px(Height))
                          </span>
                        </Index.FormHelperText>
                        <Index.Box className="file-upload-main thumbnail-box">
                          <Index.Button
                            variant="contained"
                            component="label"
                            className="file-upload-button"
                          >
                            {renderImage(values)}
                            <input
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                              id="image"
                              name="image"
                              onChange={(e) => {
                                if (e.target.files?.length !== 0) {
                                  setFieldValue("image", e.target.files[0]);
                                }
                              }}
                            />
                          </Index.Button>
                          {touched.image && errors.image && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.image}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-modal-user-btn-flex">
                        <Index.Box className="border-btn-main">
                          <Index.Button
                            className="border-btn"
                            onClick={() => {
                              navigate("/admin/movies");
                            }}
                            disabled={buttonSpinner}
                          >
                            Discard
                          </Index.Button>
                        </Index.Box>
                        <Index.Box className="btn-main-primary">
                          <Index.Button
                            className={`${state?.data?._id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                            type="submit"
                            disabled={buttonSpinner}
                          >
                            {buttonSpinner ? <PagesIndex.Spinner /> :
                              (<>
                                <img
                                  src={PagesIndex.Svg.save}
                                  className="admin-user-save-icon"
                                  alt="Save"
                                />
                                <span>{state?.data?._id ? "Update" : "Add"}</span>
                              </>)
                            }
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Modal
                    open={openUpload}
                    onClose={() => {
                      handleCloseUpload();
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="admin-modal"
                  >
                    <Index.Box
                      sx={style}
                      className="admin-add-user-modal-inner-main admin-modal-inner upload-video-modal-inner"
                    >
                      <Index.Box className="admin-modal-header">
                        <Index.Typography
                          id="modal-modal-title"
                          className="admin-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Upload
                        </Index.Typography>
                        <Index.Button
                          className="modal-close-btn"
                          onClick={() => {
                            handleCloseUpload();
                          }}
                        >
                          <img
                            src={PagesIndex.Svg.closeblack}
                            className="admin-modal-close-icon"
                            alt="Close"
                          />
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                        <Index.Box className="grid-row">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column"
                              >
                                <Index.Box className="admin-input-box admin-add-news-input-box admin-modal-input-box">
                                  {(values?.movieLink && uploadData=='movie')||(values?.movieTrailerLink && uploadData=='trailer') ? (
                                    <Index.Box className="file-upload-main  admin-file-browse-btn-main upload-video-input-box">
                                      <Index.Box className="upload-news-img-box">
                                        {uploadProgress > 0 &&
                                          uploadProgress <= 100 ? (
                                          <Index.Box
                                            sx={{
                                              position: "relative",
                                              display: "inline-flex",
                                            }}
                                          >
                                            <Index.CircularProgress
                                              variant="determinate"
                                              value={uploadProgress}
                                            />
                                            <Index.Box
                                              sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: "absolute",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Index.Typography
                                                variant="caption"
                                                component="div"
                                                color="text.secondary"
                                              >{`${Math.round(
                                                uploadProgress
                                              )}%`}</Index.Typography>
                                            </Index.Box>
                                          </Index.Box>
                                        ) : (
                                          renderVideo(values)
                                        )}
                                      </Index.Box>
                                      <Index.Button
                                        variant="contained"
                                        component="label"
                                        className="admin-file-browse-btn"
                                      >
                                        <img
                                          src={PagesIndex.Svg.whiteedit}
                                          className="admin-browse-icon-img"
                                          alt="upload img"
                                        ></img>
                                        <input
                                          hidden
                                          accept="video/*"
                                          name="video"
                                          type="file"
                                          onChange={(e) => {
                                            handleUploadVideo(
                                              e,
                                              setFieldValue,
                                              setFieldTouched
                                            );
                                          }}
                                        />
                                      </Index.Button>
                                    </Index.Box>
                                  ) : (
                                    <Index.Box className="file-upload-main admin-file-browse-btn-main upload-video-input-box">
                                      <Index.Box className="upload-news-img-box">
                                        {uploadProgress > 0 &&
                                          uploadProgress <= 100 ? (
                                          <Index.Box
                                            sx={{
                                              position: "relative",
                                              display: "inline-flex",
                                            }}
                                          >
                                            <Index.CircularProgress
                                              variant="determinate"
                                              value={uploadProgress}
                                            />
                                            <Index.Box
                                              sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: "absolute",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Index.Typography
                                                variant="caption"
                                                component="div"
                                                color="text.secondary"
                                              >{`${Math.round(
                                                uploadProgress
                                              )}%`}</Index.Typography>
                                            </Index.Box>
                                          </Index.Box>
                                        ) : (
                                          <Index.Button
                                            variant="contained"
                                            component="label"
                                            className="file-upload-button video-upload-btn"
                                          >
                                            {renderVideo(values)}
                                            <input
                                              hidden
                                              accept="video/*"
                                              name="video"
                                              type="file"
                                              onChange={(e) => {
                                                handleUploadVideo(
                                                  e,
                                                  setFieldValue,
                                                  setFieldTouched
                                                );
                                              }}
                                            />
                                          </Index.Button>
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  )}

                                  <p className="option-condition-text text-center">
                                    Video must be Mp4 type
                                  </p>
                                  <Index.Box className="admin-user-btn-flex add-news-btn-flex">
                                    <Index.Box className="btn-main-primary">
                                      <Index.Button
                                        className="btn-primary"
                                        onClick={handleCloseUpload}
                                      >
                                        Cancel
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Modal>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <VideoThumb />
    </Index.Box>
  );
}
