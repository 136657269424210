import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./castAndCrewDetails.css";
import PropTypes from "prop-types";
// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom checkbox design
const TableData = ({
  filteredData,
  currentPage,
  handleOpenViewCastandCrew,
  handleOpenAddCastandCrew,
  handleOpenDelete,
}) => {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  return filteredData?.length ? (
    filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {(currentPage - 1) * 10 + (index + 1)}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Box className="admin-table-data-flex">
                <img
                  src={
                    data?.image
                      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`
                      : PagesIndex.Svg.noimgfound
                  }
                  className="admin-table-data-img "
                  alt="User"
                ></img>
              </Index.Box>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.name}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
             
            >
              <Index.Typography className="admin-table-data-text">
                {data?.createdBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"

            >
              <Index.Typography className="admin-table-data-text">
                {data?.updatedBy?.name || "-"}
              </Index.Typography>
            </Index.TableCell>
            {/* <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                Actor/Director
              </Index.Typography>
            </Index.TableCell> */}
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box className="admin-table-data-btn-flex">
                {adminDetails?.roleId?.permissions?.includes("actor_edit") && (
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenAddCastandCrew("Edit", data)}
                    >
                      <img
                        src={PagesIndex.Svg.edit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenViewCastandCrew(data)}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
                {adminDetails?.roleId?.permissions?.includes(
                  "actor_delete"
                ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      })
  ) : (
    <PagesIndex.DataNotFound />
  );
};
export default function CastAndCrewDetails() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const [selectuserData, setSelectuserData] = useState({});
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [addOrEdit, setAddOrEdit] = useState("Add");
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  // add Cast and Crew modal
  const [openAddCastAndCrew, setOpenAddCastAndCrew] = useState(false);

  // view Cast and Crew modal
  const [openViewCastAndCrew, setOpenViewCastAndCrew] = useState(false);
  const handleOpenViewCastandCrew = (data) => {
    setId(data?._id);
    setSelectuserData(data);
    setOpenViewCastAndCrew(true);
  };
  const handleCloseViewCastandCrew = () => {
    formRef.current.resetForm();
    setSelectuserData({});
    setOpenViewCastAndCrew(false);
  };

  // delete modal
  const [openDelete, setOpenDelete] = useState(false);
  const initialValues = {
    actorName: id ? selectuserData?.name : "",
    image: "",
    imageUrl: id ? selectuserData?.image : "",
    description: id ? selectuserData?.description : "",
  };
  useEffect(() => {
    handleGetActors();
  }, []);

  useEffect(() => { }, [searchValue, data]);

  const requestSearch = (e) => {
    setSearchValue(e?.target?.value);
    let result = data.filter((data) => {
      let name = data?.name
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());
      return name;
    });
    setCurrentPage(1);
    setFilteredData(result);
  };

  const handleOpenAddCastandCrew = (op, data) => {
    setAddOrEdit(op);
    formRef.current.resetForm();
    if (data) {
      setId(data?._id);
      setSelectuserData(data);
    }
    setOpenAddCastAndCrew(true);
  };
  const handleCloseAddCastandCrew = () => {
    setId("");
    setOpenAddCastAndCrew(false);
  };

  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleGetActors = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_ACTOR).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setData(res?.data);
        setFilteredData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleAddEditActor = (values) => {
    setButtonSpinner(true)
    const formdata = new FormData();
    if (id) {
      formdata.append("id", id);
    }
    formdata.append("name", values?.actorName);
    if (values?.image) {
      formdata.append("profile", values?.image);
    }
    if (values?.description) {
      formdata.append("description", values?.description);
    }

    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_ACTOR, formdata)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          handleCloseAddCastandCrew();
          handleGetActors();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  const handleDeleteActor = () => {
    setDeleteButtonSpinner(true)
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_ACTOR, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetActors();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  // for img
  const renderImage = (values) => {
    const isUploadImg = values?.imageUrl || values.image;
    const isImageBlob = values.image instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        className={isUploadImg ? "upload-img" : "upload-profile-img"}
        src={src}
        alt="profile"
      />
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Cast/Crew List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {adminDetails?.roleId?.permissions?.includes("actor_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => handleOpenAddCastandCrew("Add")}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Cast/Crew Member</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className="admin-cast-table-main page-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="2%"
                      >
                        Sr. No.
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                      >
                        Profile
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="13%"
                      >
                        Name
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="7%"
                      
                      >
                        Created By
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="7%"
                      
                      >
                        Updated By
                      </Index.TableCell>
                      {/* <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="5%"
                    >
                      Cast/Crew
                    </Index.TableCell> */}
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="4%"
                        align="right"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    <TableData
                      filteredData={filteredData}
                      currentPage={currentPage}
                      handleOpenViewCastandCrew={handleOpenViewCastandCrew}
                      handleOpenAddCastandCrew={handleOpenAddCastandCrew}
                      handleOpenDelete={handleOpenDelete}
                    />
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleAddEditActor}
        initialValues={initialValues}
        validationSchema={
          id ? PagesIndex.actorEditSchema : PagesIndex.actorSchema
        }
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Index.Modal
              open={openAddCastAndCrew}
              onClose={handleCloseAddCastandCrew}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box
                  sx={style}
                  className="admin-add-user-modal-inner-main admin-modal-inner"
                >
                  <Index.Box className="admin-modal-header">
                    <Index.Typography
                      id="modal-modal-title"
                      className="admin-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      {addOrEdit} Cast/Crew Member
                    </Index.Typography>
                    {/* <Index.Button
                      className="modal-close-btn"
                      onClick={handleCloseAddCastandCrew}
                    >
                      <img
                        src={PagesIndex.Svg.closeblack}
                        className="admin-modal-close-icon"
                        alt="Close"
                      />
                    </Index.Button> */}
                  </Index.Box>
                  <Index.Box
                    className="admin-modal-hgt-scroll cus-scrollbar"
                    id="scrollDiv"
                  >
                    {/* <ScrollIntoView /> */}
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Select Photo<span className="astrick-sing">*</span>{" "}
                        <span className="option-condition-text">
                          (150px(Width) * 150px (Height))
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="file-upload-main thumbnail-box cast-crew-image-main">
                        <Index.Button
                          variant="contained"
                          component="label"
                          className="file-upload-button cast-crew-button"
                        >
                          {renderImage(values)}
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            id="image"
                            name="image"
                            onChange={(e) => {
                              if (e.target.files?.length !== 0) {
                                setFieldValue("image", e.target.files[0]);
                              }
                            }}
                          />
                        </Index.Button>
                        {touched.image && errors.image && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.image}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Cast/Crew Member Name
                        <span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="actorName"
                          fullWidth
                          id="actorName"
                          className="admin-form-control"
                          placeholder="Add Cast/Crew Member Name"
                          onChange={handleChange}
                          value={values?.actorName}
                          error={errors.actorName && touched.actorName}
                          inputProps={{ maxLength: 40 }}
                        />
                        {touched.actorName && errors.actorName && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.actorName}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-input-box admin-add-webseries-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Description<span className="astrick-sing">*</span>{" "}
                        <span className="option-condition-text">
                          (Max character length: 250)
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextareaAutosize
                          aria-label="minimum height"
                          // minRows={3}
                          style={{ resize: "vertical", overflow: "auto" }}
                          placeholder="Enter Description"
                          className="admin-form-control-textarea"
                          id="description"
                          name="description"
                          onChange={handleChange}
                          value={values?.description}
                          error={errors.description && touched.description}
                          maxLength={250}
                        />
                        {touched.description && errors.description && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.description}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box>
                    {/* <Index.Box className="admin-input-box add-user-input">
              <Index.FormHelperText className="admin-form-lable">
                About
              </Index.FormHelperText>
              <Index.Box className="admin-form-group">
                <Index.TextareaAutosize
                  aria-label="minimum height"
                  // minRows={3}
                  placeholder="Add description about your self"
                  className="admin-form-control-textarea"
                />
              </Index.Box>
            </Index.Box> */}
                    {/* <Index.Box className="admin-input-box add-user-input">
              <Index.FormHelperText className="admin-form-lable">
                Category
              </Index.FormHelperText>
              <Index.Box className="add-checkbox-flex">
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox />
                  <Index.Typography className="admin-checkbox-lable">
                    Director
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="admin-checkbox-main">
                  <BpCheckbox />
                  <Index.Typography className="admin-checkbox-lable">
                    Actor
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="admin-modal-user-btn-flex">
                      <Index.Box className="admin-discard-btn-main border-btn-main">
                        <Index.Button
                          className="admin-discard-user-btn border-btn"
                          onClick={handleCloseAddCastandCrew}
                          disabled={buttonSpinner}
                        >
                          Discard
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="admin-save-btn-main btn-main-primary">
                        <Index.Button
                          className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                          type="submit"
                          disabled={buttonSpinner}
                        >
                          {buttonSpinner ? <PagesIndex.Spinner /> :
                            (<>
                              <img
                                src={PagesIndex.Svg.save}
                                className="admin-user-save-icon"
                                alt="Save"
                              />
                              <span>{id ? "Update" : "Add"}</span>
                            </>)
                          }
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Modal>
            <Index.Modal
              open={openViewCastAndCrew}
              onClose={handleCloseViewCastandCrew}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner admin-cast-profile-modal"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Profile
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseViewCastandCrew}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.Box className="admin-profile-main">
                      <img
                        src={
                          values?.imageUrl
                            ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
                            : PagesIndex.Svg.noimgfound
                        }
                        className="admin-profile-img"
                        alt="upload img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="news-content-main">
                    <Index.Typography className="news-name-text">
                      {values?.actorName}
                    </Index.Typography>
                    <Index.Typography className="news-discription-text">
                      {values?.description}
                    </Index.Typography>
                  </Index.Box>
                  {/* <Index.Box className="admin-input-box admin-modal-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Cast/Crew Member Name
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Enter Cast/Crew Member Name"
                        value={values?.actorName}
                        disabled
                      />
                    </Index.Box>
                  </Index.Box> */}
                  {/* <Index.Box className="admin-input-box add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      About
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        // minRows={3}
                        placeholder="Versatile Actor of all time"
                        className="admin-form-control-textarea"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box add-user-input">
                    <Index.FormHelperText className="admin-form-lable">
                      Category
                    </Index.FormHelperText>
                    <Index.Box className="add-checkbox-flex">
                      <Index.Box className="admin-checkbox-main">
                        <BpCheckbox />
                        <Index.Typography className="admin-checkbox-lable">
                          Director
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-checkbox-main">
                        <BpCheckbox />
                        <Index.Typography className="admin-checkbox-lable">
                          Actor
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </>
        )}
      </PagesIndex.Formik>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this cast/crew? This process cannot
              be undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteActor}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

TableData.propTypes = {
  filteredData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleOpenViewCastandCrew: PropTypes.func.isRequired,
  handleOpenAddCastandCrew: PropTypes.func.isRequired,
  handleOpenDelete: PropTypes.func.isRequired,
};
