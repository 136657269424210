import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function ViewEpisode() {
  const location = PagesIndex.useLocation();
  const { state } = location;
  const { data } = state;
  const [videoLink, setVideoLink] = useState("");
  const [openIframeModal, setOpenIframeModal] = useState(false);
  const [message, setMessage] = useState("Copy");
  const handleCloseifameModal = () => {
    setOpenIframeModal(false);
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-view-page-title"
          component="h2"
          variant="h2"
        >
          Episode Details
        </Index.Typography>
      </Index.Box>

      <Index.Box className="view-details-card">
        <Index.Box className="admin-file-view-card common-card">
          <Index.Box className="admin-file-view-main">
            {/* <Index.Box className="highlight-box">
              <Index.Box className="highlight-flex">
                <img
                  src={PagesIndex.Svg.calendaricon}
                  className="calendar-icon"
                  alt=""
                />
                <Index.Typography className="highlight-content-lable">
                  Release Date:
                </Index.Typography>
                <Index.Typography className="highlight-content-data">
                  13-03-1992
                </Index.Typography>
              </Index.Box>
            </Index.Box> */}
            <img
              src={
                data?.image
                  ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`
                  : PagesIndex.Svg.noimgfound
              }
              className="admin-file-view-img"
              alt="upload img"
            />
          </Index.Box>
          <Index.Box className="admin-view-btn-flex">
            {/* <Index.Box className="btn-main-primary">
              <Index.Button className="btn-primary">
                <img
                  src={PagesIndex.Svg.playicon}
                  className="admin-icon play-icon"
                  alt="View"
                />
                <span>Youtube</span>
              </Index.Button>
            </Index.Box> */}
            <Index.Box className="btn-main-secondary">
              <Index.Button
                className="btn-secondary"
                onClick={() => {
                  setOpenIframeModal(true);
                  setVideoLink(data?.link);
                }}
              >
                <img
                  src={PagesIndex.Svg.playicon}
                  className="admin-icon play-icon"
                  alt="View"
                />
                <span>Play</span>
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="common-card brif-content-card">
          <Index.Box className="view-movie-content-main brif-content-main">
            {/* <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Durations:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  1 hours
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Cast/Crew:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  Prashant Kishor , Rajat Sharma
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Tags:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  Plitics
                </Index.Typography>
              </Index.Box>
            </Index.Box> */}
            <Index.Box className="news-content-main">
              <Index.Typography className="news-name-text">
                {data?.name}
              </Index.Typography>
              <Index.Typography className="news-discription-text">
                {data?.description}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Episode Link:
                </Index.Typography>
                <Index.Box className="view-content-link-flex">
                  <Index.Typography className="view-content-data">
                    {data?.link}
                  </Index.Typography>
                  <Index.Tooltip
                    title={message}
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                    onMouseLeave={() => {
                      setTimeout(() => {
                        setMessage("Copy");
                      }, 1000);
                    }}
                  >
                    <Index.Button
                      className="copy-btn-main"
                      onClick={() => {
                        setMessage("Copied ✓");
                        navigator.clipboard.writeText(data?.link);
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.copyicon}
                        className="copy-icon"
                        alt="copy"
                      />
                    </Index.Button>
                  </Index.Tooltip>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="view-category-content-main">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Category:
              </Index.Typography>
              <Index.Box className="view-category-flex-box">
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Drama
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Action
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Comedy
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="view-category-box">
                  <Index.Typography className="view-content-data">
                    Thriller
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openIframeModal}
        onClose={handleCloseifameModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-ifram-modal-inner-main admin-modal-inner"
        >
          <Index.Button
            className="iframe-modal-close-btn"
            onClick={handleCloseifameModal}
          >
            <img
              src={PagesIndex.Svg.closeroundicon}
              className="admin-modal-close-icon"
              alt="Close"
            />
          </Index.Button>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="iframe-main">
              {/* <iframe
                className="iframe-main-tag"
                src={`https://www.youtube.com/embed/${videoLink?.includes("watch?")
                  ? videoLink?.split("=")[1]
                  : videoLink?.split("/")[3]
                  }?autoplay=1`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe> */}
                  <video

className="iframe-main-tag"
loop
autoPlay
muted
controls
>
<source src={videoLink} type="video/mp4" />
</video>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
}
