import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "../auth.css";
import "../auth.responsive.css";
import { generateToken } from "../../../../utils/firebase/firebase";

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function Login() {
  const initialValues = {
    email: "",
    password: "",
  };
  const [buttonSpinner, setButtonSpinner] = useState(false);
  let browserInfo = navigator.userAgent;
  let browser;
  let browserV;
  if (browserInfo.includes("Opera") || browserInfo.includes("Opr")) {
    browser = "Opera";
    browserV = browserInfo.split("Opera/")[1];
  } else if (browserInfo.includes("Edg")) {
    browser = "Edge";
    browserV = browserInfo.split("Edge/")[1];
  } else if (browserInfo.includes("Chrome")) {
    browser = "Chrome";
    browserV = browserInfo;
  } else if (browserInfo.includes("Safari")) {
    browser = "Safari";
    browserV = browserInfo.split("Safari/")[1];
  } else if (browserInfo.includes("Firefox")) {
    browser = "Firefox";
    browserV = browserInfo.split("Firefox/")[1];
  } else {
    browser = "unknown";
  }
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  // for password eye hide and show
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const fetchLocalIpAddress = async () => {
    try {
      const response = await fetch("https://geolocation-db.com/json/");
      const data = await response.json();
      return data?.IPv4;
    } catch (error) {
      console.error("Error fetching local IP address:", error);
      return null;
    }
  };

  const handleLogin = async (values) => {
    setButtonSpinner(true);
    const urlencoded = new URLSearchParams();
    // const localIpAddress = await fetchLocalIpAddress();
    Promise.resolve(generateToken()).then(async (token) => {
      urlencoded.append("browserName", browser);
      // urlencoded.append("deviceId", localIpAddress);
      urlencoded.append("deviceName", browserV);
      urlencoded.append("email", values?.email?.toLowerCase());
      urlencoded.append("password", values?.password);
      urlencoded.append("fcm", token);
      PagesIndex.apiPostHandler(PagesIndex.Api.LOGIN, urlencoded).then(
        (res) => {
          setButtonSpinner(false);
          if (res.status === 200) {
            dispatch(PagesIndex.AdminLogInAction(res.data));
            dispatch(PagesIndex.GetAdminTokenAction(res.data.token));
            PagesIndex.DataService.defaults.headers.common.auth =
              res.data.token;
            navigate("/admin/dashboard", { replace: true });
          } else {
            PagesIndex.toasterError(res?.message);
          }
        }
      );
    });
  };
  return (
    <Index.Box className="admin-auth-main-flex">
      <Index.Box className="admin-auth-left-main">
        <PagesIndex.AuthBackground />
      </Index.Box>
      <Index.Box className="admin-auth-right-main">
        <Index.Box className="admin-auth-box">
          <Index.Box className="admin-auth-main">
            <Index.Link className="admin-auth-logo-box">
              <img
              src={PagesIndex.Png.adminlogo}
                className="admin-sidebar-logo"
                alt="logo"
              />
            </Index.Link>
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleLogin}
              initialValues={initialValues}
              validationSchema={PagesIndex.loginValidationSchema}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Index.Box
                  component={"form"}
                  noValidate="novalidate"
                  onSubmit={handleSubmit}
                  className="admin-auth-inner-main admin-login-inner-main"
                >
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="admin-wel-text"
                  >
                    Login
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-sign-para admin-common-para"
                  >
                    Please enter your credentials to login.
                  </Index.Typography>
                  <Index.Box className="admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Email Id
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        name="email"
                        fullWidth
                        id="fullWidth"
                        className="admin-form-control"
                        placeholder="Enter Email Id"
                        autoComplete="off"
                        onChange={handleChange}
                        value={values.email}
                        error={errors?.email && touched?.email}
                        inputProps={{ maxLength: 50 }}
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {errors?.email && touched?.email ? errors?.email : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Password
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.OutlinedInput
                        name="password"
                        className="admin-form-control-eye admin-form-control"
                        autoComplete="off"
                        placeholder="Enter Password"
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.Visibility />
                              ) : (
                                <Index.VisibilityOff />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                        onChange={handleChange}
                        value={values.password}
                        error={errors?.password && touched?.password}
                      />
                      <Index.FormHelperText className="admin-error-text">
                        {errors?.password && touched?.password
                          ? errors?.password
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-flex-all admin-forgot-row">
                    <Index.Box className="admin-checkbox-main">
                      <BpCheckbox />
                      <Index.Typography className="admin-checkbox-lable">
                        Remember Me
                      </Index.Typography>
                    </Index.Box>
                    <Index.Link
                      className="admin-text-decoration-none admin-forgot-para admin-common-para"
                      to="/reset-password"
                    >
                      Forgot Password?
                    </Index.Link>
                  </Index.Box>
                  <Index.Box className="btn-main-primary admin-login-btn-main">
                    <Index.Button
                      className="btn-primary admin-login-btn"
                      type="submit"
                      disabled={buttonSpinner}
                    >
                      {buttonSpinner ? <PagesIndex.Spinner /> : "Login"}
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="copy-right-text-main">
                    <Index.Typography className="copy-tight-text">
                     XYZ broadcast Pvt. Ltd
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
