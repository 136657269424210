import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./webSeries.css";
import "./webSeries.responsive.css";
import PropTypes from "prop-types";
import VideoThumbnail from "react-video-thumbnail";

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const TableData = ({
  filteredData,
  currentPage,
  handleOpenAddWebSeries,
  handleOpenDelete,
  handleChangeStatusSeries,
}) => {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const navigate = PagesIndex.useNavigate();
  return filteredData?.length ? (
    filteredData
      ?.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
      ?.map((data, index) => {
        return (
          <Index.TableRow
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {(currentPage - 1) * 10 + (index + 1)}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                <Index.Box className="admin-table-data-flex">
                  <img
                    src={
                      data?.image
                        ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`
                        : PagesIndex.Svg.noimgfound
                    }
                    className="admin-table-data-img "
                    alt="User"
                  ></img>
                </Index.Box>
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.title}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                <Index.Tooltip
                  title={data?.description}
                  arrow
                  placement="top"
                  className="admin-tooltip"
                >
                  {data?.description}
                </Index.Tooltip>
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.tags?.length
                  ? data?.tags
                    ?.map((data) => data?.tag)
                    .toString()
                    ?.replaceAll(",", ", ")
                  : "-"}
              </Index.Typography>
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.seasonCount}
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.episodeCount}
            </Index.TableCell>
            <Index.TableCell component="td" variant="td" className="table-td">
              {" "}
              {data?.categoryName?.replaceAll(",", ", ")}
            </Index.TableCell>
            {adminDetails?.roleId?.permissions?.includes("series_edit") && (
              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Tooltip
                  title={data?.isActive ? "Active" : "Deactive"}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Box className="admin-switch-main">
                    <Index.FormControlLabel
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      className="admin-switch-lable"
                      checked={data?.isActive}
                      onChange={() => handleChangeStatusSeries(data?._id)}
                    />
                  </Index.Box>
                </Index.Tooltip>
              </Index.TableCell>
            )}
            {adminDetails?.roleId?.permissions?.includes("season_view") && (
              <Index.TableCell component="td" variant="td" className="table-td">
                <Index.Tooltip
                  title="Add Season"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() =>
                      navigate("/admin/seasons", {
                        state: { seriesId: data?._id, title: data?.title },
                      })
                    }
                  >
                    <img
                      src={PagesIndex.Svg.darkplus}
                      className="admin-icon"
                      alt="Plus"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              </Index.TableCell>
            )}
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Box className="admin-table-data-btn-flex">
                {adminDetails?.roleId?.permissions?.includes("series_edit") && (
                  <Index.Tooltip
                    title="Edit"
                    arrow
                    placement="bottom"
                    className="admin-tooltip"
                  >
                    <Index.Button
                      className="admin-table-data-btn"
                      onClick={() => handleOpenAddWebSeries("Edit", data)}
                    >
                      <img
                        src={PagesIndex.Svg.blueedit}
                        className="admin-icon"
                        alt="Edit"
                      ></img>
                    </Index.Button>
                  </Index.Tooltip>
                )}
                <Index.Tooltip
                  title="View"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => {
                      navigate(`/admin/view-web-series/${data?._id}`);
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.yelloweye}
                      className="admin-icon"
                      alt="View"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
                {adminDetails?.roleId?.permissions?.includes(
                  "series_delete"
                ) && (
                    <Index.Tooltip
                      title="Delete"
                      arrow
                      placement="bottom"
                      className="admin-tooltip"
                    >
                      <Index.Button
                        className="admin-table-data-btn"
                        onClick={() => handleOpenDelete(data?._id)}
                      >
                        <img
                          src={PagesIndex.Svg.trash}
                          className="admin-icon"
                          alt="Delete"
                        ></img>
                      </Index.Button>
                    </Index.Tooltip>
                  )}
              </Index.Box>
            </Index.TableCell>
          </Index.TableRow>
        );
      })
  ) : (
    <PagesIndex.DataNotFound />
  );
};
export default function WebSeries() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const videoRef = useRef();
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [data, setData] = useState([]);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectData, setSelectData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const [addOrEdit, setAddOrEdit] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddWebSeries, setOpenAddWebSeries] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
  const [message, setMessage] = useState("Copy");

  // add WebSeries modal
  const initialValues = {
    title: id ? selectData?.title : "",
    tags: id ? selectData?.tags?.map((data) => data?._id) : "",
    genreCategory: id
      ? selectData?.categoryId?.map((data) => {
        return data?._id;
      })
      : [],
    genreName: id
      ? selectData?.categoryId?.map((data) => {
        return data?.name;
      })
      : [],

    description: id ? selectData?.description : "",

    movieTrailerLink: id ? selectData?.trailerLink : "",

    image: "",
    imageUrl: id ? selectData?.image : "",
  };

  const requestSearch = (e) => {
    setSearchValue(e.target.value);
    let result = data.filter((data) => {
      let title = data?.title
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());

      let categoryName = data?.categoryName
        ?.toLowerCase()
        .includes(e?.target?.value?.trim()?.toLowerCase());

      return title || categoryName;
    });

    setCurrentPage(1);
    setFilteredData(result);
  };
  const handleGetTag = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_MOVIE_TAG).then((res) => {
      if (res.status === 200) {
        const activeTags = res?.data?.filter((tag) => tag?.isActive);
        setTagData(activeTags);
      }
    });
  };
  const handleOpenAddWebSeries = (op, data) => {
    if (data) {
      setId(data?._id);
      setSelectData(data);
    }
    setAddOrEdit(op);
    setOpenAddWebSeries(true);
  };
  const handleCloseAddWebSeries = () => {
    setId("");
    formRef.current.resetForm();
    setOpenAddWebSeries(false);
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true)
    PagesIndex.apiGetHandler(`${PagesIndex.Api.MOVIES_STATUS}/${id}`).then(
      (res) => {
        setStatusButtonSpinner(false)
        if (res.status === 200) {
          handleGetWebSeries();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  // delete modal
  const handleOpenDelete = (id) => {
    setId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  // page navigate

  const navigate = PagesIndex.useNavigate();
  useEffect(() => {
    setLoading(true);
    handleGetCategories();
    handleGetWebSeries();
    handleGetTag();
  }, []);
  const handleGetWebSeries = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_WEB_SERIES).then((res) => {
      setLoading(false);
      if (res?.status === 200) {
        setData(res?.data);
        setFilteredData(res?.data);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleGetCategories = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_MOVIE_CATEGORY).then(
      (res) => {
        if (res?.status === 200) {
          setCategoryData(res?.data?.filter((data) => data?.isActive));
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleAddEditMovie = (values) => {
    setButtonSpinner(true)
    const formdata = new FormData();
    if (id) {
      formdata.append("id", id);
    }
    formdata.append("title", values?.title);
    formdata.append("description", values?.description);
    formdata.append("categoryId", JSON.stringify(values?.genreCategory));
    formdata.append("categoryName", values?.genreName);
    formdata.append("trailerLink", values?.movieTrailerLink);
    formdata.append("type", "webseries");
    if (values?.image) {
      formdata.append("profile", values?.image);
    }
    if (values?.tags?.length) {
      values?.tags?.forEach((tag, index) => {
        formdata.append(`tags[${index}]`, tag);
      });
    } else {
      formdata.append(`tags`, []);
    }
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_MOVIES, formdata)
      .then((res) => {
        setButtonSpinner(false)
        if (res.status === 201 || res.status === 200) {
          navigate("/admin/web-series");
          handleCloseAddWebSeries();
          handleGetWebSeries();
          setSearchValue("");
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };
  const handleDeleteWebSeries = () => {
    setDeleteButtonSpinner(true)
    PagesIndex.apiGetHandler(`${PagesIndex.Api.DELETE_MOVIES}/${id}`).then(
      (res) => {
        setDeleteButtonSpinner(false)
        if (res.status === 200) {
          handleCloseDelete();
          handleGetWebSeries();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };

  // for img
  const renderImage = (values) => {
    const isUploadImg = values?.imageUrl || values.image;
    const isImageBlob = values.image instanceof Blob;
    const imageUrl = values?.imageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (isImageBlob) {
      src = URL.createObjectURL(values.image);
    } else if (isUploadImg) {
      src = imageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        className={isUploadImg ? "upload-img" : "upload-profile-img"}
        src={src}
        alt="zone"
      />
    );
  };
  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        let scrollDiv = document.getElementById("scrollDiv");
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          scrollDiv.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
    
  };








  const renderVideo = (values) => {
    return values.movieTrailerLink ? (
      <video
        ref={videoRef}
        className="upload-img"
        loop={true}
        autoPlay="autoplay"
        muted
      >
        <source
          src={values?.movieTrailerLink}
          type="video/mp4"
        />
      </video>
    ) : (
      <img
        src={PagesIndex.Svg.fileupload}
        className={"upload-profile-img"}
        alt="upload img"
      />
    );
  };
  const [uploadedVideo, setUploadedVideo] = useState("");
  const handleOpenUpload = () => setOpenUpload(true);
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleUploadVideo = (e, setFieldValue, setFieldTouched) => {
    setUploadedVideo("");
    const formdata = new FormData();
    formdata.append("video", e.target.files[0]);
    PagesIndex.apiPostHandlerVideoUpload(
      PagesIndex.Api.UPLOAD_S3_LINK,
      formdata,
      {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      }
    ).then((res) => {
      setUploadProgress(0);
      if (res?.status === 201 || res?.status === 200) {
        PagesIndex.toasterSuccess(res.message);
        videoRef.current?.load();
        setUploadedVideo(`${process.env.REACT_APP_IMAGE_ENDPOINT}${res.data}`);
        setFieldTouched("movieTrailerLink", false);
        setFieldValue("movieTrailerLink",`${process.env.REACT_APP_IMAGE_ENDPOINT}${res.data}`);
        setOpenUpload(false);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const VideoThumb = useCallback(() => {
    return (
      <VideoThumbnail
        videoUrl={uploadedVideo}
        thumbnailHandler={(thumbnail) => {
          formRef.current.setFieldValue("imageUrl", thumbnail);
        }}
        renderThumbnail={false}
      />
    );
  }, [uploadedVideo]);
  
  
  return (
    <>
      <Index.Box className="admin-dashboard-content admin-user-management-content">
        <Index.Box className="admin-user-list-flex admin-page-title-main">
          <Index.Typography
            className="admin-page-title admin-user-list-page-title"
            component="h2"
            variant="h2"
          >
            Web Series List
          </Index.Typography>
          <Index.Box className="admin-userlist-btn-flex">
            <Index.Box className="admin-search-main">
              <Index.Box className="admin-search-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={requestSearch}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="admin-search-grey-img admin-icon"
                    alt="search"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {adminDetails?.roleId?.permissions?.includes("series_add") && (
              <Index.Box className="admin-userlist-inner-btn-flex">
                <Index.Box className="admin-adduser-btn-main btn-main-primary">
                  <Index.Button
                    className="admin-adduser-btn btn-primary"
                    onClick={() => handleOpenAddWebSeries("Add")}
                  >
                    <img
                      src={PagesIndex.Svg.plus}
                      className="admin-plus-icon"
                      alt="plus"
                    />
                    <span>Add Web Series</span>
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Box>
        </Index.Box>

        <Index.Box className="common-card">
          <Index.Box className={`admin-web-series-table-main  page-table-main`}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              {loading ? (
                <PagesIndex.Loader />
              ) : (
                <Index.Table aria-label="simple table" className="table">
                  <Index.TableHead className="table-head">
                    <Index.TableRow className="table-row">
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="4%"
                      >
                        Sr. No.
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                      >
                        Thumbnail
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="8%"
                      >
                        Title
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="15%"
                      >
                        Description
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Tags
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        No. of Seasons
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        No. of Episodes
                      </Index.TableCell>
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="10%"
                      >
                        Category
                      </Index.TableCell>
                      {adminDetails?.roleId?.permissions?.includes(
                        "series_edit"
                      ) && (
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="3%"
                          >
                            Status
                          </Index.TableCell>
                        )}
                      {adminDetails?.roleId?.permissions?.includes(
                        "season_view"
                      ) && (
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="7%"
                          >
                            Add Season
                          </Index.TableCell>
                        )}
                      <Index.TableCell
                        component="th"
                        variant="th"
                        className="table-th"
                        width="3%"
                        align="right"
                      >
                        Action
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody className="table-body">
                    {loading ? (
                      <PagesIndex.Loader />
                    ) : (
                      <TableData
                        filteredData={filteredData}
                        currentPage={currentPage}
                        handleOpenAddWebSeries={handleOpenAddWebSeries}
                        handleOpenDelete={handleOpenDelete}
                        handleChangeStatusSeries={handleOpenStatusModal}
                      />
                    )}
                  </Index.TableBody>
                </Index.Table>
              )}
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="admin-pagination-main">
            {filteredData?.length ? (
              <Index.Pagination
                page={currentPage}
                count={Math.ceil(filteredData?.length / 10)}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                variant="outlined"
                shape="rounded"
                className="admin-pagination"
              />
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openAddWebSeries}
        onClose={handleCloseAddWebSeries}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner"
        >
          <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddEditMovie}
            initialValues={initialValues}
            validationSchema={
              id ? PagesIndex.seriesEditSchema : PagesIndex.seriesSchema
            }
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched
            }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {addOrEdit} Web Series
                  </Index.Typography>
                  {/* <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseAddWebSeries}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button> */}
                </Index.Box>
                <Index.Box
                  className="admin-modal-hgt-scroll cus-scrollbar"
                  id="scrollDiv"
                >
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="admin-input-box admin-add-webseries-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Title<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextField
                        fullWidth
                        id="title"
                        className="admin-form-control"
                        placeholder="Enter Title"
                        onChange={handleChange}
                        name="title"
                        value={values?.title}
                        error={errors.title && touched.title}
                        inputProps={{ maxLength: 40 }}
                      />
                      {touched.title && errors.title && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.title}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-add-webseries-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Description<span className="astrick-sing">*</span>{" "}
                      <span className="option-condition-text">
                        (Max character length: 250)
                      </span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        // minRows={3}
                        style={{ resize: "vertical", overflow: "auto" }}
                        placeholder="Enter Description"
                        className="admin-form-control-textarea"
                        id="description"
                        name="description"
                        onChange={handleChange}
                        value={values?.description}
                        error={errors.description && touched.description}
                        maxLength={250}
                      />
                      {touched.description && errors.description && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.description}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-add-webseries-input-box input-complete-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Tags<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                        <Index.Autocomplete
                          className="admin-auto-complete-filed"
                          options={tagData || []}
                          id="tags"
                          multiple
                          disableCloseOnSelect
                          freeSolo
                          closeText={""}
                          openText={""}
                          clearText={""}
                          getOptionSelected={(option, value) =>
                            option._id === value._id
                          }
                          value={
                            values?.tags
                              ? tagData.filter((tag) =>
                                values?.tags?.includes(tag._id)
                              )
                              : []
                          }
                          onChange={(e, values) => {
                            if (!e.key) {
                              setFieldValue(
                                "tags",
                                values.map((tag) => tag._id)
                              );
                            }
                          }}
                          getOptionLabel={(option) => option?.tag}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.tag}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              {...params}
                              size="small"
                              name="tags"
                              placeholder={
                                values?.tags?.length ? "" : "Enter Tag"
                              }
                              variant="outlined"
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  event.preventDefault(); // Prevent form submission
                                  const inputValue = event.target.value.trim();
                                  if (
                                    inputValue &&
                                    !values?.tags?.includes(inputValue)
                                  ) {
                                    setFieldValue("tags", [
                                      ...(values?.tags ? values.tags : []),
                                      inputValue,
                                    ]);
                                  }
                                  if (
                                    !tagData.find(
                                      (tag) => tag._id === inputValue
                                    )
                                  ) {
                                    setTagData((prev) => [
                                      ...prev,
                                      {
                                        _id: inputValue,
                                        tag: inputValue,
                                      },
                                    ]);
                                  }
                                  event.target.value = "";
                                }
                              }}
                            />
                          )}
                        />
                      </Index.FormControl>
                      {touched.tags && errors.tags && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.tags}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-add-webseries-input-box input-complete-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Categories<span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="admin-form-group">
                      <Index.FormControl className="admin-form-control admin-auto-complete-control">
                        <Index.Autocomplete
                          className="admin-auto-complete-filed"
                          options={categoryData || []}
                          id="genreCategory"
                          multiple
                          disableCloseOnSelect
                          closeText={""}
                          openText={""}
                          clearText={""}
                          value={
                            values?.genreCategory
                              ? categoryData.filter((data) =>
                                values?.genreCategory?.some(
                                  (cat) => cat === data?._id
                                )
                              )
                              : []
                          }
                          onChange={(e, selectedOptions) => {
                            setFieldValue(
                              "genreCategory",
                              selectedOptions.map((option) => option._id)
                            );
                            setFieldValue(
                              "genreName",
                              selectedOptions
                                .map((option) => option.name)
                                .toString()
                            );
                          }}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, item) => (
                            <li {...props} key={item?._id}>
                              {item?.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <Index.TextField
                              fullWidth
                              className="admin-form-control"
                              name="genreCategory"
                              placeholder={
                                values?.genreCategory?.length
                                  ? ""
                                  : "Select Category"
                              }
                              {...params}
                              size="small"
                              variant="outlined"
                            />
                          )}
                        />

                        {touched.genreCategory && errors.genreCategory && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.genreCategory}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-input-box admin-add-webseries-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Web Series Trailer Link
                      <span className="astrick-sing">*</span>
                    </Index.FormHelperText>
                   
                    <Index.Box className="admin-form-group">
                          <Index.OutlinedInput
                            fullWidth
                            id="video"
                            className="admin-form-control admin-form-control-eye"
                            placeholder="Upload Web Series Trailer Link"
                            name="movieTrailerLink"
                            disabled
                            value={
                              values?.movieTrailerLink
                               
                            }
                            endAdornment={
                              values?.movieTrailerLink ? (
                                <Index.InputAdornment position="end">
                                  <Index.Tooltip
                                    title={message}
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                    onMouseLeave={() => {
                                      setTimeout(() => {
                                        setMessage("Copy");
                                      }, 1000);
                                    }}
                                  >
                                    <Index.Button
                                      className="copy-btn-main"
                                      onClick={() => {
                                        setMessage("Copied ✓");
                                        navigator.clipboard.writeText(
                                          values?.movieTrailerLink
                                        );
                                      }}
                                    >
                                      <img
                                        src={PagesIndex.Svg.copyicon}
                                        className="copy-icon"
                                        alt="copy"
                                      />
                                    </Index.Button>
                                  </Index.Tooltip>
                                  <Index.Tooltip
                                    title="Delete"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                  >
                                    <Index.Button
                                      className="copy-btn-main"
                                      onClick={() => {
                                        setFieldValue("movieTrailerLink", "");
                                        // setUploadVideoId(undefined);
                                        if (!id) {
                                          setFieldValue("imageUrl", "");
                                        }
                                      }}
                                    >
                                      <img
                                        src={PagesIndex.Svg.closeBold}
                                        className="admin-icon"
                                        alt="copy"
                                      />
                                    </Index.Button>
                                  </Index.Tooltip>
                                </Index.InputAdornment>
                              ) : (
                                ""
                              )
                            }
                          />
                            {touched.movieTrailerLink && errors.movieTrailerLink && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.movieTrailerLink}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                    <Index.Box className="admin-input-box admin-add-news-input-box">
                      <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                      <Index.Box className="btn-main-secondary">
                        <Index.Button
                          className="btn-secondary"
                        onClick={handleOpenUpload}
                        >
                          Upload Video
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>



                  <Index.Box className="admin-input-box admin-add-webseries-input-box">
                    <Index.FormHelperText className="admin-form-lable">
                      Thumbnail<span className="astrick-sing">*</span>
                      <span className="option-condition-text">
                      (Image must be 1280px(Width) * 720px(Height))
                      </span>
                    </Index.FormHelperText>
                    <Index.Box className="file-upload-main thumbnail-box">
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-button"
                      >
                        {renderImage(values)}
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          id="image"
                          name="image"
                          onChange={(e) => {
                            if (e.target.files?.length !== 0) {
                              setFieldValue("image", e.target.files[0]);
                            }
                          }}
                        />
                      </Index.Button>
                      {touched.image && errors.image && (
                        <PagesIndex.FormHelperText className="admin-error-text">
                          {errors.image}
                        </PagesIndex.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-discard-btn-main border-btn-main">
                      <Index.Button
                        className="admin-discard-user-btn border-btn"
                        onClick={handleCloseAddWebSeries}
                        disabled={buttonSpinner}
                      >
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                        type="submit"
                        disabled={buttonSpinner}
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> :
                          (<>
                            <img
                              src={PagesIndex.Svg.save}
                              className="admin-user-save-icon"
                              alt="Save"
                            />
                            <span>{id ? "Update" : "Add"}</span>
                          </>)
                        }
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Modal
              open={openUpload}
              onClose={() => {
                handleCloseUpload();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner upload-video-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Upload
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={() => {
                      handleCloseUpload();
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box admin-modal-input-box">
                            {values?.movieTrailerLink ? (
                              <Index.Box className="file-upload-main  admin-file-browse-btn-main upload-video-input-box">
                                <Index.Box className="upload-news-img-box">
                                  {uploadProgress > 0 &&
                                  uploadProgress <= 100 ? (
                                    <Index.Box
                                      sx={{
                                        position: "relative",
                                        display: "inline-flex",
                                      }}
                                    >
                                      <Index.CircularProgress
                                        variant="determinate"
                                        value={uploadProgress}
                                      />
                                      <Index.Box
                                        sx={{
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          position: "absolute",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Index.Typography
                                          variant="caption"
                                          component="div"
                                          color="text.secondary"
                                        >{`${Math.round(
                                          uploadProgress
                                        )}%`}</Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  ) : (
                                    renderVideo(values)
                                  )}
                                </Index.Box>
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="admin-file-browse-btn"
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteedit}
                                    className="admin-browse-icon-img"
                                    alt="upload img"
                                  ></img>
                                  <input
                                    hidden
                                    accept="video/*"
                                    name="video"
                                    type="file"
                                    onChange={(e) => {
                                      handleUploadVideo(
                                        e,
                                        setFieldValue,
                                        setFieldTouched
                                      );
                                    }}
                                  />
                                </Index.Button>
                              </Index.Box>
                            ) : (
                              <Index.Box className="file-upload-main admin-file-browse-btn-main upload-video-input-box">
                                <Index.Box className="upload-news-img-box">
                                  {uploadProgress > 0 &&
                                  uploadProgress <= 100 ? (
                                    <Index.Box
                                      sx={{
                                        position: "relative",
                                        display: "inline-flex",
                                      }}
                                    >
                                      <Index.CircularProgress
                                        variant="determinate"
                                        value={uploadProgress}
                                      />
                                      <Index.Box
                                        sx={{
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          position: "absolute",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Index.Typography
                                          variant="caption"
                                          component="div"
                                          color="text.secondary"
                                        >{`${Math.round(
                                          uploadProgress
                                        )}%`}</Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  ) : (
                                    <Index.Button
                                      variant="contained"
                                      component="label"
                                      className="file-upload-button video-upload-btn"
                                    >
                                      {renderVideo(values)}
                                      <input
                                        hidden
                                        accept="video/*"
                                        name="video"
                                        type="file"
                                        onChange={(e) => {
                                          handleUploadVideo(
                                            e,
                                            setFieldValue,
                                            setFieldTouched
                                          );
                                        }}
                                      />
                                    </Index.Button>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            )}
                            <p className="option-condition-text text-center">
                              Video must be Mp4 type
                            </p>
                            <Index.Box className="admin-user-btn-flex add-news-btn-flex">
                              <Index.Box className="btn-main-primary">
                                <Index.Button
                                  className="btn-primary"
                                  onClick={handleCloseUpload}
                                >
                                  Cancel
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this web series? This process cannot
              be undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleDeleteWebSeries}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? <PagesIndex.Spinner /> : <span>Delete</span>
                }
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"web series"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </>
  );
}
TableData.propTypes = {
  filteredData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleOpenAddWebSeries: PropTypes.func.isRequired,
  handleOpenDelete: PropTypes.func.isRequired,
  handleChangeStatusSeries: PropTypes.func.isRequired,
};
