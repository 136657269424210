import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./homeCategoryManagement.responsive.css";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import { styled } from "@mui/material/styles";
import { HTML5Backend } from "react-dnd-html5-backend";

export const DraggableTypes = {
    REORDERABLE_ROW: "reorderable_row",
};

// for modal design

const IOSSwitch = Index.styled((props) => (
    <Index.Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 14,
        height: 14,
        boxShadow: "none",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    border: "1px solid #b2b3b3",
    width: 12,
    height: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));
function BpCheckbox(props) {
    return (
        <Index.Checkbox
            sx={{
                "&:hover": { bgcolor: "transparent" },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
        />
    );
}
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    "&:before": {
        display: "block",
        width: 12,
        height: 12,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "transparent",
    },
});


export default function CategorySong() {
    const { adminDetails } = PagesIndex.useSelector(
        (state) => state.AdminReducer
    );
    const { state } = PagesIndex.useLocation();
    const navigate = PagesIndex.useNavigate()
    const [data, setData] = useState([]);
    const [id, setId] = useState("");
    const [selectuserData, setSelectuserData] = useState();
    const [loading, setLoading] = useState(true);
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
    const [openLogModal, setOpenLogModal] = useState(false);
    const handleCloseLogModal = () => setOpenLogModal(false);
    const [searchValue, setSearchValue] = useState("");
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
    const [idData, setIdData] = useState()
    const [movieData, setMovieData] = useState([])
    const [pageCount, setPageCount] = useState()


    const handleOpenLogModal = (id) => {
        setIdData(id)
        setOpenLogModal(true);
    }
    const formRef = useRef();
    const initialValues = {
        songs: []
        // color: id ? selectuserData?.colorCode : "#000",
        // vertical: id ? selectuserData?.vertical : false,
    };

    const [addOrEdit, setAddOrEdit] = useState("Add");
    const [filteredData, setFilteredData] = useState([]);
    // add user modal
    const [open, setOpen] = useState(false);
    // delete modal
    const [openDelete, setOpenDelete] = useState(false);

    const handleOpen = (op, id, data) => {
        setOpen(true);
        setAddOrEdit(op);
        setId(id);
        setSelectuserData(data);
    };
    const handleClose = () => {
        setId("");
        formRef.current.resetForm();
        setOpen(false);
    };
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setId(id);
    };
    const handleCloseDelete = () => {
        setId("");
        setOpenDelete(false);
    };

    const handleGetCategory =  (searchTerm, pageNo, dataPerPage, tabNo)  => {
        // const data = {
        //     page: pageNo ?? page,
        //     limit: 10,
        //     search: searchTerm ?? search,
        //     id:state?.id
        // }
        PagesIndex.apiPostHandler(PagesIndex.Api.GET_HOME_SONGS,{ id:state?.id}).then((res) => {
            setLoading(false);
            console.log(res,'nirmal');
            if (res.status == 200) {
                setData(res.data);

                setFilteredData(res.data);
                setPageCount(res?.pagination?.totalPages)
                // setPage(res?.pagination?.currentPag)
            }
        });
    };
    // const { page, tabValue, search, setPage, handleSearchChange, handlePageChange, handleTabChange } = PagesIndex.useDebounceHook(handleGetCategory, setFilteredData);

    const handleGetMovies = () => {
        PagesIndex.apiPostHandler(`${PagesIndex.Api.GET_ALL_IN_DROPDOWN_SONGS}?id=${state?.id}`).then((res) => {
            setLoading(false);
            if (res.status === 200) {
                setMovieData(res.data);
            }
            else{
                setMovieData([]);
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        handleGetCategory();
        handleGetMovies()
    }, [state?.id]);

    
    const handleAddEditCategory = (values) => {
        setButtonSpinner(true)

        PagesIndex.apiPostHandler(PagesIndex.Api.ADD_EDIT_HOME_SONG_SECTION, {homeCategory:state?.id,songId:values?.songs})
            .then((res) => {
                setButtonSpinner(false)
                if (res.status === 201 || res.status === 200) {
                    handleClose();
                    setSearchValue("");
                    handleGetCategory();
                    handleGetMovies()
                    PagesIndex.toasterSuccess(res?.message);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            })
            .catch((err) => {
                PagesIndex.toasterError(err.response.message);
            });
    };
    const handleDeleteCategory = () => {
        setDeleteButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(
            PagesIndex.Api.DELETE_HOME_SONG,
            urlEncoded
        ).then((res) => {
            setDeleteButtonSpinner(false)
            if (res.status === 200) {
                handleCloseDelete();
                handleGetCategory();
                handleGetMovies()
                PagesIndex.toasterSuccess(res?.message);
            } else {
                PagesIndex.toasterError(res?.message);
            }
        });
    };
    const requestSearch = (e) => {
        setSearchValue(e.target.value);
        let filteredData = data.filter(
            (data) =>
                data?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()) ||
                data?.sequence == e?.target?.value
        );
        setFilteredData(filteredData);
    };
    const handleOpenStatusModal = (id) => {
        setId(id);
        setOpenStatusModal(true);
    };
    const handleCloseStatusModal = () => {
        setId("");
        setOpenStatusModal(false);
    };
    const handleStatus = () => {
        setStatusButtonSpinner(true)
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(
            PagesIndex.Api.ISACTIVE_HOME_SONG,
            urlEncoded
        ).then((res) => {
            setStatusButtonSpinner(false)
            if (res.status === 200) {
                handleCloseStatusModal();
                PagesIndex.toasterSuccess(res?.message);
                handleGetCategory();
            } else {
                PagesIndex.toasterError(res?.message);
            }
        });
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = filteredData[dragIndex];
            setFilteredData(
                update(filteredData, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            );
            handleChangeSequence(
                update(filteredData, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            );
        },
        [filteredData]
    );
    const handleChangeSequence = (list) => {
        const urlEncoded = new URLSearchParams();
        const updatedRows = list?.map((data, index) => ({
            ...data,
            sequence: index + 1,
        }));
        urlEncoded.append("updatedRows", JSON.stringify(updatedRows));
        PagesIndex.apiPostHandler(
            PagesIndex.Api.UPDATE_SONG_ROW,
            urlEncoded
        ).then((res) => {
            if (res.status === 200) {
                PagesIndex.toasterSuccess(res?.message);
            } else {
                handleGetCategory();
                PagesIndex.toasterError(res?.message);
            }
        });
    };
    const renderTableRows = (setFieldValue) => {
        return filteredData?.map((data, index) => {
            return (
                <ReorderableRow
                    key={data?._id}
                    index={index}
                    id={data?._id}
                    moveRow={moveRow}
                >
                    <Fragment key={data?._id}>
                        <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Typography className="admin-table-data-text">
                                {index + 1}
                            </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.songId?.title}
                            </Index.Typography>
                        </Index.TableCell>
                        {/* <Index.TableCell component="td" variant="td" className="table-td">
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.moviewebSeriesId?.type}
                            </Index.Typography>
                        </Index.TableCell> */}
                        <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"

                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.createdBy?.name || "-"}
                            </Index.Typography>
                        </Index.TableCell>
                        {/* <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="right"
                        >
                            <Index.Typography className="admin-table-data-text">
                                {" "}
                                {data?.updatedBy?.name || "-"}
                            </Index.Typography>
                        </Index.TableCell> */}

                        {/* {adminDetails?.roleId?.permissions?.includes(
                "home_category_edit"
              ) && (
                  <Index.TableCell
                    component="td"
                    variant="td"
                    align="right"
                    className="table-td"
                  >
                    <Index.Box className="admin-switch-main">
                      <Index.FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        className="admin-switch-lable"
                        checked={data?.isActive}
                        onChange={() => {
                          handleOpenStatusModal(data?._id);
                        }}
                      />
                    </Index.Box>
                  </Index.TableCell>
                )} */}


                        {(adminDetails?.roleId?.permissions?.includes(
                            "category_song_edit"
                        ) ||
                            adminDetails?.roleId?.permissions?.includes(
                                "category_song_delete"
                            ) ||
                            adminDetails?.roleId?.permissions?.includes("category_song_log")) && (
                                <Index.TableCell
                                    component="td"
                                    variant="td"
                                    align="right"
                                    className="table-td"
                                >
                                    <Index.Box className="admin-table-data-btn-flex ">

                                        {adminDetails?.roleId?.permissions?.includes(
                                            "category_song_edit"
                                        ) && (
                                                <Index.Box className="admin-switch-main">
                                                    <Index.FormControlLabel
                                                        control={<IOSSwitch sx={{ m: 1 }} />}
                                                        className="admin-switch-lable"
                                                        checked={data?.isActive}
                                                        onChange={() => {
                                                            handleOpenStatusModal(data?._id);
                                                        }}
                                                    />
                                                </Index.Box>
                                            )}
                                        {/* {adminDetails?.roleId?.permissions?.includes(
                        "home_category_log"
                      ) && (
                          <Index.Tooltip
                            title="Log"
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <Index.Button
                              className="admin-table-data-btn"
                              onClick={() => handleOpenLogModal(data?._id)}
                            >
                              <img
                                src={PagesIndex.Svg.logicon}
                                className="admin-icon"
                                alt="Log"
                              ></img>
                            </Index.Button>
                          </Index.Tooltip>
                        )} */}

                                        {adminDetails?.roleId?.permissions?.includes(
                                            "category_song_delete"
                                        ) && (
                                                <Index.Tooltip
                                                    title="Delete"
                                                    arrow
                                                    placement="bottom"
                                                    className="admin-tooltip"
                                                >
                                                    <Index.Button
                                                        className="admin-table-data-btn"
                                                        onClick={() => handleOpenDelete(data?._id)}
                                                    >
                                                        <img
                                                            src={PagesIndex.Svg.trash}
                                                            className="admin-icon"
                                                            alt="Delete"
                                                        ></img>
                                                    </Index.Button>
                                                </Index.Tooltip>
                                            )}
                                    </Index.Box>
                                </Index.TableCell>
                            )}
                    </Fragment>
                </ReorderableRow>
            );
        });
    };

    const renderContent = (setFieldValue) => {
        if (filteredData?.length) {
            return renderTableRows(setFieldValue);
        } else {
            return <PagesIndex.DataNotFound />;
        }
    };

    const ScrollIntoView = () => {
        // Scroll to the first error when errors change
        const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
        useEffect(() => {
            if (errors && Object.keys(errors).length > 0) {
                const firstErrorField = Object.keys(errors)[0];
                let scrollDiv = document.getElementById("scrollDiv");
                const firstErrorElement = document.querySelector(
                    `[id="${firstErrorField}"]`
                );
                if (firstErrorElement) {
                    scrollDiv.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    firstErrorElement.focus();
                }
            }
        }, [isSubmitting]);
    };
    return (
        <PagesIndex.Formik
            enableReinitialize
            onSubmit={handleAddEditCategory}
            initialValues={initialValues}
            validationSchema={PagesIndex.homeCategorySongSchema}
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <>
                    <Index.Box className="admin-dashboard-content admin-user-list-content">
                        <Index.Box className="admin-user-list-flex admin-page-title-main">
                            <Index.Typography
                                className="admin-page-title admin-user-list-page-title"
                                component="h2"
                                variant="h2"
                            >
                               {state?.name} Song List
                            </Index.Typography>
                            <Index.Box className="admin-userlist-btn-flex">
                                {/* <Index.Box className="admin-search-main">
                                    <Index.Box className="admin-search-box">
                                        <Index.Box className="admin-form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="admin-form-control"
                                                placeholder="Search"
                                                //   value={searchValue}
                                                // onChange={handleSearchChange}
                                            />
                                            <img
                                                src={PagesIndex.Svg.search}
                                                className="admin-search-grey-img admin-icon"
                                                alt="search"
                                            ></img>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box> */}
                                {adminDetails?.roleId?.permissions?.includes(
                                    "category_movies_add"
                                ) && (
                                        <Index.Box className="admin-userlist-inner-btn-flex">
                                            <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                                <Index.Button
                                                    className="admin-adduser-btn btn-primary"
                                                    onClick={() => {
                                                        setId("");
                                                        handleOpen("Add");
                                                    }}
                                                >
                                                    <img
                                                        src={PagesIndex.Svg.plus}
                                                        className="admin-plus-icon"
                                                        alt="plus"
                                                    />
                                                    <span>Add Songs</span>
                                                </Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    )}
                            </Index.Box>
                        </Index.Box>

                        <Index.Box className="common-card">
                            <Index.Box
                                className={`admin-category-table-main ${loading ? "" : "page-table-main"
                                    }`}
                            >
                                <DndProvider backend={HTML5Backend}>
                                    {loading ? (
                                        <PagesIndex.Loader />
                                    ) : (
                                        <Index.TableContainer
                                            component={Index.Paper}
                                            className="table-container"
                                        >
                                            <Index.Table aria-label="simple table" className="table">
                                                <Index.TableHead className="table-head">
                                                    <Index.TableRow className="table-row">
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            width="5%"
                                                        >
                                                            Sr. No.
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            width="10%"
                                                        >
                                                            Song Name
                                                        </Index.TableCell>

                                                        {/* <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            width="8%"

                                                        >
                                                            Movie Type
                                                        </Index.TableCell> */}
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            width="8%"

                                                        >
                                                            Created By
                                                        </Index.TableCell>
                                                        {/* 
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            width="12%"
                                                            align="right"
                                                        >
                                                            Updated By
                                                        </Index.TableCell> */}

                                                        {/* {(adminDetails?.roleId?.permissions?.includes(
                                "home_category_edit"
                              ) ||
                                adminDetails?.roleId?.permissions?.includes(
                                  "home_category_delete"
                                )) && (
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    width="6%"
                                    align="right"
                                  >
                                    Status
                                  </Index.TableCell>
                                )} */}

                                                        {(adminDetails?.roleId?.permissions?.includes(
                                                            "category_song_edit"
                                                        ) ||
                                                            adminDetails?.roleId?.permissions?.includes(
                                                                "category_song_delete"
                                                            ) ||
                                                            adminDetails?.roleId?.permissions?.includes(
                                                                "category_song_log"
                                                            )) && (
                                                                <Index.TableCell
                                                                    component="th"
                                                                    variant="th"
                                                                    className="table-th"
                                                                    width="4%"
                                                                    align="right"
                                                                >
                                                                    Action
                                                                </Index.TableCell>
                                                            )}
                                                    </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className="table-body">
                                                    {renderContent(setFieldValue)}
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                    )}
                                </DndProvider>
                            </Index.Box>
                         
                            {/* <Index.Box className="admin-pagination-main">
                                {pageCount>1 ? (
                                    <Index.Pagination
                                        page={page}
                                        count={pageCount}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        shape="rounded"
                                        className="admin-pagination"
                                    />
                                ) : (
                                    ""
                                )}
                            </Index.Box> */}
                        </Index.Box>
                    </Index.Box>

                    <Index.Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="admin-modal"
                    >
                        <form onSubmit={handleSubmit} noValidate="novalidate">
                            <Index.Box
                                sx={style}
                                className="admin-add-user-modal-inner-main admin-modal-inner"
                            >
                                <Index.Box className="admin-modal-header">
                                    <Index.Typography
                                        id="modal-modal-title"
                                        className="admin-modal-title"
                                        variant="h6"
                                        component="h2"
                                    >
                                        {/* {addOrEdit} Home Category */}
                                        Add Songs
                                    </Index.Typography>
                                    {/* <Index.Box className="modal-close-btn" onClick={handleClose}>
                      <img
                        src={PagesIndex.Svg.closeblack}
                        className="admin-modal-close-icon"
                        alt="Close"
                      />
                    </Index.Box> */}
                                </Index.Box>
                                <Index.Box
                                    className="admin-modal-hgt-scroll cus-scrollbar"
                                    id="scrollDiv"
                                >

                                    {/*                   
                    <Index.Box className="admin-input-box admin-modal-input-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Home Category Name<span className="astrick-sing">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          name="name"
                          fullWidth
                          id="name"
                          className="admin-form-control"
                          placeholder="Enter Home Category Name"
                          onChange={handleChange}
                          value={values?.name}
                          error={errors.name && touched.name}
                          inputProps={{ maxLength: 40 }}
                        />
                        {touched.name && errors.name && (
                          <PagesIndex.FormHelperText className="admin-error-text">
                            {errors.name}
                          </PagesIndex.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Box> */}
                                    <Index.Box className="admin-input-box admin-add-movie-input-box input-complete-box">
                                        <Index.FormHelperText className="admin-form-lable">
                                            Select Songs<span className="astrick-sing">*</span>
                                        </Index.FormHelperText>
                                        <Index.Box className="admin-form-group">
                                            <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                                <Index.Autocomplete
                                                    className="admin-auto-complete-filed"
                                                    options={movieData || []}
                                                    id="genreCategory"
                                                    multiple
                                                    disableCloseOnSelect
                                                    closeText={""}
                                                    openText={""}
                                                    clearText={""}
                                                    value={
                                                        values?.songs
                                                            ? movieData.filter((data) =>
                                                                values?.songs?.some(
                                                                    (cat) => cat === data?._id
                                                                )
                                                            )
                                                            : []
                                                    }
                                                    onChange={(e, selectedOptions) => {
                                                        setFieldValue(
                                                            "songs",
                                                            selectedOptions.map((option) => option._id)
                                                        );

                                                    }}
                                                    getOptionLabel={(option) => option?.title}
                                                    renderOption={(props, item) => (
                                                        <li {...props} key={item?._id}>
                                                            {item?.title}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <Index.TextField
                                                            name="movies"
                                                            fullWidth
                                                            className="admin-form-control"
                                                            placeholder={
                                                                values?.songs?.length
                                                                    ? ""
                                                                    : "Select Songs"
                                                            }
                                                            {...params}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                {touched.songs && errors.songs && (
                                                    <PagesIndex.FormHelperText className="admin-error-text">
                                                        {errors.songs}
                                                    </PagesIndex.FormHelperText>
                                                )}
                                            </Index.FormControl>
                                        </Index.Box>
                                    </Index.Box>
                                    {/* <Index.Box className="admin-input-box admin-add-news-input-box">
                      <Index.Box className="admin-news-check-main admin-form-lable">
                        <Index.Box className="admin-checkbox-main">
                          <BpCheckbox
                            checked={values?.vertical}
                            onChange={() => {
                              const newValue = !values.vertical;
                              setFieldValue("vertical", newValue);
                            }}
                            name="vote"
                          />
                          <Index.Typography className="admin-checkbox-lable">
                            Vertical
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box> */}

                                </Index.Box>
                                <Index.Box className="modal-footer">
                                    <Index.Box className="admin-modal-user-btn-flex">
                                        <Index.Box className="admin-discard-btn-main border-btn-main">
                                            <Index.Button
                                                className="admin-discard-user-btn border-btn"
                                                onClick={handleClose}
                                                disabled={buttonSpinner}
                                            >
                                                Discard
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="admin-save-btn-main btn-main-primary">
                                            <Index.Button
                                                className={`${id ? "update-btn" : "save-btn"} admin-save-user-btn btn-primary`}
                                                type="submit"
                                                disabled={buttonSpinner}
                                            >
                                                {buttonSpinner ? <PagesIndex.Spinner /> :
                                                    (<>
                                                        <img
                                                            src={PagesIndex.Svg.save}
                                                            className="admin-user-save-icon"
                                                            alt="Save"
                                                        />
                                                        <span>{id ? "Update" : "Add"}</span>
                                                    </>)
                                                }
                                            </Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </form>
                    </Index.Modal>

                    <PagesIndex.LogModal
                        openModal={openLogModal}
                        handleCloseModal={handleCloseLogModal}
                        data={idData}
                        api={PagesIndex.Api.GET_HOME_CATEGORY_ACTIVITY_LOG + "/" + idData}
                        setOpenLogModal={setOpenLogModal}
                    />

                    <PagesIndex.DeleteModal
                        openModal={openDelete}
                        handleCloseModal={handleCloseDelete}
                        data={"record"}
                        loading={deleteButtonSpinner}
                        handleFunction={handleDeleteCategory}
                    />
                    <PagesIndex.ChangeStatusModal
                        data={"record"}
                        statusButtonSpinner={statusButtonSpinner}
                        openStatusModal={openStatusModal}
                        handleCloseStatusModal={handleCloseStatusModal}
                        handleChangeStatus={handleStatus}
                    />
                </>
            )}
        </PagesIndex.Formik>
    );
}

const ReorderableRow = ({ id, children, index, moveRow }) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: DraggableTypes.REORDERABLE_ROW,
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: DraggableTypes.REORDERABLE_ROW,
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <Index.TableRow
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            style={{ opacity }}
            ref={ref}
        >
            {children}
        </Index.TableRow>
    );
};
