import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./homeSlider.css";
import "./homeSlider.responsive.css";
import { useNavigate } from "react-router-dom/dist";
import PropTypes from "prop-types";

const IOSSwitch = Index.styled((props) => (
    <Index.Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 3,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 14,
        height: 14,
        boxShadow: "none",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

// for modal design
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const SongSlider = () => {
    const navigate = useNavigate();
    const { adminDetails } = PagesIndex.useSelector(
        (state) => state.AdminReducer
    );
    const [pageCount, setPageCount] = useState(0)
    const [openDelete, setOpenDelete] = useState(false);
    const [id, setId] = useState("");
    const [viewData, setViewData] = useState()
    const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
    const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false)
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openLogModal, setOpenLogModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);

    const handleCloseLogModal = () => setOpenLogModal(false);
    const handleOpenLogModal = (id) => {
        setOpenLogModal(true);
        setViewData(id)
    };
    const handleCloseViewModal = () => {
        setOpenViewModal(false)
    }

    const handleOpenViewModal = (data) => {
        setOpenViewModal(true)
        setViewData(data)
    }
    const handleOpenDelete = (id) => {
        setOpenDelete(true);
        setId(id);
    };
    const handleCloseDelete = () => {
        setId("");
        setOpenDelete(false);
    };

    const handleGetNews = (searchTerm, pageNo, dataPerPage, tabNo) => {
        const data = {
            page: pageNo ?? page,
            rowsPerPage: 10,
            search: searchTerm ?? search,
        }
        setLoading(true);
        PagesIndex.apiPostHandler(PagesIndex.Api.SLIDER_SONG_IMAGE_GET_ALL, data).then(
            (res) => {
                setLoading(false);
                if (res.status == 200) {
                    setPageCount(res?.pagination?.totalPages)
                    setPage(res?.pagination?.currentPage)
                    setFilteredData(res.data);
                } else {
                    setLoading(false);
                    PagesIndex.toasterError(res?.message);
                    setFilteredData([]);
                }
            }
        );
    }


    useEffect(() => {
        handleGetNews();
    }, []);
    const { page, tabValue, search, setPage, handleSearchChange, handlePageChange, handleTabChange } = PagesIndex.useDebounceHook(handleGetNews, setFilteredData);


    const handleNewsDelete = () => {
        setDeleteButtonSpinner(true);
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(PagesIndex.Api.SLIDER_SONG_IMAGE_DELETE, urlEncoded).then(
            (res) => {
                setDeleteButtonSpinner(false);
                if (res.status === 200) {
                    handleCloseDelete();
                    handleGetNews();
                    PagesIndex.toasterSuccess(res?.message);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            }
        );
    };
    const handleOpenStatusModal = (id) => {
        setId(id);
        setOpenStatusModal(true);
    };
    const handleCloseStatusModal = () => {
        setId("");
        setOpenStatusModal(false);
    };
    const handleStatus = () => {
        setStatusButtonSpinner(true);
        const urlEncoded = new URLSearchParams();
        urlEncoded.append("id", id);
        PagesIndex.apiPostHandler(PagesIndex.Api.SLIDER_SONG_IMAGE_ACTIVE_DEACTIVE, urlEncoded).then(
            (res) => {
                setStatusButtonSpinner(false);
                if (res.status === 200) {
                    handleGetNews();
                    handleCloseStatusModal();
                    PagesIndex.toasterSuccess(res?.message);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            }
        );
    };
    const renderTableRows = () => {
        return filteredData?.map((data) => {

            return (
                <Index.TableRow
                    sx={{
                        "&:last-child td, &:last-child th": {
                            border: 0,
                        },
                    }}
                    key={data?._id}
                >
                    {console.log(data)}
                    <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                    >
                        <Index.Typography className="admin-table-data-text">
                            <Index.Tooltip
                                title={data?.sliderTitle}
                                arrow
                                placement="top"
                                className="admin-tooltip admin-news-tooltip"
                            >
                                <Index.Typography className="admin-table-data-text"> {data?.sliderTitle}</Index.Typography>
                            </Index.Tooltip>
                        </Index.Typography>
                    </Index.TableCell>

                    {/* <Index.TableCell component="td" variant="td" className="table-td">
                        <Index.Typography className="admin-table-data-text">
                            {" "}
                            {data?.type ? data?.type : ''}
                        </Index.Typography>
                    </Index.TableCell> */}
                    <Index.TableCell component="td" variant="td" className="table-td">
                        <Index.Typography className="admin-table-data-text">
                            {" "}
                          {data?.songId?.title||'-'}
                        </Index.Typography>
                    </Index.TableCell>
                    <Index.TableCell component="td" variant="td" className="table-td">
                        <Index.Typography className="admin-table-data-text">
                            {" "}
                            {data?.sliderPosition ? data?.sliderPosition : ''}
                        </Index.Typography>
                    </Index.TableCell>
                    <Index.TableCell component="td" variant="td" className="table-td">
                        <Index.Typography className="admin-table-data-text">
                            {" "}
                            {data?.createdAt
                                ? `${PagesIndex.moment(
                                    data?.createdAt
                                )?.format("DD/MM/YYYY hh:mm A")}`
                                : "-"}
                        </Index.Typography>
                    </Index.TableCell>
                    <Index.TableCell
                        component="td"
                        variant="td"
                        align="right"
                        className="table-td"
                    >
                        <Index.Box className="admin-table-data-btn-flex ">
                            {/* {adminDetails?.roleId?.permissions?.includes("videos_edit") && (
                                <Index.Tooltip
                                    title={data?.isAdsPlay ? "Ads Show" : "Ads Hidden"}
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                >
                                    <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => handleOpenAd(data?._id)}
                                    >
                                        {data?.isAdsPlay ? (
                                            <img
                                                src={PagesIndex.Svg.advertise}
                                                className="admin-icon"
                                                alt="block ad"
                                            ></img>
                                        ) : (
                                            <img
                                                src={PagesIndex.Svg.blockad}
                                                className="admin-icon"
                                                alt="show ad"
                                            ></img>
                                        )}
                                    </Index.Button>
                                </Index.Tooltip>
                            )} */}
                            {/* <Index.Tooltip
                                title={data?.notiFlag == true ? "ON" : "OFF"}
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                            >
                                <span
                                    className={
                                        data?.notiFlag == true ? "active noti-box" : "noti-box"
                                    }
                                >
                                    <img
                                        src={
                                            data?.notiFlag == true
                                                ? PagesIndex.Svg.notificationActive
                                                : PagesIndex.Svg.notification
                                        }
                                        className="admin-icons"
                                        alt="upload img"
                                    ></img>
                                </span>
                            </Index.Tooltip> */}

                            {(adminDetails?.roleId?.permissions?.includes("dashboard_slider_song_edit") ||
                                adminDetails?.roleId?.permissions?.includes("dashboard_slider_song_delete")) && (

                                    <Index.Tooltip
                                        title={data?.isActive ? "Active" : "Deactive"}
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                    >
                                        <Index.Box className="admin-switch-main">
                                            <Index.FormControlLabel
                                                control={<IOSSwitch sx={{ m: 1 }} />}
                                                className="admin-switch-lable"
                                                checked={data?.isActive}
                                                onChange={() => {
                                                    handleOpenStatusModal(data?._id);
                                                }}
                                            />
                                        </Index.Box>
                                    </Index.Tooltip>

                                )}
                            {adminDetails?.roleId?.permissions?.includes("dashboard_slider_song_edit") && (
                                <Index.Tooltip
                                    title="Edit"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                >
                                    <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => {
                                            navigate(`/admin/add-home-song-slider/${data?._id}`, {
                                                state: {
                                                    data: data,
                                                },
                                            });
                                        }}
                                    >
                                        <img
                                            src={PagesIndex.Svg.blueedit}
                                            className="admin-icon"
                                            alt="Edit"
                                        ></img>
                                    </Index.Button>
                                </Index.Tooltip>
                            )}
                            <Index.Tooltip
                                title="View"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                            >
                                <Index.Button
                                    className="admin-table-data-btn"
                                    onClick={() => {
                                        handleOpenViewModal(data)

                                    }}
                                >
                                    <img
                                        src={PagesIndex.Svg.yelloweye}
                                        className="admin-icon"
                                        alt="View"
                                    ></img>
                                </Index.Button>
                            </Index.Tooltip>

                            {/* {adminDetails?.roleId?.permissions?.includes("videos_log") && (
                                <Index.Tooltip
                                    title="Log"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                >
                                    <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => handleOpenLogModal(data?._id)}
                                    >
                                        <img
                                            src={PagesIndex.Svg.logicon}
                                            className="admin-icon"
                                            alt="Log"
                                        ></img>
                                    </Index.Button>
                                </Index.Tooltip>
                            )} */}

                            {adminDetails?.roleId?.permissions?.includes("dashboard_slider_song_delete") && (
                                <Index.Tooltip
                                    title="Delete"
                                    arrow
                                    placement="bottom"
                                    className="admin-tooltip"
                                >
                                    <Index.Button
                                        className="admin-table-data-btn"
                                        onClick={() => handleOpenDelete(data?._id)}
                                    >
                                        <img
                                            src={PagesIndex.Svg.trash}
                                            className="admin-icon"
                                            alt="Delete"
                                        ></img>
                                    </Index.Button>
                                </Index.Tooltip>
                            )}
                        </Index.Box>
                    </Index.TableCell>
                </Index.TableRow>
            );
        });
    };

    const renderContent = () => {
        if (filteredData?.length) {
            return renderTableRows();
        } else {
            return <PagesIndex.DataNotFound />;
        }
    };

  
    return (
        <>
            <Index.Box className="admin-dashboard-content admin-user-list-content">
                <Index.Box className="admin-user-list-flex admin-page-title-main">
                    <Index.Typography
                        className="admin-page-title admin-user-list-page-title"
                        component="h2"
                        variant="h2"
                    >
                        Song Slider List
                    </Index.Typography>
                    <Index.Box className="admin-userlist-btn-flex">
                        <Index.Box className="admin-search-main">
                            <Index.Box className="admin-search-box">
                                <Index.Box className="admin-form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="admin-form-control"
                                        placeholder="Search"
                                        // value={searchValue}
                                        onChange={handleSearchChange}
                                    />
                                    <img
                                        src={PagesIndex.Svg.search}
                                        className="admin-search-grey-img admin-icon"
                                        alt="search"
                                    ></img>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        {adminDetails?.roleId?.permissions?.includes("dashboard_slider_song_add") && (
                            <Index.Box className="admin-adduser-btn-main btn-main-primary">
                                <Index.Button
                                    className="admin-adduser-btn btn-primary"
                                    onClick={() => navigate("/admin/add-home-song-slider")}
                                >
                                    <img
                                        src={PagesIndex.Svg.plus}
                                        className="admin-plus-icon"
                                        alt="plus"
                                    />
                                    <span>Add Slider Song</span>
                                </Index.Button>
                            </Index.Box>
                        )}
                    </Index.Box>
                </Index.Box>

            </Index.Box>

            <Index.Box className="common-card">
                {loading ? (
                    <PagesIndex.Loader col={5} />
                ) : (<>
                    <Index.Box className={`admin-news-table-main page-table-main`}>
                        <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                        >
                            <Index.Table aria-label="simple table" className="table">
                                <Index.TableHead className="table-head">
                                    <Index.TableRow className="table-row">
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="2%"
                                        >
                                             Title
                                        </Index.TableCell>
                                        {/* <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="2%"
                                        >
                                            Category
                                        </Index.TableCell> */}
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="2%"
                                        >
                                            Song Name
                                        </Index.TableCell>
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="2%"
                                        >
                                            Slider Position
                                        </Index.TableCell>
                                        <Index.TableCell
                                            component="th"
                                            variant="th"
                                            className="table-th"
                                            width="1%"
                                        >
                                            Created Date & Time
                                        </Index.TableCell>

                                        {(adminDetails?.roleId?.permissions?.includes(
                                            "dashboard_slider_edit"
                                        ) ||
                                            adminDetails?.roleId?.permissions?.includes(
                                                "dashboard_slider_edit"
                                            )) && (
                                                <Index.TableCell
                                                    component="th"
                                                    variant="th"
                                                    className="table-th"
                                                    width="2%"
                                                    align="right"
                                                >
                                                    Action
                                                </Index.TableCell>
                                            )}
                                    </Index.TableRow>
                                </Index.TableHead>

                                <Index.TableBody className="table-body">

                                    {renderContent()}
                                </Index.TableBody>

                            </Index.Table>

                        </Index.TableContainer>
                    </Index.Box>
                    <Index.Box className="admin-pagination-main">
                        {filteredData.length > 10 ? (
                            <Index.Pagination
                                page={page}
                                count={pageCount}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                                className="admin-pagination"
                            />
                        ) : (
                            ""
                        )}
                    </Index.Box>
                </>)}
               
                <PagesIndex.LogModal
                    openModal={openLogModal}
                    handleCloseModal={handleCloseLogModal}
                    data={viewData}
                    api={PagesIndex.Api.GET_NEWS_ACTIVITY_LOG + "/" +viewData}
                    setOpenLogModal={setOpenLogModal}
                     />

                <PagesIndex.DeleteModal
                    openModal={openDelete}
                    handleCloseModal={handleCloseDelete}
                    data={"record"}
                    loading={deleteButtonSpinner}
                    handleFunction={handleNewsDelete}
                />
                <PagesIndex.ChangeStatusModal
                    data={"record"}
                    statusButtonSpinner={statusButtonSpinner}
                    openStatusModal={openStatusModal}
                    handleCloseStatusModal={handleCloseStatusModal}
                    handleChangeStatus={handleStatus}
                />
                <PagesIndex.SliderViewModal
                    openModal={openViewModal}
                    handleCloseViewModal={handleCloseViewModal}
                    data={viewData}
                />
            </Index.Box>

        </>
    );
};

export default SongSlider;

SongSlider.propTypes = {
    filteredData: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    dataLength: PropTypes.number.isRequired,
    handleGetNews: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    valueTabs: PropTypes.any.isRequired, // Adjust the type according to your requirement
    loading: PropTypes.bool.isRequired,
};
