import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";

//#start region Admin
import { useSelector } from "react-redux";
import ForgotPassword from "../component/admin/auth/forgotPassword/ForgotPassword";
import Login from "../component/admin/auth/login/Login";
import Otp from "../component/admin/auth/otp/Otp";
import ResetPassword from "../component/admin/auth/resetPassword/ResetPassword";
import SignIn from "../component/admin/auth/signIn/SignIn";
import ComingSoon from "../component/common/comingSoon/ComingSoon.js";
import WebView from "../component/webView/WebView.js";
import AccountLayout from "../container/admin/pages/accountLayout/AccountLayout";
import AdZoneDetails from "../container/admin/pages/adZoneDetails/AdZoneDetails.js";
import AdminLayOut from "../container/admin/pages/adminLayout/AdminLayOut";
import AddAdsManagement from "../container/admin/pages/adsManagement/AddAdsManagement.js";
import AdsManagement from "../container/admin/pages/adsManagement/AdsManagement.js";
import ViewAdsManagement from "../container/admin/pages/adsManagement/ViewAdsManagement.js";
import AddAdvertiser from "../container/admin/pages/advertiser/AddAdvertiser.js";
import AdvertiseCategory from "../container/admin/pages/advertiser/AdvertiseCategory.js";
import AdvertiseSubCategory from "../container/admin/pages/advertiser/AdvertiseSubCategory.js";
import Advertiser from "../container/admin/pages/advertiser/Advertiser.js";
import MediaPlatform from "../container/admin/pages/advertiser/MediaPlatform.js";
import ViewAdvertiser from "../container/admin/pages/advertiser/ViewAdvertiser.js";
import Advertising from "../container/admin/pages/advertising/Advertising.js";
import CastAndCrewDetails from "../container/admin/pages/castAndCrewDetails/CastAndCrewDetails";
import CategoryManagement from "../container/admin/pages/categoryManagement/CategoryManagement";
import ChannelManagement from "../container/admin/pages/channelManagement/ChannelManagement.js";
import CMSManagement from "../container/admin/pages/cmsManagement/CMSManagement";
import CreativeManagement from "../container/admin/pages/creativeManagement/CreativeManagement.js";
import DashBoard from "../container/admin/pages/dashBoard/DashBoard";
import DistrictDetails from "../container/admin/pages/district/districtDetails.js";
import ElementManagement from "../container/admin/pages/elementManagement/ElementManagement.js";
import Episode from "../container/admin/pages/episode/Episode";
import ViewEpisode from "../container/admin/pages/episode/ViewEpisode";
import EventManagement from "../container/admin/pages/eventManagement/EventManagement.js";
import FaqManagement from "../container/admin/pages/faqManagement/FaqManagement";
import FeedBack from "../container/admin/pages/feedBack/FeedBack.js";
import HomeCategoryManagement from "../container/admin/pages/homeCategoryManagement/HomeCategoryManagement.js";
import ManagementUser from "../container/admin/pages/manageUser/ManagementUser.js";
import ViewUser from "../container/admin/pages/manageUser/ViewUser.js";
import MovieAndWebSeriesTagMaster from "../container/admin/pages/movieAndWebSeriesTagMaster/MovieAndWebSeriesTagMaster.js";
import AddMovie from "../container/admin/pages/movies/AddMovie.js";
import MovieActivityLog from "../container/admin/pages/movies/MovieActivityLog..js";
import MovieCategoryManagement from "../container/admin/pages/movies/MovieCategoryManagement.js";
import Movies from "../container/admin/pages/movies/Movies";
import ViewMovie from "../container/admin/pages/movies/ViewMovie";
import NCShotsScheduleManagement from "../container/admin/pages/ncShotsScheduleManagement/NCShotsScheduleManagement.js";
import AddNews from "../container/admin/pages/news/AddNews.js";
import NewsActivityLog from "../container/admin/pages/news/NewsActivityLog.js";
import NewsTab from "../container/admin/pages/news/NewsTab.js";
import ViewNews from "../container/admin/pages/news/ViewNews.js";
import PhoneDrectory from "../container/admin/pages/phoneDirectory/PhoneDrectory.js";
import ProfileDetails from "../container/admin/pages/profileDetails/ProfileDetails";
import ProgramManagement from "../container/admin/pages/programManagement/ProgramManagement.js";
import ProgramType from "../container/admin/pages/programType/ProgramType.js";
import ReportsAndAnalytics from "../container/admin/pages/reportsAndAnalytics/ReportsAndAnalytics.js";
import RoleManagement from "../container/admin/pages/roleManagement/RoleManagement";
import AddSalesOrder from "../container/admin/pages/salesOrderManagment/AddSalesOrder.js";
import AddSalesSubOrder from "../container/admin/pages/salesOrderManagment/AddSalesSubOrder.js";
import SalesOrderList from "../container/admin/pages/salesOrderManagment/SalesOrderList.js";
import SalesSubOrderList from "../container/admin/pages/salesOrderManagment/SalesSubOrderList.js";
import AddScheduleTable from "../container/admin/pages/schduleManagement/AddScheduleTable.js";
import SchduleManagement from "../container/admin/pages/schduleManagement/SchduleManagement.js";
import ViewScheduleTable from "../container/admin/pages/schduleManagement/ViewScheduleTable.js";
import Seasons from "../container/admin/pages/seasons/Seasons";
import ViewSeasons from "../container/admin/pages/seasons/ViewSeasons";
import SiteSettings from "../container/admin/pages/siteSettings/SiteSettings";
import AddSlot from "../container/admin/pages/slotManagement/AddSlot.js";
import Slot from "../container/admin/pages/slotManagement/Slots.js";
import SponsorActivityLog from "../container/admin/pages/sponserManagement/SponsorActivityLog.js";
import SponsorManagement from "../container/admin/pages/sponserManagement/SponsorManagement.js";
import ViewSponser from "../container/admin/pages/sponserManagement/ViewSponser.js";
import StateManagement from "../container/admin/pages/stateManagement/StateManagement";
import AdminActivityLog from "../container/admin/pages/subAdminManagement/AdminActivityLog.js";
import SubAdminManagement from "../container/admin/pages/subAdminManagement/SubAdminManagement";
import UserLoginLog from "../container/admin/pages/subAdminManagement/UserLoginLog.js";
import Support from "../container/admin/pages/support/Support.js";
import TagMaster from "../container/admin/pages/tagMaster/TagMaster";
import UserPostVideo from "../container/admin/pages/userPostVideo/UserPostVideo.js";
import VotersList from "../container/admin/pages/votersList/VotersList.js";
import ViewWebSeries from "../container/admin/pages/webSeries/ViewWebSeries";
import WebSeries from "../container/admin/pages/webSeries/WebSeries";
import WebsiteScheduleManagement from "../container/admin/pages/websiteScheduleManagement/WebsiteScheduleManagement.js";
import ZoneDetails from "../container/admin/pages/zoneDetails/ZoneDetails";
import PrivateRoutes from "./PrivateRoutes";
import UploadVideo from "../container/admin/pages/uploadVideo/UploadVideo.js";
import AgencyManagement from "../container/admin/pages/agencyManagement/AgencyManagement.js";
import InternalServerLinkManagement from "../container/admin/pages/internalServerLinkManagement/InternalServerLinkManagement.js";
import HomeSlider from "../container/admin/pages/homeSlider/HomeSlider.js";
import AddHomeSlider from "../container/admin/pages/homeSlider/AddHomeSlider.js";
import MusicCategory from "../container/admin/pages/musicManagment/MusicCategory.js";
import CastAndCrew from "../container/admin/pages/musicManagment/CastAndCrew.js";
import AddMusic from "../container/admin/pages/musicManagment/AddMusic.js";
import Music from "../container/admin/pages/musicManagment/Music.js";
import ViewMusic from "../container/admin/pages/musicManagment/ViewMusic.js";
import CategoryMovies from "../container/admin/pages/homeCategoryManagement/CategoryMovies.js";
import SongHomeCategory from "../container/admin/pages/homeCategoryManagement/SongHomeCategory.js";
import CategorySong from "../container/admin/pages/homeCategoryManagement/CategorySong.js";
import SongSlider from "../container/admin/pages/homeSlider/SongSlider.js";
import AddSongSlider from "../container/admin/pages/homeSlider/AddSongSlider.js";
import PagesIndex from "../container/PagesIndex.js";
import PageNotFound from "../component/common/pageNotFound/PageNotFound.js";
import AlbumList from "../container/admin/pages/albumAndPlaylist/AlbumList.js";
import AlbumSongList from "../container/admin/pages/albumAndPlaylist/AlbumSongList.js";
import Playlist from "../container/admin/pages/albumAndPlaylist/Playlist.js";
import PlayListSong from "../container/admin/pages/albumAndPlaylist/PlayListSongList.js";

//#endregion

function ErrorBoundary() {
  const error = useRouteError();
  console.log(error,'error');
  
  return (
    <div className="container">
      <h1>Oh Dang!!</h1> <p>{error?.data}</p>
    </div>
  );
}
export default function Routes() {

  const { adminDetails } = useSelector((state) => state.AdminReducer);
  const appRoutes = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/otp-verify",
      element: <Otp />,
    },
    {
      path: "/sign-in",
      element: <SignIn />,
    },
    {
      path: "/:path",
      element: <WebView />,
    },
    {
      path: "*",
      element:( <PageNotFound />),
    },
    {
      path: "/admin",
      element: (
        <PrivateRoutes>
        <AdminLayOut />
        </PrivateRoutes>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "/admin/comingSoon/:comingSoon",
          element: (
            <PrivateRoutes>
              <ComingSoon />
            </PrivateRoutes>
          ),
        },
        
        {
          path: "dashboard",
          element: adminDetails?.roleId?.permissions?.includes(
            "dashboard_view"
          ) && (
              <PrivateRoutes>
                <DashBoard />
              </PrivateRoutes>
            ),
        },
        {
          path: "account",
          element: (
            <PrivateRoutes>
              <AccountLayout />
            </PrivateRoutes>
          ),
        },
        {
          path: "cms/:path",
          element: adminDetails?.roleId?.permissions?.includes("cms_view") && (
            <PrivateRoutes>
              <CMSManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "phone-directory",
          element: adminDetails?.roleId?.permissions?.includes(
            "phone_directory_view"
          ) && (
              <PrivateRoutes>
                <PhoneDrectory />
              </PrivateRoutes>
            ),
        },
        {
          path: "subAdmin-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "admin_user_view"
          ) && (
              <PrivateRoutes>
                <SubAdminManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "user-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "admin_user_view"
          ) && (
              <PrivateRoutes>
                <UserLoginLog />
              </PrivateRoutes>
            ),
        },
        {
          path: "subAdmin-management/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "admin_user_log"
          ) && (
              <PrivateRoutes>
                <AdminActivityLog />
              </PrivateRoutes>
            ),
        },
        {
          path: "sponsor-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) && (
              <PrivateRoutes>
                <SponsorManagement />
              </PrivateRoutes>
            ),
        },
        // {
        //   path: "platform-management",
        //   element: adminDetails?.roleId?.permissions?.includes(
        //     "sponsor_view"
        //   ) && (
        //       <PrivateRoutes>
        //         <ChannelManagement />
        //       </PrivateRoutes>
        //     ),
        // },
        {
          path: "view-sponsor/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) && (
              <PrivateRoutes>
                <ViewSponser />
              </PrivateRoutes>
            ),
        },
        {
          path: "sponsor/sponsor-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) && (
              <PrivateRoutes>
                <SponsorActivityLog />
              </PrivateRoutes>
            ),
        },
        {
          path: "manage-user",
          element: adminDetails?.roleId?.permissions?.includes("user_view") && (
            <PrivateRoutes>
              <ManagementUser />
            </PrivateRoutes>
          ),
        },
        // {
        //   path: "state-management",
        //   element: adminDetails?.roleId?.permissions?.includes(
        //     "state_view"
        //   ) && (
        //       <PrivateRoutes>
        //         <StateManagement />
        //       </PrivateRoutes>
        //     ),
        // },
        {
          path: "category-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "category_view"
          ) && (
              <PrivateRoutes>
                <CategoryManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "faq-management",
          element: adminDetails?.roleId?.permissions?.includes("faq_view") && (
            <PrivateRoutes>
              <FaqManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "role-management",
          element: adminDetails?.roleId?.permissions?.includes("role_view") && (
            <PrivateRoutes>
              <RoleManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "site-settings",
          element: adminDetails?.roleId?.permissions?.includes(
            "site_setting_view"
          ) && (
              <PrivateRoutes>
                <SiteSettings />
              </PrivateRoutes>
            ),
        },
        {
          path: "profile-details",
          element: (
            <PrivateRoutes>
              <ProfileDetails />
            </PrivateRoutes>
          ),
        },
        {
          path: "zone-details",
          element: adminDetails?.roleId?.permissions?.includes("zone_view") && (
            <PrivateRoutes>
              <ZoneDetails />
            </PrivateRoutes>
          ),
        },
        {
          path: "ad-zone-details",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_zone_view"
          ) && (
              <PrivateRoutes>
                <AdZoneDetails />
              </PrivateRoutes>
            ),
        },
        {
          path: "district",
          element: adminDetails?.roleId?.permissions?.includes(
            "district_view"
          ) && (
              <PrivateRoutes>
                <DistrictDetails />
              </PrivateRoutes>
            ),
        },
        {
          path: "tag-master",
          element: adminDetails?.roleId?.permissions?.includes("tag_view") && (
            <PrivateRoutes>
              <TagMaster />
            </PrivateRoutes>
          ),
        },
        {
          path: "movie-tag-master",
          element: adminDetails?.roleId?.permissions?.includes(
            "movie_tag_view"
          ) && (
              <PrivateRoutes>
                <MovieAndWebSeriesTagMaster />
              </PrivateRoutes>
            ),
        },
        {
          path: "cast-and-crew-master",
          element: (
            <PrivateRoutes>
              <CastAndCrewDetails />
            </PrivateRoutes>
          ),
        },
        {
          path: "movies",
          element: adminDetails?.roleId?.permissions?.includes(
            "movies_view"
          ) && (
              <PrivateRoutes>
                <Movies />
              </PrivateRoutes>
            ),
        },
        {
          path: "movie-category-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "movie_category_view"
          ) && (
              <PrivateRoutes>
                <MovieCategoryManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "movies/movie-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "movies_log"
          ) && (
              <PrivateRoutes>
                <MovieActivityLog />
              </PrivateRoutes>
            ),
        },
        {
          path: "videos",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_view"
          ) && (
              <PrivateRoutes>
                <NewsTab />
              </PrivateRoutes>
            ),
        },
        {
          path: "videos/video-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_view"
          ) && (
              <PrivateRoutes>
                <NewsActivityLog />
              </PrivateRoutes>
            ),
        },
        {
          path: "home-category-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "home_category_view"
          ) && (
              <PrivateRoutes>
                <HomeCategoryManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "category-movies",
          element: adminDetails?.roleId?.permissions?.includes(
            "category_movies_view"
          ) && (
              <PrivateRoutes>
                <CategoryMovies />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-video",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_add"
          ) && (
              <PrivateRoutes>
                <AddNews />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-video/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_edit"
          ) && (
              <PrivateRoutes>
                <AddNews />
              </PrivateRoutes>
            ),
        },
        {
          path: "event-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "event_view"
          ) && (
              <PrivateRoutes>
                <EventManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "category",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_category_view"
          ) && (
              <PrivateRoutes>
                <AdvertiseCategory />
              </PrivateRoutes>
            ),
        },
        {
          path: "sub-category",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_sub_category_view"
          ) && (
              <PrivateRoutes>
                <AdvertiseSubCategory />
              </PrivateRoutes>
            ),
        },
        {
          path: "media-platform",
          element: adminDetails?.roleId?.permissions?.includes(
            "media_platform_view"
          ) && (
              <PrivateRoutes>
                <MediaPlatform />
              </PrivateRoutes>
            ),
        },
        {
          path: "clients",
          element: adminDetails?.roleId?.permissions?.includes(
            "clients_view"
          ) && (
              <PrivateRoutes>
                <Advertiser />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-client",
          element: adminDetails?.roleId?.permissions?.includes(
            "clients_add"
          ) && (
              <PrivateRoutes>
                <AddAdvertiser />
              </PrivateRoutes>
            ),
        },
        {
          path: "view-client/:id",
          element: (
            <PrivateRoutes>
              <ViewAdvertiser />
            </PrivateRoutes>
          ),
        },
        {
          path: "edit-client/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "clients_edit"
          ) && (
              <PrivateRoutes>
                <AddAdvertiser />
              </PrivateRoutes>
            ),
        },
        {
          path: "web-series",
          element: adminDetails?.roleId?.permissions?.includes(
            "series_view"
          ) && (
              <PrivateRoutes>
                <WebSeries />
              </PrivateRoutes>
            ),
        },
        {
          path: "seasons",
          element: adminDetails?.roleId?.permissions?.includes(
            "season_view"
          ) && (
              <PrivateRoutes>
                <Seasons />
              </PrivateRoutes>
            ),
        },
        {
          path: "episode",
          element: adminDetails?.roleId?.permissions?.includes(
            "episode_view"
          ) && (
              <PrivateRoutes>
                <Episode />
              </PrivateRoutes>
            ),
        },
        {
          path: "view-web-series/:id",
          element: (
            <PrivateRoutes>
              <ViewWebSeries />
            </PrivateRoutes>
          ),
        },
        {
          path: "view-season",
          element: adminDetails?.roleId?.permissions?.includes("role_view") && (
            <PrivateRoutes>
              <ViewSeasons />
            </PrivateRoutes>
          ),
        },
        {
          path: "view-episode/:id",
          element: adminDetails?.roleId?.permissions?.includes("role_view") && (
            <PrivateRoutes>
              <ViewEpisode />
            </PrivateRoutes>
          ),
        },
        {
          path: "view-movie/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "movies_view"
          ) && (
              <PrivateRoutes>
                <ViewMovie />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-movie",
          element: (adminDetails?.roleId?.permissions?.includes("movies_add") ||
            adminDetails?.roleId?.permissions?.includes("movies_edit")) && (
              <PrivateRoutes>
                <AddMovie />
              </PrivateRoutes>
            ),
        },
        {
          path: "view-video/:id",
          element: (
            <PrivateRoutes>
              <ViewNews />
            </PrivateRoutes>
          ),
        },
        {
          path: "feedback",
          element: adminDetails?.roleId?.permissions?.includes("role_view") && (
            <PrivateRoutes>
              <FeedBack />
            </PrivateRoutes>
          ),
        },
        {
          path: "support",
          element: adminDetails?.roleId?.permissions?.includes(
            "support_view"
          ) && (
              <PrivateRoutes>
                <Support />
              </PrivateRoutes>
            ),
        },
        {
          path: "advertising",
          element: adminDetails?.roleId?.permissions?.includes(
            "advertising_view"
          ) && (
              <PrivateRoutes>
                <Advertising />
              </PrivateRoutes>
            ),
        },
        {
          path: "view-user/:id",
          element: adminDetails?.roleId?.permissions?.includes("user_view") && (
            <PrivateRoutes>
              <ViewUser />
            </PrivateRoutes>
          ),
        },
        {
          path: "add-ads-management",
          element: adminDetails?.roleId?.permissions?.includes("ads_add") && (
            <PrivateRoutes>
              <AddAdsManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "edit-ads-management/:id",
          element: adminDetails?.roleId?.permissions?.includes("ads_edit") && (
            <PrivateRoutes>
              <AddAdsManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "ads-management",
          element: adminDetails?.roleId?.permissions?.includes("ads_view") && (
            <PrivateRoutes>
              <AdsManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "inventory-ads-management",
          element: adminDetails?.roleId?.permissions?.includes("ads_view") && (
            <PrivateRoutes>
              <AdsManagement />
            </PrivateRoutes>
          ),
        },
        {
          path: "view-ads-management/:id",
          element: adminDetails?.roleId?.permissions?.includes("ads_view") && (
            <PrivateRoutes>
              <ViewAdsManagement />
            </PrivateRoutes>
          ),
        },
        // {
        //   path: "user-posted-videos",
        //   element: adminDetails?.roleId?.permissions?.includes(
        //     "user_posted_videos_view"
        //   ) && (
        //       <PrivateRoutes>
        //         <UserPostVideo />
        //       </PrivateRoutes>
        //     ),
        // },
        {
          path: "program-type-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "program_type_view"
          ) && (
              <PrivateRoutes>
                <ProgramType />
              </PrivateRoutes>
            ),
        },
        {
          path: "program-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "program_view"
          ) && (
              <PrivateRoutes>
                <ProgramManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "report-and-analytics",
          element: adminDetails?.roleId?.permissions?.includes(
            "report_analytics_view"
          ) && (
              <PrivateRoutes>
                <ReportsAndAnalytics />
              </PrivateRoutes>
            ),
        },
        {
          path: "element-management/:page",
          element: adminDetails?.roleId?.permissions?.includes(
            "element_view"
          ) && (
              <PrivateRoutes>
                <ElementManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-sales-order",
          element: true && (
            <PrivateRoutes>
              <AddSalesOrder />
            </PrivateRoutes>
          ),
        },
        {
          path: "add-sales-sub-order/:id",
          element: true && (
            <PrivateRoutes>
              <AddSalesSubOrder />
            </PrivateRoutes>
          ),
        },
        {
          path: "creative-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "creative_view"
          ) && (
              <PrivateRoutes>
                <CreativeManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "slot-management/:page",
          element: adminDetails?.roleId?.permissions?.includes("slot_view") && (
            <PrivateRoutes>
              <Slot />
            </PrivateRoutes>
          ),
        },
        {
          path: "slot-schedule-management/:page",
          element: adminDetails?.roleId?.permissions?.includes(
            "slot_schedule_view"
          ) && (
              <PrivateRoutes>
                <SchduleManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-slot-schedule/:page/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "slot_schedule_view"
          ) && (
              <PrivateRoutes>
                <AddScheduleTable />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-slot/:page",
          element: adminDetails?.roleId?.permissions?.includes("slot_add") && (
            <PrivateRoutes>
              <AddSlot />
            </PrivateRoutes>
          ),
        },
        {
          path: "view-slot-schedule/:page/:id",
          element: adminDetails?.roleId?.permissions?.includes("slot_add") && (
            <PrivateRoutes>
              <ViewScheduleTable />
            </PrivateRoutes>
          ),
        },
        {
          path: "edit-slot/:page/:id",
          element: adminDetails?.roleId?.permissions?.includes("slot_edit") && (
            <PrivateRoutes>
              <AddSlot />
            </PrivateRoutes>
          ),
        },
        {
          path: "sales-order-list",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_view"
          ) && (
              <PrivateRoutes>
                <SalesOrderList />
              </PrivateRoutes>
            ),
        },
        {
          path: "sales-sub-order-list/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_view"
          ) && (
              <PrivateRoutes>
                <SalesSubOrderList />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-sales-order",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_add"
          ) && (
              <PrivateRoutes>
                <AddSalesOrder />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-sales-order/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_edit"
          ) && (
              <PrivateRoutes>
                <AddSalesOrder />
              </PrivateRoutes>
            ),
        },
        {
          path: "view-slot",
          element: adminDetails?.roleId?.permissions?.includes("slot_edit") && (
            <PrivateRoutes>
              <AddSalesOrder />
            </PrivateRoutes>
          ),
        },
        {
          path: "voter-list",
          element: true && (
            <PrivateRoutes>
              <VotersList />
            </PrivateRoutes>
          ),
        },
        {
          path: "website-schedule-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "website_ad_view"
          ) && (
              <PrivateRoutes>
                <WebsiteScheduleManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "ncshots-schedule-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "nc_slot_schedule_view"
          ) && (
              <PrivateRoutes>
                <NCShotsScheduleManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "upload-video",
          element: (adminDetails?.roleId?.permissions?.includes(
            "upload_video_view"
          ) ||
            adminDetails?.roleId?.permissions?.includes(
              "upload_video_view_all"
            )) && (
              <PrivateRoutes>
                <UploadVideo />
              </PrivateRoutes>
            ),
        },
        {
          path: "agency-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_category_view"
          ) && (
              <PrivateRoutes>
                <AgencyManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "server-link-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_category_view"
          ) && (
              <PrivateRoutes>
                <InternalServerLinkManagement />
              </PrivateRoutes>
            ),
        },
        {
          path: "home-slider",
          element: adminDetails?.roleId?.permissions?.includes(
            "dashboard_slider_view"
          ) && (
              <PrivateRoutes>
                <HomeSlider />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-home-slider",
          element: adminDetails?.roleId?.permissions?.includes(
            "dashboard_slider_add"
          ) && (
              <PrivateRoutes>
                <AddHomeSlider />
              </PrivateRoutes>
            ),
        },
        {
          path: "add-home-slider/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "dashboard_slider_edit"
          ) &&(
            <PrivateRoutes>
              <AddHomeSlider />
            </PrivateRoutes>
          ),
        },
 
// Music routes

{
  path: "song-category-management",
  element: adminDetails?.roleId?.permissions?.includes(
    "song_category_view"
  ) && (
      <PrivateRoutes>
        <MusicCategory />
      </PrivateRoutes>
    ),
},
{
  path: "song",
  element: adminDetails?.roleId?.permissions?.includes(
    "songs_view"
  ) && (
      <PrivateRoutes>
        <Music />
      </PrivateRoutes>
    ),
},
{
  path: "add-song",
  element: adminDetails?.roleId?.permissions?.includes(
    "songs_add"
  ) && (
      <PrivateRoutes>
        <AddMusic />
      </PrivateRoutes>
    ),
},
{
  path: "view-song/:id",
  element: adminDetails?.roleId?.permissions?.includes(
    "songs_view"
  ) && (
      <PrivateRoutes>
        <ViewMusic />
      </PrivateRoutes>
    ),
},
{
  path: "song-cast-and-crew-master",
  element: adminDetails?.roleId?.permissions?.includes(
    "song_artist_view"
  ) && (
      <PrivateRoutes>
        <CastAndCrew />
      </PrivateRoutes>
    ),
},


// Song home category 
{
  path: "home-song-category-management",
  element: adminDetails?.roleId?.permissions?.includes(
    "home_song_category_view"
  ) && (
      <PrivateRoutes>
        <SongHomeCategory />
      </PrivateRoutes>
    ),
},
{
  path: "category-song",
  element: adminDetails?.roleId?.permissions?.includes(
    "category_song_view"
  ) && (
      <PrivateRoutes>
        <CategorySong />
      </PrivateRoutes>
    ),
},
// song slider 
{
  path: "song-home-slider",
  element: adminDetails?.roleId?.permissions?.includes(
    "dashboard_slider_song_view"
  ) && (
      <PrivateRoutes>
        <SongSlider />
      </PrivateRoutes>
    ),
},
{
  path: "add-home-song-slider",
  element: adminDetails?.roleId?.permissions?.includes(
    "dashboard_slider_song_add"
  ) && (
      <PrivateRoutes>
        <AddSongSlider />
      </PrivateRoutes>
    ),
},
{
  path: "add-home-song-slider/:id",
  element: adminDetails?.roleId?.permissions?.includes(
    "dashboard_slider_song_edit"
  ) &&(
    <PrivateRoutes>
      <AddSongSlider />
    </PrivateRoutes>
  ),
},


// Album 
{
  path: "album-list",
  element: adminDetails?.roleId?.permissions?.includes(
    "album_list_view"
  ) && (
      <PrivateRoutes>
        <AlbumList />
      </PrivateRoutes>
    ),
},
{
  path: "album-song",
  element: adminDetails?.roleId?.permissions?.includes(
    "album_song_view"
  ) && (
      <PrivateRoutes>
        <AlbumSongList />
      </PrivateRoutes>
    ),
},
// playList
{
  path: "playlist-list",
  element: adminDetails?.roleId?.permissions?.includes(
    "playlist_view"
  ) && (
      <PrivateRoutes>
        <Playlist />
      </PrivateRoutes>
    ),
},
{
  path: "playlist-song",
  element: adminDetails?.roleId?.permissions?.includes(
    "playlist_song_view"
  ) && (
      <PrivateRoutes>
        <PlayListSong />
      </PrivateRoutes>
    ),
},

      ],
    },
  ];
  const router = createBrowserRouter(appRoutes);
  return <RouterProvider router={router} />;
}
