import React from 'react'
import Index from '../../../container/Index';
import PagesIndex from '../../../container/PagesIndex';

export const AlbumModal = ({ data, onClose, open ,type}) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
    };

    return (
        <Index.Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
        >
            <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner admin-album-view-modal"
            >
                <Index.Box className="admin-modal-header">
                    <Index.Typography
                        id="modal-modal-title"
                        className="admin-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        View Album
                    </Index.Typography>
                    <Index.Button
                        className="modal-close-btn"
                        onClick={onClose}
                    >
                        <img
                            src={PagesIndex.Svg.closeblack}
                            className="admin-modal-close-icon"
                            alt="Close"
                        />
                    </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                    <Index.Box className="admin-input-box admin-modal-input-box">
                        <Index.Box className="admin-profile-main ">
                            <img
                                src={
                                    data?.image
                                        ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.image}`
                                        : PagesIndex.Svg.noimgfound
                                }
                                className="admin-profile-img album-view-moadl-image"
                                alt="upload img"
                            ></img>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="news-content-main">
                        <Index.Typography className="news-name-text">
                            {data?.title}
                        </Index.Typography>
                       {type=='album'&& <Index.Box className="cast-flex">
                            <Index.Typography className="view-content-lable">
                                Cast:
                            </Index.Typography>
                            <Index.Box className="cast-and-crew-row">
                                {data?.singers?.map((data) => {
                                    return (
                                        <Index.Box className="cast-box" key={data?.castId?._id}>
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.castId?.image}`}
                                                className="admin-upload-cast-img"
                                                alt="upload img"
                                            ></img>
                                            <Index.Typography className="cast-name">
                                                {data?.castId?.name}
                                            </Index.Typography>
                                        </Index.Box>
                                    );
                                })}
                            </Index.Box>
                        </Index.Box>}
                    </Index.Box>

                </Index.Box>
            </Index.Box>
        </Index.Modal>
    )
}
