import React, { useEffect, useRef, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { imageSliderSchema, songSliderSchema } from "../../../../utils/validation/FormikValidation";

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    border: "1px solid #b2b3b3",
    width: 12,
    height: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "transparent",
    "&:before": {
        display: "block",
        width: 12,
        height: 12,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
        content: '""',
    },
    "input:hover ~ &": {
        backgroundColor: "transparent",
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Index.Checkbox
            sx={{
                "&:hover": { bgcolor: "transparent" },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            {...props}
        />
    );
}

export default function AddSongSlider() {
    const { adminDetails } = PagesIndex.useSelector(
        (state) => state.AdminReducer
    );
    const { id } = PagesIndex.useParams();
    const { state } = PagesIndex.useLocation();
    const navigate = PagesIndex.useNavigate();
    const [buttonSpinner, setButtonSpinner] = useState(false);
    const [internalServerLink, setInternalServerLink] = useState("");
    const [getAllMovie, setGetAllMovie] = useState([])
    const [image, setImage] = useState()

    const movieType = [
        { name: 'Movie', value: 'movie' },
        { name: 'Web Show', value: 'webseries' },
    ]

    const sliderPosition = [
        { name: 'Top', value: 'top' },
        { name: 'Middle', value: 'Middle' },
        { name: 'Bottom', value: 'Bottom' },
    ]

    let initialValues = {
     
        sliderTitle: id ? state?.data?.sliderTitle : "",
        sliderPosition: id ? state?.data?.sliderPosition : "",
        type: id ? state?.data?.type : "",
        image: id ? state?.data?.sliderImage : "",
        linkId: id ? state?.data?.songId._id : "",
        isEdit: !!id,
        defaultImage: false,
    };

    useEffect(() => {
        getSettingData();
    }, []);



    const handleGetMovies = (value) => {

        const urlencoded = new URLSearchParams();
        // urlencoded.append("type", id ? state?.data?.type : (value || 'movie'));
        PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_SONG).then(
            (res) => {
                if (res.status == 200) {

                    setGetAllMovie(res?.data)

                } else {

                    PagesIndex.toasterError(res?.message)
                }
            }
        );
    }
    useEffect(() => {
        handleGetMovies()
    }, [])


    const handleAddEditNews = (values) => {
        setButtonSpinner(true);

        const formdata = new FormData();
        if (id) {
            formdata.append("id", id);
        }
        if (values?.image) {
            formdata.append("sliderImage", values?.image);
        }
        formdata.append("songId", values?.linkId);

        formdata.append("type", values?.type);

        formdata.append("sliderPosition", values?.sliderPosition);

        formdata.append("sliderTitle", values?.sliderTitle);

        PagesIndex.apiPostHandler(
            PagesIndex.Api.SLIDER_SONG_IMAGE_ADD,
            formdata
        )
            .then((res) => {
                setButtonSpinner(false);

                if (res.status === 201 || res.status === 200) {
                    PagesIndex.toasterSuccess(res.message);
                    navigate("/admin/song-home-slider", {

                    });
                } else {
                    PagesIndex.toasterError(res.message);
                }
            })
            .catch((err) => {
                PagesIndex.toasterError(err.response.message);
            });
    };


    // for img
    const renderImage = (values) => {
        const isImageBlob = values.image instanceof Blob;
        let src;
        if (image) {
            src = URL.createObjectURL(image);
        } else if ( values?.image) {
            src = `${process.env.REACT_APP_IMAGE_ENDPOINT}${values.image}`;
        } 
        else {
            src = PagesIndex.Svg.fileupload;
        }

        return (
            <img
                src={src}
                className={src !== PagesIndex.Svg.fileupload ? "upload-img" : "upload-profile-img"}
                alt="upload img"
            />
        );
    };


    const getSettingData = () => {
        PagesIndex.apiGetHandler(PagesIndex.Api.GET_SETTING_DATA).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("internalLink", res?.data?.[0]?.internalUrl);
                setInternalServerLink(res?.data?.[0]?.internalUrl);
            }
        });
    };

    return (
        <>

            <Index.Box className="admin-dashboard-content admin-user-management-content">
                <Index.Box className="admin-user-list-flex admin-page-title-main">
                    <Index.Typography
                        className="admin-page-title admin-user-list-page-title"
                        component="h2"
                        variant="h2"
                    >
                        {id ? "Edit Slider Song" : "Add Slider Song"}
                    </Index.Typography>
                </Index.Box>
                <PagesIndex.Formik
                    enableReinitialize={true}
                    onSubmit={handleAddEditNews}
                    initialValues={initialValues}
                    validationSchema={songSliderSchema}

                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        handleBlur,
                        setFieldError,
                    }) => (
                        <>
                            <Index.Box className="common-card">
                                <form onSubmit={handleSubmit} noValidate="novalidate">
                                    <Index.Box className="grid-row">
                                        {/* <ScrollIntoView /> */}
                                        {console.log(errors)
                                        }
                                        <Index.Box className="grid-main">
                                            <Index.Box
                                                display="grid"
                                                gridTemplateColumns="repeat(12, 1fr)"
                                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                            >
                                  

                                                <Index.Box
                                                    gridColumn={{
                                                        xs: "span 12",
                                                        sm: "span 12",
                                                        md: "span 12",
                                                        lg: "span 12",
                                                    }}
                                                    className="grid-column"
                                                >
                                                    <Index.Box className="admin-input-box admin-add-news-input-box">
                                                        <Index.FormHelperText className="admin-form-lable">
                                                            Image Title
                                                            <span className="option-condition-text">
                                                                (Max character length: 500)
                                                            </span>
                                                        </Index.FormHelperText>
                                                        <Index.Box className="admin-form-group">
                                                            <Index.TextField
                                                                fullWidth
                                                                id="sliderTitle"
                                                                className="admin-form-control"
                                                                placeholder="Enter Image Title"
                                                                name="sliderTitle"
                                                                onChange={handleChange}
                                                                value={values?.sliderTitle}
                                                                inputProps={{ maxLength: 500 }}
                                                            />
                                                            {touched.sliderTitle && errors.sliderTitle && (
                                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                                    {errors.sliderTitle}
                                                                </PagesIndex.FormHelperText>
                                                            )}
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>

                                                <Index.Box
                                                    gridColumn={{
                                                        xs: "span 12",
                                                        sm: "span 12",
                                                        md: "span 6",
                                                        lg: "span 6",
                                                    }}
                                                    className="grid-column"
                                                >

                                                    <Index.Box className="admin-input-box admin-add-user-input">
                                                        <Index.FormHelperText className='admin-form-lable'>Select Slider Position</Index.FormHelperText>
                                                        <Index.Box className="admin-form-group">
                                                            <Index.Box className='admin-dropdown-box'>
                                                                <Index.FormControl className='admin-form-control'>
                                                                    <Index.Select className='admin-dropdown-select '
                                                                        value={values.sliderPosition}
                                                                        name="sliderPosition"
                                                                        defaultValue={
                                                                            values ? values?.sliderPosition : ""
                                                                        }
                                                                        onBlur={handleBlur}
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "sliderPosition",
                                                                                e.target.value
                                                                            );
                                                                        }}

                                                                        displayEmpty
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                    >
                                                                        <Index.MenuItem value="" className='admin-menuitem'>
                                                                            Select Slider Position
                                                                        </Index.MenuItem>
                                                                        {sliderPosition.map((item) => {
                                                                            return (
                                                                                <Index.MenuItem value={item.value} className='admin-menuitem'>
                                                                                    {item.name}
                                                                                </Index.MenuItem>
                                                                            )
                                                                        })

                                                                        }
                                                                    </Index.Select>
                                                                </Index.FormControl>
                                                                {touched.sliderPosition && errors.sliderPosition && (
                                                                    <PagesIndex.FormHelperText className="admin-error-text">
                                                                        {errors.sliderPosition}
                                                                    </PagesIndex.FormHelperText>
                                                                )}
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>
                                                {/* <Index.Box
                                                    gridColumn={{
                                                        xs: "span 12",
                                                        sm: "span 12",
                                                        md: "span 6",
                                                        lg: "span 6",
                                                    }}
                                                    className="grid-column"
                                                >

                                                    <Index.Box className="admin-input-box admin-add-user-input">
                                                        <Index.FormHelperText className='admin-form-lable'>Select Category</Index.FormHelperText>
                                                        <Index.Box className="admin-form-group">
                                                            <Index.Box className='admin-dropdown-box'>
                                                                <Index.FormControl className='admin-form-control'>
                                                                    <Index.Select className='admin-dropdown-select '
                                                                        value={values.type}
                                                                        name="type"
                                                                        defaultValue={
                                                                            values ? values?.type : ""
                                                                        }
                                                                        onBlur={handleBlur}
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "type",
                                                                                e.target.value
                                                                            );
                                                                            handleGetMovies(e.target.value)
                                                                        }}

                                                                        displayEmpty
                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                    >
                                                                        <Index.MenuItem value="" className='admin-menuitem'>
                                                                            Select Category
                                                                        </Index.MenuItem>
                                                                        {movieType.map((item) => {
                                                                            return (
                                                                                <Index.MenuItem value={item.value} className='admin-menuitem'>
                                                                                    {item.name}
                                                                                </Index.MenuItem>
                                                                            )
                                                                        })

                                                                        }
                                                                    </Index.Select>
                                                                </Index.FormControl>
                                                                {touched.type && errors.type && (
                                                                    <PagesIndex.FormHelperText className="admin-error-text">
                                                                        {errors.type}
                                                                    </PagesIndex.FormHelperText>
                                                                )}
                                                            </Index.Box>
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box> */}
                                                <Index.Box
                                                    gridColumn={{
                                                        xs: "span 12",
                                                        sm: "span 12",
                                                        md: "span 6",
                                                        lg: "span 6",
                                                    }}
                                                    className="grid-column"
                                                >
                                                    <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                                                        <Index.FormHelperText className="admin-form-lable">
                                                            Select Song<span className="astrick-sing">*</span>
                                                        </Index.FormHelperText>
                                                        <Index.Box className="admin-form-group">
                                                            <Index.FormControl className="admin-form-control admin-auto-complete-control">

                                                                <Index.Autocomplete
                                                                    size="small"
                                                                    className="admin-auto-complete-filed"
                                                                    fullWidth
                                                                    name='linkId'
                                                                    options={getAllMovie}
                                                                    onBlur={handleBlur}
                                                                    getOptionLabel={(option) =>
                                                                        option?.title
                                                                        || ""
                                                                    }
                                                                    value={
                                                                        getAllMovie.find(
                                                                            (option) =>
                                                                                option?._id ==
                                                                                values.linkId
                                                                        ) || null
                                                                    }
                                                                    onChange={(e, newValue) => {
                                                                        console.log(newValue)

                                                                        setFieldValue(
                                                                            'linkId',
                                                                            newValue ? newValue?._id : ""
                                                                        );
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <Index.TextField
                                                                            {...params}
                                                                            placeholder="Select Song"
                                                                            variant="outlined"
                                                                            onBlur={handleBlur}
                                                                            className="admin-form-control"
                                                                        />
                                                                    )}
                                                                    classes={{
                                                                        paper:
                                                                            "custom-product-dropdowns",
                                                                    }}
                                                                // disableClearable
                                                                />
                                                            </Index.FormControl>
                                                            {touched.linkId && errors.linkId && (
                                                                <PagesIndex.FormHelperText className="admin-error-text">
                                                                    {errors.linkId}
                                                                </PagesIndex.FormHelperText>
                                                            )}
                                                        </Index.Box>
                                                    </Index.Box>
                                                </Index.Box>


                                                <Index.Box
                                                    gridColumn={{
                                                        xs: "span 12",
                                                        sm: "span 6",
                                                        md: "span 6",
                                                        lg: "span 6",
                                                    }}
                                                    className="grid-column"
                                                >
                                                    <Index.Box className="admin-input-box admin-add-news-input-box">
                                                        <Index.FormHelperText className="admin-form-lable">
                                                            Select Image <span className="astrick-sing">*</span>
                                                            <span className="option-condition-text">
                                                                (Image must be 1280px(Width) * 720px(Height))
                                                            </span>
                                     
                                                        </Index.FormHelperText>

                                                        {values?.image
                                                            ? (
                                                                <Index.Box
                                                                    className={`file-upload-main thumbnail-box admin-file-browse-btn-main ${values?.vertical === true
                                                                        ? "thumbnail-box-reel"
                                                                        : ""
                                                                        }`}

                                                                >
                                                                    <Index.Box className="upload-news-img-box">
                                                                        {renderImage(values)}
                                                                    </Index.Box>
                                                                    <Index.Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        className={`admin-file-browse-btn ${values?.vertical === true
                                                                            ? "admin-file-browse-btn-reel"
                                                                            : ""
                                                                            }`}

                                                                        onClick={() => {
                                                                            setFieldValue("defaultImage", false);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={PagesIndex.Svg.whiteedit}
                                                                            className="admin-browse-icon-img"
                                                                            alt="upload img"
                                                                        ></img>
                                                                        <input
                                                                            hidden
                                                                            accept="image/*"
                                                                            id="image"
                                                                            name="image"
                                                                            type="file"
                                                                            onChange={(e) => {
                                                                                if (e.target.files?.length !== 0) {
                                                                                    setFieldValue("image", e.target.files[0]);
                                                                                    setImage(e.target.files[0])
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Index.Button>
                                                                </Index.Box>
                                                            ) : (
                                                                <Index.Box
                                                                    className={`file-upload-main thumbnail-box ${values?.vertical === true
                                                                        ? "thumbnail-box-reel"
                                                                        : ""
                                                                        }`}

                                                                >
                                                                    <Index.Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        className={`file-upload-button ${values?.vertical === true
                                                                            ? "file-upload-button-reel"
                                                                            : ""
                                                                            }`}

                                                                    >
                                                                        {renderImage(values)}
                                                                        <input
                                                                            hidden
                                                                            accept="image/*"
                                                                            id="image"
                                                                            name="image"
                                                                            type="file"
                                                                            onChange={(e) => {
                                                                                if (e.target.files?.length !== 0) {
                                                                                    setFieldValue("image", e.target.files[0]);
                                                                                    setImage(e.target.files[0])
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Index.Button>
                                                                </Index.Box>
                                                            )}
                                                        {touched.image && errors.image && (
                                                            <PagesIndex.FormHelperText className="admin-error-text">
                                                                {errors.image}
                                                            </PagesIndex.FormHelperText>
                                                        )}
                                                    </Index.Box>
                                                </Index.Box>



                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="admin-user-btn-flex add-news-btn-flex">
                                        <Index.Box className="border-btn-main">
                                            <Index.Button
                                                className="border-btn"
                                                onClick={() => {
                                                    navigate("/admin/home-slider", {
                                                    });
                                                }}
                                                disabled={buttonSpinner}
                                            >
                                                Discard
                                            </Index.Button>
                                        </Index.Box>
                                        <Index.Box className="btn-main-primary">
                                            <Index.Button
                                                className="btn-primary"
                                                type="submit"
                                                // onClick={handleOpenPublishSchedule}
                                                disabled={
                                                    buttonSpinner

                                                }
                                            >
                                                {buttonSpinner ? (
                                                    <PagesIndex.Spinner />
                                                ) : id ? (
                                                    "Update"
                                                ) : "Submit"}
                                            </Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </form>
                            </Index.Box>


                        </>
                    )}
                </PagesIndex.Formik>
            </Index.Box>

        </>
    );
}
