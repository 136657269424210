import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

// for modal design

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};
export default function ViewMusic() {
    const params = PagesIndex.useParams();
    const navigate = PagesIndex.useNavigate();
    const { id } = params;
    const [videoLink, setVideoLink] = useState("");
    const [openIframeModal, setOpenIframeModal] = useState(false);
    const [moviesData, setMoviesData] = useState({});
    const [message, setMessage] = useState("Copy");

    const timeConverter = (time) => {
        let hours = Math.floor(time / 60) || "0";
        let minutes = time % 60 || "0";
        return hours + "h " + minutes + "m";
    };
    const handleCloseifameModal = () => {
        setOpenIframeModal(false);
    };
    useEffect(() => {
        handleGetWebSeriesData();
    }, []);

    const handleGetWebSeriesData = () => {
        PagesIndex.apiGetHandler(`${PagesIndex.Api.VIEW_SONG_DATA}?id=${id}`).then(
            (res) => {
                if (res?.status === 200) {
                    console.log(res);

                    setMoviesData(res?.data);
                } else {
                    PagesIndex.toasterError(res?.message);
                }
            }
        );
    };
    const AudioPlayer = ({ songLink }) => {
        return (
            <audio controls onError={(event) => console.log('Error playing audio:', event)}>
                <source src={songLink} type="audio/mpeg" />
                
            </audio>
        );
    };
    return (
        <Index.Box className="admin-dashboard-content admin-user-management-content">
            <Index.Box className="admin-user-list-flex admin-page-title-main">
                <Index.Box className="admin-title-left">
                    <Index.Button
                        className="admin-adduser-btn btn-primary back-btn"
                        onClick={() => {
                            navigate(`/admin/song`);
                        }}
                    >
                        <img
                            src={PagesIndex.Svg.backarrow}
                            className="admin-icon edit-icons"
                            alt="plus"
                        />
                    </Index.Button>
                    <Index.Typography
                        className="admin-view-page-title"
                        component="h2"
                        variant="h2"
                    >
                        Movie Details
                    </Index.Typography>
                </Index.Box>
            </Index.Box>

            <Index.Box className="view-details-card">
                <Index.Box className="admin-file-view-card common-card">
                    <Index.Box className="admin-file-view-main">
                        <Index.Box className="highlight-box">
                            <Index.Box className="highlight-flex">
                                <img
                                    src={PagesIndex.Svg.calendaricon}
                                    className="calendar-icon"
                                    alt=""
                                />
                                <Index.Typography className="highlight-content-lable">
                                    Release Date:
                                </Index.Typography>
                                <Index.Typography className="highlight-content-data">
                                    {PagesIndex.moment(moviesData?.releaseDate).format(
                                        "DD-MM-YYYY"
                                    )}
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <img
                            src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${moviesData?.image}`}
                            className="admin-file-view-img"
                            alt="upload img"
                        />
                    </Index.Box>
                    <Index.Box className="admin-view-btn-flex">
                        {/* <Index.Box className="btn-main-primary">
              <Index.Button
                className="btn-primary"
                onClick={() => {
                  setOpenIframeModal(true);
                  setVideoLink(moviesData?.getMovie?.trailerLink);
                }}
              >
                <img
                  src={PagesIndex.Svg.playicon}
                  className="admin-icon play-icon"
                  alt="View"
                />
                <span>Trailer</span>
              </Index.Button>
            </Index.Box> */}
                        {/* <Index.Box className="btn-main-secondary">
                            <Index.Button
                                className="btn-secondary"
                                onClick={() => {
                                    //   setOpenIframeModal(true);
                                    setVideoLink(moviesData?.getMovie?.link);
                                }}
                            >
                                <img
                                    src={PagesIndex.Svg.playicon}
                                    className="admin-icon play-icon"
                                    alt="View"
                                />
                                <span>Play Song</span>
                            </Index.Button>
                        </Index.Box> */}
                   
                        <AudioPlayer songLink={moviesData?.songLink}/>
                    </Index.Box>
                    <Index.Box className="types-flex">
                        {/* <Index.Box className="types-box">
              <Index.Typography className="types-text">
                {moviesData?.getMovie?.movieType}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="types-box">
              <Index.Typography className="types-text">
                {moviesData?.getMovie?.censorRating}
              </Index.Typography>
            </Index.Box> */}
                        <Index.Box className="types-box">
                            <Index.Typography className="types-text">
                                {moviesData?.language}
                            </Index.Typography>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="review-flex">
                        <Index.Box className="review-box">
                            <Index.Typography className="review-title">
                                Total Viewers
                            </Index.Typography>
                            <Index.Typography className="review-data">
                                {moviesData?.views}
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="review-box">
                            <Index.Typography className="review-title">
                                Rating
                            </Index.Typography>
                            <Index.Typography className="review-data">
                                {moviesData?.rating?.toFixed(1) || "0"}
                            </Index.Typography>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="common-card brif-content-card">
                    <Index.Box className="view-movie-content-main brif-content-main">
                        <Index.Box className="news-content-main">
                            <Index.Typography className="news-name-text">
                                {moviesData?.title}
                            </Index.Typography>
                            <Index.Typography className="news-discription-text">
                                {moviesData?.description}
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className="view-content-box">
                            <Index.Box className="view-content-flex">
                                <Index.Typography className="view-content-lable">
                                    Duration:
                                </Index.Typography>
                                <Index.Box className="view-content-data-flex">
                                    <Index.Typography className="view-content-data">
                                        {timeConverter(moviesData?.duration)}
                                    </Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="view-content-box">
                            <Index.Box className="view-content-flex">
                                <Index.Typography className="view-content-lable">
                                    Movie Link:
                                </Index.Typography>
                                <Index.Box className="view-content-data-flex">
                                    <Index.Typography className="view-content-data">
                                        {moviesData?.songLink}
                                    </Index.Typography>
                                    <Index.Tooltip
                                        title={message}
                                        arrow
                                        placement="bottom"
                                        className="admin-tooltip"
                                        onMouseLeave={() => {
                                            setTimeout(() => {
                                                setMessage("Copy");
                                            }, 1000);
                                        }}
                                    >
                                        <Index.Button
                                            className="copy-btn-main"
                                            onClick={() => {
                                                setMessage("Copied ✓");
                                                navigator.clipboard.writeText(
                                                    moviesData?.songLink
                                                );
                                            }}
                                        >
                                            <img
                                                src={PagesIndex.Svg.copyicon}
                                                className="copy-icon"
                                                alt="copy"
                                            />
                                        </Index.Button>
                                    </Index.Tooltip>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-category-content-main">
                        <Index.Box className="view-content-flex">
                            <Index.Typography className="view-content-lable">
                                Categories:
                            </Index.Typography>
                            <Index.Box className="view-category-flex-box">
                                {moviesData?.categoryId?.map((data) => {
                                    return (
                                        <Index.Box className="view-category-box" key={data?._id}>
                                            <Index.Typography className="view-content-data">
                                                {data?.categoryName}
                                            </Index.Typography>
                                        </Index.Box>
                                    );
                                })}
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="view-content-box">
                        <Index.Box className="cast-flex">
                            <Index.Typography className="view-content-lable">
                                Cast:
                            </Index.Typography>
                            <Index.Box className="cast-and-crew-row">
                                {moviesData?.cast?.map((data) => {
                                    return (
                                        <Index.Box className="cast-box" key={data?.castId?._id}>
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${data?.castId?.image}`}
                                                className="admin-upload-cast-img"
                                                alt="upload img"
                                            ></img>
                                            <Index.Typography className="cast-name">
                                                {data?.castId?.name}
                                            </Index.Typography>
                                        </Index.Box>
                                    );
                                })}
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

            {/* <PagesIndex.MovieDetailsTabs moviesData={moviesData} /> */}

            <Index.Modal
                open={openIframeModal}
                onClose={handleCloseifameModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="admin-modal"
            >
                <Index.Box
                    sx={style}
                    className="admin-ifram-modal-inner-main admin-modal-inner"
                >
                    <Index.Button
                        className="iframe-modal-close-btn"
                        onClick={handleCloseifameModal}
                    >
                        <img
                            src={PagesIndex.Svg.closeroundicon}
                            className="admin-modal-close-icon"
                            alt="Close"
                        />
                    </Index.Button>
                    <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                        <Index.Box className="iframe-main">
                            <iframe
                                className="iframe-main-tag"
                                src={`https://www.youtube.com/embed/${videoLink?.includes("watch?")
                                    ? videoLink?.split("=")[1]
                                    : videoLink?.split("/")[3]
                                    }?autoplay=1`}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            ></iframe>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
        </Index.Box>
    );
}
